import { Component } from '@angular/core';

import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-supplier-contact-list',
  templateUrl: './supplier-contact-list.component.html',
})
export class SupplierContactListComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    // {
    //   name: 'John Smith',
    //   companyname: 'Asus',
    //   jobTitle: 'Account Manager',
    //   email: 'john@testdomain.com',
    //   phone: '1234567890',
    //   mobile: '1234567890',
    // },
    // {
    //   name: 'Jane Doe',
    //   companyname: 'Acer',
    //   jobTitle: 'Sales Representative',
    //   email: 'jane@testdomain.com',
    //   phone: '0987654321',
    //   mobile: '0987654321',
    // },
    // {
    //   name: 'Michael Johnson',
    //   companyname: 'Lenavo',
    //   jobTitle: 'Marketing Specialist',
    //   email: 'michael@testdomain.com',
    //   phone: '5555555555',
    //   mobile: '5555555555',
    // },
    // {
    //   name: 'Emily Williams',
    //   companyname: 'Dell',
    //   jobTitle: 'Project Manager',
    //   email: 'emily@testdomain.com',
    //   phone: '7777777777',
    //   mobile: '7777777777',
    // },
    // {
    //   name: 'David Brown',
    //   companyname: 'Gaming',
    //   jobTitle: 'Software Engineer',
    //   email: 'david@testdomain.com',
    //   phone: '2222222222',
    //   mobile: '2222222222',
    // },
    // {
    //   name: 'Olivia Wilson',
    //   companyname: 'Prestige',
    //   jobTitle: 'HR Coordinator',
    //   email: 'olivia@testdomain.com',
    //   phone: '3333333333',
    //   mobile: '3333333333',
    // },
    // {
    //   name: 'Daniel Thompson',
    //   companyname: 'Wonderlaw',
    //   jobTitle: 'Finance Analyst',
    //   email: 'daniel@testdomain.com',
    //   phone: '4444444444',
    //   mobile: '4444444444',
    // },
    // {
    //   name: 'Sophia Martinez',
    //   companyname: 'KTM',
    //   jobTitle: 'Graphic Designer',
    //   email: 'sophia@testdomain.com',
    //   phone: '6666666666',
    //   mobile: '6666666666',
    // },
    // {
    //   name: 'James Davis',
    //   companyname: 'Honda',
    //   jobTitle: 'Operations Manager',
    //   email: 'james@testdomain.com',
    //   phone: '9999999999',
    //   mobile: '9999999999',
    // },
    // {
    //   name: 'Ava Anderson',
    //   companyname: 'Pulsar',
    //   jobTitle: 'Customer Support Specialist',
    //   email: 'ava@testdomain.com',
    //   phone: '8888888888',
    //   mobile: '8888888888',
    // },
    // {
    //   name: 'Robert Johnson',
    //   companyname: 'BMW',
    //   jobTitle: 'Sales Manager',
    //   email: 'robert@testdomain.com',
    //   phone: '1111111111',
    //   mobile: '1111111111',
    // },
    // {
    //   name: 'Mia Thompson',
    //   companyname: 'Bense',
    //   jobTitle: 'Product Manager',
    //   email: 'mia@testdomain.com',
    //   phone: '2222222222',
    //   mobile: '2222222222',
    // },
    // {
    //   name: 'Ethan Wilson',
    //   companyname: 'Microsoft',
    //   jobTitle: 'Software Developer',
    //   email: 'ethan@testdomain.com',
    //   phone: '3333333333',
    //   mobile: '3333333333',
    // },
    // {
    //   name: 'Isabella Davis',
    //   companyname: 'Space X',
    //   jobTitle: 'Marketing Manager',
    //   email: 'isabella@testdomain.com',
    //   phone: '4444444444',
    //   mobile: '4444444444',
    // },
    // {
    //   name: 'William Rodriguez',
    //   companyname: 'Google',
    //   jobTitle: 'Business Analyst',
    //   email: 'william@testdomain.com',
    //   phone: '5555555555',
    //   mobile: '5555555555',
    // },
    // {
    //   name: 'Sofia Garcia',
    //   companyname: 'Facebook',
    //   jobTitle: 'UX Designer',
    //   email: 'sofia@testdomain.com',
    //   phone: '6666666666',
    //   mobile: '6666666666',
    // },
    // {
    //   name: 'Benjamin Anderson',
    //   companyname: 'Blackbox',
    //   jobTitle: 'Accountant',
    //   email: 'benjamin@testdomain.com',
    //   phone: '7777777777',
    //   mobile: '7777777777',
    // },
    // {
    //   name: 'Charlotte Lee',
    //   companyname: 'Git hub',
    //   jobTitle: 'Customer Success Manager',
    //   email: 'charlotte@testdomain.com',
    //   phone: '8888888888',
    //   mobile: '8888888888',
    // },
    // {
    //   name: 'Alexander Martin',
    //   companyname: 'IQOOO',
    //   jobTitle: 'Operations Coordinator',
    //   email: 'alexander@testdomain.com',
    //   phone: '9999999999',
    //   mobile: '9999999999',
    // },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: (params: ICellRendererParams) => {
        return `<a href="/app/interaction/contacts/detail" class="text-base">${params.value}</a>`;
      },
    },
    { field: 'companyname', headerName: 'Company Name' },

    { field: 'jobTitle', headerName: 'Job Title' },
    { field: 'email', headerName: 'Email' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'mobile', headerName: 'Mobile' },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationPageSize = 10;
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
