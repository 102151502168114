<div class="">
  <div class="text-3xl">
    <p>Notifications</p>
  </div>
  <div class="py-5 pt-10">
    <div class="grid grid-cols-2 gap-4">
      <div class="relative">
        <div class="">
          <input
            class="border dark:border dark:border-theme-wui-700 dark:bg-theme-wui-dark-100 w-full rounded-xl h-10 px-10"
            type="text"
            placeholder="Filter"
            name="name"
          />
        </div>
        <div class="absolute top-2 left-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full" x-data="{showAccordion:0}">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs w-1/12 font-medium leading-4 text-left text-gray-500 uppercase border-b border-gray-200 dark:dark:bg-theme-wui-dark-100 bg-gray-50"
                >
                  Type
                </th>
                <th
                  class="px-3 text-xs w-6/12 font-medium leading-4 text-left text-gray-500 uppercase border-b dark:dark:bg-theme-wui-dark-100 border-gray-200 bg-gray-50"
                >
                  Subject
                </th>
                <th
                  class="px-6 py-3 text-xs w-2/12 font-medium leading-4 text-left text-gray-500 uppercase dark:dark:bg-theme-wui-dark-100 border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
              </tr>
            </thead>

            <tbody class="bg-white dark:bg-theme-wui-dark-100">
              <tr>
                <td class="whitespace-no-wrap border-b border-gray-200">
                  <div class="px-4">
                    <div
                      class="bg-gray-300 dark:bg-cyan-400 w-fit p-2 rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-lg text-gray-500 font-bold leading-5">
                    Your database topmarketcoin-cluster is low on resources
                  </div>
                  <div class="text-sm pt-2">
                    Hello, Your mysql service, topmarketcoin-db-cluster, is
                    running low on disk. if youservice....
                  </div>

                  <div *ngIf="showSummaryCard" class="">
                    <button
                      (click)="hideSummaryCard()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </button>
                  </div>
                  <div *ngIf="!showSummaryCard" class="">
                    <button
                      (click)="unhideSummaryCard()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="">
                    <p>22 days ago</p>
                  </div>
                </td>
              </tr>
              <tr *ngIf="showSummaryCard">
                <td
                  class="py-4 whitespace-no-wrap border-b border-gray-200"
                ></td>

                <td
                  class="py-4 whitespace-no-wrap w-6/12 border-b border-gray-200"
                >
                  <div class="text-sm py-4 whitespace-no-wrap">
                    <p>
                      Your mysql service,topmarketcoin-db-cluster, is runnung
                      low on disk. if you service runs out of disk space, writes
                      will be denied and other functionality could be rendered
                      unavailable. either delete unnecessary data or upgrade to
                      a larger plan to avoid service outage
                    </p>
                    <p class="pt-2">Digital Ocean</p>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                ></td>
              </tr>

              <tr>
                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="flex p-4 items-center">
                    <div class="bg-gray-300 dark:bg-cyan-400 p-2 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-lg text-gray-500 font-bold leading-5">
                    Your database topmarketcoin-cluster is low on resources
                  </div>
                  <div class="text-sm pt-2">
                    Hello, Your mysql service, topmarketcoin-db-cluster, is
                    running low on disk. if youservice....
                  </div>

                  <div *ngIf="showSummaryCard1" class="block">
                    <button
                      (click)="hideSummaryCard1()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </button>
                  </div>
                  <div *ngIf="!showSummaryCard1">
                    <button
                      (click)="unhideSummaryCard1()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="">
                    <p>22 days ago</p>
                  </div>
                </td>
              </tr>
              <tr *ngIf="showSummaryCard1">
                <td
                  class="py-4 whitespace-no-wrap border-b border-gray-200"
                ></td>

                <td
                  class="py-4 whitespace-no-wrap w-6/12 border-b border-gray-200"
                >
                  <div class="text-sm py-4 whitespace-no-wrap">
                    <p>
                      Your mysql service,topmarketcoin-db-cluster, is runnung
                      low on disk. if you service runs out of disk space, writes
                      will be denied and other functionality could be rendered
                      unavailable. either delete unnecessary data or upgrade to
                      a larger plan to avoid service outage
                    </p>
                    <p class="pt-2">Digital Ocean</p>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                ></td>
              </tr>

              <tr>
                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="flex p-4 items-center">
                    <div class="bg-gray-300 dark:bg-cyan-400 p-2 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="text-lg text-gray-500 font-bold leading-5">
                    Your database topmarketcoin-cluster is low on resources
                  </div>
                  <div class="text-sm pt-2">
                    Hello, Your mysql service, topmarketcoin-db-cluster, is
                    running low on disk. if youservice....
                  </div>

                  <div *ngIf="showSummaryCard2" class="block">
                    <button
                      (click)="hideSummaryCard2()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </button>
                  </div>
                  <div *ngIf="!showSummaryCard2">
                    <button
                      (click)="unhideSummaryCard2()"
                      class="w-fit btn px-2 py-2 hover:bg-gray-200 rounded text-left flex justify-start"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </button>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="">
                    <p>22 days ago</p>
                  </div>
                </td>
              </tr>
              <tr *ngIf="showSummaryCard2">
                <td
                  class="py-4 whitespace-no-wrap border-b border-gray-200"
                ></td>

                <td
                  class="py-4 whitespace-no-wrap w-6/12 border-b border-gray-200"
                >
                  <div class="text-sm py-4 whitespace-no-wrap">
                    <p>
                      Your mysql service,topmarketcoin-db-cluster, is runnung
                      low on disk. if you service runs out of disk space, writes
                      will be denied and other functionality could be rendered
                      unavailable. either delete unnecessary data or upgrade to
                      a larger plan to avoid service outage
                    </p>
                    <p class="pt-2">Digital Ocean</p>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap border-b border-gray-200"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
