import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeKey = 'blueMode';
  private themeSubject = new BehaviorSubject<string>('');

  theme$: Observable<string> = this.themeSubject.asObservable();

  constructor() {
    // Do not set the theme in the constructor
  }

  initTheme() {
    // Get the current theme from local storage
    const selectedTheme = localStorage.getItem(this.themeKey);

    if (selectedTheme === 'orange') {
      // If there is a saved theme, do not override it
      this.setOrangeMode();
    } else {
      // If no saved theme, apply the default theme

      this.setBlueMode(); // or any other default theme logic you want
    }
  }

  setBlueMode() {
    localStorage.setItem(this.themeKey, 'blue');
    document.documentElement.classList.remove('orange');
    document.documentElement.classList.add('blue');
    this.themeSubject.next('blue');
  }

  setOrangeMode() {
    localStorage.setItem(this.themeKey, 'orange');
    document.documentElement.classList.add('orange');
    document.documentElement.classList.remove('blue');
    this.themeSubject.next('orange');
  }

  private updateTheme(theme: string) {
    // Remove any other theme class
    document.documentElement.classList.add(theme);
    this.themeSubject.next(theme);
  }

  isBlueModeEnabled(): boolean {
    return localStorage.getItem(this.themeKey) === 'blue';
  }
}
