import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-datagrid-supllier-list',
  templateUrl: './datagrid-supllier-list.component.html',
})
export class DatagridSupllierListComponent {
  gridOptions: GridOptions = { suppressRowClickSelection: true };
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      name: 'Voltas',
      city: 'Mumbai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: '24, Nani Bungalow, Chapel Lane,Santacruz (west), Mumbai ',
      phone: '5689324789',
      fax: '+44 161 999 8888',
    },
    {
      name: 'Taj Meridian',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '9054782398',
      fax: '1-408-999 8888',
    },
    {
      name: 'PVR Theatre',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '7598267459',
      fax: '+1 (212) 222 8888',
    },
    {
      name: 'Asus',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '8573256980',
      fax: '1-408-999 8888',
    },
    {
      name: 'Lenovo',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '3412890546',
      fax: '+1 (212) 222 8888',
    },
    {
      name: 'Court Yard',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '6743289018',
      fax: '1-408-999 8888',
    },
    {
      name: 'KTM',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '7543674589',
      fax: '+1 (212) 222 8888',
    },
    {
      name: 'Voltas',
      city: 'Mumbai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: '24, Nani Bungalow, Chapel Lane,Santacruz (west), Mumbai ',
      phone: '5689324789',
      fax: '+44 161 999 8888',
    },
    {
      name: 'Taj Meridian',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '9054782398',
      fax: '1-408-999 8888',
    },
    {
      name: 'PVR Theatre',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '7598267459',
      fax: '+1 (212) 222 8888',
    },
    {
      name: 'Asus',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '8573256980',
      fax: '1-408-999 8888',
    },
    {
      name: 'Lenovo',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '3412890546',
      fax: '+1 (212) 222 8888',
    },
    {
      name: 'Court Yard',
      city: 'Chennai',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address: 'Old No 76,new 177, Portugese Church St, Chennai ',
      phone: '6743289018',
      fax: '1-408-999 8888',
    },
    {
      name: 'KTM',
      city: 'Banglore',
      country: 'India',
      link: '/app/interaction/suppliers/detail',
      address:
        'S-217,no 47, Manipal Centre, Dickenson Road, Dickenson Road, Banglore ',
      phone: '7543674589',
      fax: '+1 (212) 222 8888',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.name}, ${data.city}, ${data.country}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.name;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `<div class="pl-1">
            <a href="${params.node.data.link}">
            <p class="text-lg font-bold mt-2 list-font hover:underline">${params.node.data.name}</p>
            <p class="-mt-2"><small class="text-xs text-gray-600 dark:text-gray-100">${params.node.data.city}, ${params.node.data.country} </small></p>
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-sky-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Quotes : ${params.value.address}
        </div>
        <div class="flex bg-pink-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Invoices: ${params.value.phone}
        </div>
        <div class="flex bg-purple-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Jobs : ${params.value.fax}
        </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Supplier Name',
      minWidth: 300,
      resizable: true,
      cellRenderer: this.nameCellRenderer,
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'address',
      headerName: 'Address',
    },
    {
      field: 'phone',
      headerName: 'Phone',
    },
    {
      field: 'fax',
      headerName: 'Fax',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
