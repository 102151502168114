import { Component } from '@angular/core';

@Component({
  selector: 'app-quotes-main',
  templateUrl: './quotes-main.component.html',
})
export class QuotesMainComponent {
  showViewFilter = true;
  selectedViewFilter = 'All Suppliers';

  ngOnInit(): void {
    this.showViewFilter = true;
  }

  toggleViewFilter() {
    this.showViewFilter = !this.showViewFilter;
  }

  callAllSuppliers() {
    this.selectedViewFilter = ' All Suppliers';
    this.toggleViewFilter();
  }

  callActiveSuppliers() {
    this.selectedViewFilter = 'Active Suppliers Only';
    this.toggleViewFilter();
  }

  callFavoriteSuppliers() {
    this.selectedViewFilter = 'Archived Suppliers Only';
    this.toggleViewFilter();
  }

  callArchivedSuppliers() {
    this.selectedViewFilter = 'My Favorited Suppliers';
    this.toggleViewFilter();
  }
}
