import { Component, OnInit } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-invite-staff',
  templateUrl: './invite-staff.component.html',
})
export class InviteStaffComponent {
  role: any[] | undefined;
  filteredroles: any[] = [];
  ngOnInit() {
    this.role = [
      'Role',
      'Admin',
      'Super Users & Client Services',
      'Accountants/Bookkeepers',
      'Account Managers',
      'Pre-Sales',
      'Project ManagersMay',
      'Job engineers',
      'Support engineers',
    ];
  }
  filterroles(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.role as unknown as any[]).length; i++) {
      let role = (this.role as any[])[i];
      if (role.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(role);
      }
    }

    this.filteredroles = filtered;
  }
}
