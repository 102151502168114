<div class="sidebar-close">
  <div
    class="min-[450px]:invisible your-sidebar-class absolute top-3 right-5"
    (click)="closeSidebar()"
  >
    <svg
      id="close-sidebar"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 dark:text-white text-black h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </div>
  <div
    [@fadeAnimation]="sidebarVisible ? 'void' : '*'"
    class="px-3 py-4 min-h-screen sidebar-full bg-white dark:bg-theme-main-dark dashboard-shadow ltr:dark:border-r rtl:dark:border-l dark:border-theme-wui-700"
    [ngClass]="{ miniSidebar: !sidebarVisible, fullSidebar: sidebarVisible }"
    id="sidebar"
    (mouseenter)="onMiniSidebarHover(true)"
    (mouseleave)="onHideSidebarHover(false)"
  >
    <div class="mt-4 mb-5" *ngIf="sidebarVisible">
      <app-logo></app-logo>
    </div>
    <div class="mt-4 mb-5" *ngIf="!sidebarVisible">
      <app-smalllogo></app-smalllogo>
    </div>
    <ul class="">
      <!-- blogs -->
      <li class="px-2">
        <a
          routerLink="/app/dashboard"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            class="fill-stroke sideBarIcon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 4H15C14.4477 4 14 4.44772 14 5V9C14 9.55228 14.4477 10 15 10H19C19.5523 10 20 9.55228 20 9V5C20 4.44772 19.5523 4 19 4Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <!-- list name -->
          <h3
            [hidden]="!sidebarVisible"
            class="px-2 text-sm sideBarText fade-in"
          >
            Dashboard
          </h3>
        </a>
      </li>

      <!-- news -->
      <li class="px-2">
        <a
          routerLink="interaction"
          routerLinkActive="sidebarMenuActive1"
          (click)="handleSubmenuClick('interactionSubMenu')"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>

          <!-- list name -->
          <div
            class="flex items-center justify-between"
            [hidden]="!sidebarVisible"
          >
            <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
              Interactions
            </h3>
            <div
              class="ml-8 rotate-animation"
              [hidden]="!sidebarVisible"
              [ngClass]="{
                'rotate-180': interactionSubMenuRotation,
                'rotate-0': !interactionSubMenuRotation
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 ml-auto h-4 sideBarText transform"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
        </a>

        <!-- sub list -->
        <ul
          *ngIf="sidebarVisible"
          [hidden]="interactionSubMenu"
          class="sidebarSubMenu"
        >
          <li>
            <a
              routerLink="interaction/clients"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center text-xs sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 mx-2 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                /></svg
              >Clients</a
            >
          </li>
          <li>
            <a
              routerLink="interaction/suppliers"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                />
              </svg>
              Suppliers
            </a>
          </li>
          <li>
            <a
              routerLink="interaction/contacts"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
              Contacts</a
            >
          </li>
        </ul>
      </li>

      <!-- collabartaion -->
      <li class="px-2">
        <a
          routerLink="collaboration"
          routerLinkActive="sidebarMenuActive1"
          (click)="handleSubmenuClick('collaborationSubMenu')"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
            />
          </svg>
          <!-- list name -->
          <div
            class="flex items-center justify-between"
            [hidden]="!sidebarVisible"
          >
            <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
              Collaboration
            </h3>
            <div
              class="ml-5 rotate-animation"
              [hidden]="!sidebarVisible"
              [ngClass]="{
                'rotate-180': collaborationSubMenuRotation,
                'rotate-0': !collaborationSubMenuRotation
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 ml-auto h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
        </a>
        <ul
          *ngIf="sidebarVisible"
          [hidden]="collaborationSubMenu"
          class="sidebarSubMenu"
        >
          <li>
            <a
              routerLink="collaboration/jobs"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
              Jobs</a
            >
          </li>
          <li>
            <a
              routerLink="collaboration/timesheets"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                />
              </svg>
              Time Sheet</a
            >
          </li>
        </ul>
      </li>
      <!-- privacy -->
      <li class="px-2">
        <a
          routerLink="supports"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
          <!-- list name -->
          <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
            Supports
          </h3>
        </a>
      </li>
      <!-- contact -->
      <li class="px-2">
        <a
          routerLink="business"
          routerLinkActive="sidebarMenuActive1"
          (click)="handleSubmenuClick('businessSubMenu')"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
            />
          </svg>
          <!-- list name -->
          <div
            [hidden]="!sidebarVisible"
            class="flex items-center justify-between"
          >
            <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
              Business
            </h3>
            <div
              class="ml-12 rotate-animation"
              [hidden]="!sidebarVisible"
              [ngClass]="{
                'rotate-180': businessSubMenuRotation,
                'rotate-0': !businessSubMenuRotation
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 ml-auto h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
        </a>
        <ul
          *ngIf="sidebarVisible"
          [hidden]="businessSubMenu"
          class="sidebarSubMenu sidebarLink"
        >
          <li>
            <a
              routerLink="business/quotes"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              Quotes</a
            >
          </li>
          <li>
            <a
              routerLink="business/invoices"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>

              Invoices</a
            >
          </li>
          <li>
            <a
              routerLink="business/orders"
              routerLinkActive="sidebarSubMenuItemActive"
              class="sidebarSubMenuItem flex items-center sidebarLink"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 mx-2 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                />
              </svg>
              Purchase Orders</a
            >
          </li>
        </ul>
      </li>
      <!-- Support -->
      <li class="px-2">
        <a
          routerLink="admin"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <!-- list name -->
          <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
            Organizer Console
          </h3>
        </a>
      </li>
      <li class="px-2">
        <a
          routerLink="reports"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
            />
          </svg>

          <!-- list name -->
          <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
            Reports
          </h3>
        </a>
      </li>
      <li class="px-2">
        <a
          routerLink="ui"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <!-- visit=> "heroicon.com" for SVG & JSX icons -->
          <!-- list icon -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 sideBarIcon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            />
          </svg>

          <!-- list name -->
          <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
            UI Elements
          </h3>
        </a>
      </li>
      <li class="px-2">
        <a
          routerLink="tool/tip"
          routerLinkActive="sidebarMenuActive"
          class="sideBarMenu sidebarLink"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 sideBarIcon h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>

          <h3 [hidden]="!sidebarVisible" class="px-2 text-sm sideBarText">
            Tool tip
          </h3>
        </a>
      </li>
    </ul>
  </div>
</div>
