<div class="flex pb-5 justify-between">
  <div class="flex lg:flex-row md:flex-row flex-row items-center">
    <h2 class="lg:text-3xl md:tex-3xl text-2xl dark:text-theme-text-blue-200">
      Contacts
    </h2>
    <div class="ltr:ml-4 lg:pt-0 md:pt-0 rtl:mr-4">
      <a
        routerLink="/app/interaction/contacts/add"
        class="btn bg-theme-primary hover:bg-save-hover text-white rounded-2xl lg:px-4 md:px-4 px-2 lg:py-2 md:py-2 py-1 lg:text-sm md:text-sm text-xs"
      >
        + Add Contact
      </a>
    </div>
  </div>
</div>
<div
  class="content-warpper border-2 p-10 py-5 rounded-3xl dark:border-top-nav-bar"
>
  <ul class="tab-navs">
    <li
      class="tab"
      [ngClass]="{
      'tab-active': showTabNumber === 1,
      
    }"
    >
      <button
        type="button"
        [@tabClick]="getTabClickState(1)"
        class="block w-full items-center justify-center py-2"
        (click)="toggleTab(1)"
        data-tab="home"
      >
        Client
      </button>
    </li>
    <li
      class="tab"
      [ngClass]="{
      'tab-active': showTabNumber === 2,
     
    }"
    >
      <button
        type="button"
        class="block w-full items-center justify-center py-2"
        (click)="toggleTab(2)"
        [@tabClick]="getTabClickState(2)"
        data-tab="contacts"
      >
        Suppliers
      </button>
    </li>
  </ul>

  <div class="tab-content overflow-hidden shadow dark:shadow-slate-400">
    <div
      class="tab-pane"
      [ngClass]="{
        block: showTabNumber === 1,
        active: showTabNumber === 1,
        hidden: showTabNumber !== 1,
        'tab-animation': showTabNumber === 1
      }"
      data-tab="info"
    >
      <app-clients-contact-list></app-clients-contact-list>
    </div>
    <div
      class="tab-pane"
      [ngClass]="{
        block: showTabNumber === 2,
        active: showTabNumber === 2,
        hidden: showTabNumber !== 2,
        'tab-animation': showTabNumber === 2
      }"
      data-tab="contacts"
    >
      <app-supplier-contact-list></app-supplier-contact-list>
    </div>
  </div>
</div>
