<div class="w-full py-10 ml-auto">
  <form>
    <div class="grid grid-cols-2 gap-x-8">
      <div class="col-span-2 mb-8">
        <h4 class="text-xl font-bold">Timesheet Information</h4>
      </div>

      <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
        <div class="relative">
          <div class="relative z-0 w-full group">
            <input
              type="text"
              name="title"
              id="ti_tle"
              class="input_box peer"
              placeholder=" "
              required
            />
            <label for="title" class="input_label">Title</label>
          </div>
        </div>
      </div>
      <div></div>
      <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
        <div class="relative">
          <div class="relative z-0 w-full group">
            <textarea
              name="not_es"
              id="notes"
              class="input_box peer"
              placeholder=""
              required
            >
            </textarea>
            <label for="not_es" class="address_label text-xl">Notes</label>
          </div>
        </div>
      </div>
      <div></div>
      <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
        <div class="relative">
          <div class="relative z-0 w-full group">
            <input
              type="text"
              name="folder"
              id="fol_der"
              class="input_box peer"
              placeholder=" "
              required
            />
            <label for="folder" class="input_label">Folder</label>
          </div>
        </div>
      </div>

      <div class="col-span-2">
        <hr class="border-gray-300" />
      </div>
      <div class="py-5">
        <div
          class="flex items-center justify-start ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
        >
          <a class="px-5 text-white py-2 save rounded-xl" href="#">Save</a>
          <a class="px-5 text-white cancel py-2 rounded-xl" href="#">Cancel</a>
        </div>
      </div>
      <div class="col-span-2 pt-5">
        <div class="">
          <div class="text-lg text-save-background font-bold text-left">
            <p>Note History</p>
          </div>
          <div class="text-sm pt-2">
            <p>There are no QuoteNotesList to display.</p>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
