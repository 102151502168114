import { Component, OnInit } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
})
export class AddContactComponent {
  filteredtyre: any[] = [];
  tyre: any[] | undefined;
  tyres: any[] | undefined;
  filteredMonths: any[] = [];
  months: any[] = [];
  accounts: any[] | undefined;
  filteredbustructure: any[] = [];
  filteredbussiness: any[] = [];
  bussiness: any = null;
  formGroup: FormGroup | undefined;
  filteredaccount: any[] = [];
  filteredjob: any[] = [];
  filteredtyres: any[] = [];
  jobs: any[] | undefined;
  ngOnInit() {
    this.months = [
      'January ',
      'January 01',
      'January 02',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.tyre = [
      ' Select Tyre ',
      'Client Type 1',
      'Client Type 2',
      'Client Type 3',
    ];
    this.tyres = [' Select Tyre ', ' 1', ' 2', '3'];
    this.bussiness = [
      ' Business Structure ',
      'Compan',
      'Individual',
      'Patnership',
      'Self Managed Superannuation Fund',
      'Sole Trader',
      'Superannuation Fund',
      'Trust',
    ];
    this.accounts = [' Account Manager', 'Surya', 'Ashwin', 'Suresh'];
    this.jobs = ['  Job Manager ', 'Arun', 'Surji', 'Sandy'];
    this.formGroup = new FormGroup({
      selectedMonth: new FormControl<object | null>(null),
    });
  }
  filteraccount(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.accounts as any[]).length; i++) {
      let accounts = (this.accounts as any[])[i];
      if (accounts.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(accounts);
      }
    }

    this.filteredaccount = filtered;
  }
  filterjob(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.jobs as any[]).length; i++) {
      let jobs = (this.jobs as any[])[i];
      if (jobs.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(jobs);
      }
    }

    this.filteredjob = filtered;
  }
  filterbussiness(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.bussiness as any[]).length; i++) {
      let bussiness = (this.bussiness as any[])[i];
      if (bussiness.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(bussiness);
      }
    }

    this.filteredbussiness = filtered;
  }
  filtertyre(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tyre as any[]).length; i++) {
      let tyre = (this.tyre as any[])[i];
      if (tyre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tyre);
      }
    }

    this.filteredtyre = filtered;
  }
  filtertyres(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tyres as any[]).length; i++) {
      let tyres = (this.tyres as any[])[i];
      if (tyres.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tyres);
      }
    }

    this.filteredtyres = filtered;
  }
  filterMonths(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.months as any[]).length; i++) {
      let month = (this.months as any[])[i];
      if (month.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(month);
      }
    }

    this.filteredMonths = filtered;
  }
}
