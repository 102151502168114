<div>
  <div class="flex flex-row justify-between items-center mb-4">
    <div
      class="flex flex-row lg:justify-none md:justify-between justify-end items-center"
    >
      <h2
        class="lg:text-3xl md:text-3xl text-2xl text-left dark:text-theme-text-blue-200"
      >
        Clients
      </h2>
      <div
        class="lg:ltr:ml-4 lg:pt-0 md:pt-0 pt-2 lg:rtl:mr-4 md:ltr:ml-2 md:rtl:mr-2 ltr:ml-2 rtl:mr-0"
      >
        <a
          routerLink="/app/interaction/clients/add"
          class="btn bg-theme-primary hover:bg-save-hover text-white rounded-2xl lg:px-4 md:px-2 px-2 lg:py-2 md:py-2 py-1 lg:text-sm md:text-sm text-xs"
        >
          + Add Client
        </a>
      </div>
    </div>
    <app-filter-clients></app-filter-clients>
  </div>
  <div class="list-page">
    <app-datagrid-clientslist></app-datagrid-clientslist>
  </div>
</div>
