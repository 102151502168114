<div class="loginPage">
  <div class="w-full min-h-screen flex lg:flex-row md:flex-col flex-col">
    <div
      class="lg:w-/712 md:w-full w-full flex-1 dark:bg-register-background bg-register-light"
    >
      <div class="mt-8 mb-8 ml-4 flex items-center justify-start">
        <img class="w-auto h-10 mx-0" src="./assets/images/small_logo.png" />
        <p class="text-2xl dark:text-white font-bold">WorkSheet</p>
      </div>
      <div class="p-14">
        <img
          class="w-[720px] h-auto mx-auto"
          src="./assets/images/loginpge.png"
        />
      </div>
    </div>
    <div class="lg:w-5/12 p-10 row w-full dark:bg-register-dark">
      <div class="flex items-center justify-end lg:ml-4 md:ml-0 ml-0">
        <button
          *ngIf="isDarkMode"
          type="button"
          (click)="toggleTheme()"
          title="Toggle light mode"
          class="inline-block btn bg-gray-400 sahdow-md p-2 hover:bg-gray-200 dark:bg-opacity-8 dark:hover:bg-hover-gray-100/10 text-gray-900 dark:text-white text-center rounded-full cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          </svg>
        </button>
        <button
          *ngIf="!isDarkMode"
          type="button"
          type="button"
          (click)="toggleTheme()"
          title="Toggle dark mode"
          class="inline-block btn bg-theme-light sahdow-md p-2 hover:bg-gray-200 dark:bg-opacity-8 dark:hover:bg-hover-gray-100/10 text-gray-900 dark:text-white text-center rounded-full cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          </svg>
        </button>
      </div>

      <div class="loginFormContainer w-10/12 mx-auto">
        <div class="">
          <p class="text-2xl font-bold dark:text-lite-blue text-left">
            Welcome to WorkSheet
          </p>
          <p class="pt-2 text-sm dark:text-lite-blue">
            New to WorkSheet<span class="text-sky-blue-text ml-2"
              >Create an account</span
            >
          </p>
        </div>

        <div class="pt-8">
          <div class="relative">
            <div class="z-0 group">
              <input
                type="E-mail"
                name="email"
                id="email"
                class="add_box peer"
                placeholder=" "
                required
              />
              <label for="email" class="login_lable pointer-events-none"
                >E-mail *</label
              >
            </div>
            <!-- <p class="error_login text-xs font-register">
              E-mail is Required *
            </p> -->
          </div>
        </div>
        <div class="pt-4">
          <div class="relative">
            <div class="z-0 group">
              <input
                type="E-mail"
                name="floating_phone"
                id="email"
                class="errorinput_box peer"
                placeholder=" "
                required
              />
              <label for="email" class="errorlogin_lable pointer-events-none"
                >E-mail *</label
              >
            </div>
            <p
              class="text-right py-2 text-pink-text text-xs font-register"
              style="font-size: 12px"
            >
              E-mail is Required *
            </p>
          </div>
        </div>

        <!-- Password input -->
        <div class="">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="password"
                name="password"
                id="Password"
                class="add_box peer"
                placeholder=" "
                required
              />
              <label for="password" class="login_lable pointer-events-none"
                >Password *</label
              >
            </div>
            <!-- <p class="error_login font-register">Username is Required *</p> -->
          </div>
        </div>

        <!-- Login button -->

        <div class="pt-6">
          <div
            class="w-full rounded-lg dark:bg-save-background bg-theme-primary py-2 text-center"
          >
            <button class="text-sm text-white" href="/dashboard">
              Sign In
            </button>
          </div>
        </div>
        <div class="pt-4">
          <div class="w-full rounded-lg bg-button-lite py-2 text-center">
            <button class="text-sm text-white" href="/dashboard">
              Sign In
            </button>
          </div>
        </div>
        <!-- <div class="pt-6">
          <div class="w-full rounded-xl py-2 text-center signin">
            <a class="text-sm text-white" href="#">Register Now</a>
          </div>
        </div> -->
        <div class="pt-6">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start">
              <div class="dark:hover:bg-login-back px-3 py-2 rounded-full">
                <input
                  class="rounded p-2 dark:bg-transparent"
                  type="checkbox"
                />
              </div>
              <div
                class="ml-2 dark:text-theme-text-blue-200"
                style="font-size: 14px"
              >
                <p>Remember this Device</p>
              </div>
            </div>
            <div class="forgotPassword font-semibold text-sky-blue">
              <a href="./forgot/password">Forgot Password ?</a>
            </div>
          </div>
        </div>
        <div class="py-6">
          <div class="flex items-center justify-center">
            <div class="border w-3/12 border-gray-100"></div>
            <div class="text-sm dark:text-lite-blue text-center w-5/12">
              <p>Or Login with</p>
            </div>
            <div class="border w-3/12 border-gray-100"></div>
            <hr />
          </div>
        </div>
        <div class="">
          <div class="">
            <button
              class="border dark:border-button-lite dark:hover:bg-lite-dark hover:bg-gray-100 text-white py-2 w-full rounded-lg"
            >
              <div class="flex items-center justify-center">
                <div class="">
                  <img
                    class="w-4 h-5 dark:hidden mx-auto"
                    src="./assets/images/outline.png"
                  />
                  <img
                    class="w-4 h-5 hidden dark:block mx-auto"
                    src="./assets/images/outlinedark.png"
                  />
                </div>
                <div class="ml-2 text-black dark:text-lite-blue">
                  <p>Github</p>
                </div>
              </div>
            </button>
          </div>
          <div class="flex pt-6 items-center justify-between">
            <button
              class="border dark:border-button-lite dark:hover:bg-lite-dark hover:bg-gray-100 text-white py-2 w-full rounded-lg"
            >
              <div class="flex items-center justify-center">
                <div class="">
                  <img
                    class="w-4 h-5 mx-auto"
                    src="./assets/images/facebook.png"
                  />
                </div>
                <div class="ml-2 text-black dark:text-lite-blue">
                  <p>facebook</p>
                </div>
              </div>
            </button>
            <button
              class="border dark:border-button-lite dark:hover:bg-lite-dark hover:bg-gray-100 text-white py-2 ml-4 w-full rounded-lg"
            >
              <div class="flex items-center justify-center">
                <div class="">
                  <img
                    class="w-4 h-5 mx-auto"
                    src="./assets/images/twitter.png"
                  />
                </div>
                <div class="ml-2 text-black dark:text-lite-blue">
                  <p>Twitter</p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
