<div class="">
  <div class="w-full mx-auto">
    <div class="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 gap-4">
      <div
        class="bg-white dark:border-blue-300 dark:drop-shadow-lg border-2 gray-100 dark:drop-shadow-blue-300 dark:border-2 dark:bg-theme-main-dark drop-shadow-lg rounded-xl p-5"
      >
        <div class="">
          <div class="">
            <p class="text-xl font-semibold dark:text-blue-100 text-gray-700">
              Change Profile
            </p>
            <p class="text-sm pt-1 dark:text-blue-200 text-gray-700">
              Change your profile picture from here
            </p>
          </div>
          <div class="">
            <div class="rounded p-2">
              <img
                class="w-36 rounded-full h-auto mx-auto"
                src="./assets/images/111.png"
              />
            </div>
            <div class="py-3">
              <div class="flex items-center justify-center rtl:space-x-reverse">
                <a class="px-5 save text-white text-sm py-2 rounded-lg" href="#"
                  >Upload</a
                >
                <a
                  class="px-5 text-sm ml-2 border dark:hover:bg-blue-50 hover:bg-pink-100 border-light-pink-100 text-light-pink-100 py-2 rounded-lg"
                  href="#"
                  >Reset</a
                >
              </div>
            </div>
            <div class="pt-2">
              <p class="text-sm text-center">
                Allowed JPG, GIF or PNG. Max size of 800K
              </p>
            </div>
          </div>
        </div>

        <div class="pt-10">
          <div class="">
            <p class="text-xl font-semibold dark:text-blue-100 text-gray-700">
              Personal Details
            </p>
            <p class="text-sm pt-1 dark:text-blue-200 text-gray-700">
              To change your personal detail , edit and save from here
            </p>
          </div>
          <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-col-1 pt-5 gap-4">
            <div class="">
              <label
                class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
                for="username"
              >
                Name
              </label>
              <input
                class="shadow border hover:border dark:hover:border-lite-dark dark:hover:border-2 dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 placeholder:text-gray-700 dark:placeholder:text-blue-400 text-black dark:text-address-label focus:border-theme-primary"
                type="text"
                placeholder="Name"
              />
            </div>
            <div class="">
              <label
                class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
                for="username"
              >
                User ID
              </label>
              <input
                class="shadow border hover:border-black hover:border dark:hover:border-lite-dark dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
                id="current"
                type="text"
                placeholder="User Id"
              />
            </div>
            <div class="">
              <label
                class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
                for="username"
              >
                E-mail
              </label>
              <input
                class="shadow border hover:border-black hover:border dark:hover:border-lite-dark dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
                id="current"
                type="text"
                placeholder="E-mail"
              />
            </div>

            <div class="">
              <label
                class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
                for="username"
              >
                Mobile
              </label>
              <input
                class="shadow border hover:border-black hover:border dark:hover:border-lite-dark dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
                id="current"
                type="number"
                placeholder="Mobile"
              />
            </div>
            <div class="">
              <label
                class="block text-gray-700 text-sm dark:text-blue-200 font-bold mb-2"
                for="username"
              >
                Phone
              </label>
              <input
                class="shadow border hover:border-black hover:border dark:hover:border-lite-dark dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
                id="current"
                type="number"
                placeholder="Phone"
              />
            </div>
            <div class="lg:block md:block hidden"></div>
            <div class="lg:col-span-2 md:col-span-2 cols-span-1">
              <label
                class="block text-gray-700 text-sm dark:text-blue-200 font-bold mb-2"
                for="username"
              >
                Address
              </label>
              <textarea
                class="h-20 rounded-lg w-full p-2 border hover:border dark:hover:border-lite-dark dark:bg-transparent text-black dark:text-white bg-transparent placeholder:text-gray-700 hover:border-black dark:placeholder:text-blue-400 focus:border-2 focus:border-theme-primary"
              ></textarea>
            </div>
            <div class="lg:col-span-2 md:col-span-2 cols-span-1">
              <div
                class="flex items-center justify-end ltr:space-x-3 rtl:space-x-3 rtl:space-x-reverse"
              >
                <a class="px-5 save text-white text-sm py-2 rounded-lg" href="#"
                  >Save</a
                >
                <a
                  class="px-5 text-pink-text text-sm border dark:hover:bg-blue-50 hover:bg-pink-100 border-pink-text py-2 rounded-lg"
                  href="#"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
