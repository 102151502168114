<div class="adminConsoleWrapper">
  <div class="flex flex-col">
    <h2
      class="text-2xl ltr:text-left rtl:text-right font-bold text-theme-primary rounded-t-full py-5"
    >
      Settings
    </h2>
    <div
      class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-6 gap-y-8"
    >
      <!-- <app-blurb-admin-console
        link="settings/org"
        type="setting"
        heading="Orgainisation"
        subheading="Edit your Organisation settings."
        image="setting.png"
      /> -->
      <app-blurb-admin-console
        link="settings/org"
        type="setting"
        heading="Orgainisation"
        subheading="Edit your Organisation settings."
        image="setting.png"
      />

      <app-blurb-admin-console
        link="/app/admin/client/setting"
        type="setting"
        heading="Clients"
        subheading="Settings related to clients"
        image="client.png"
      />

      <app-blurb-admin-console
        link="/app/admin/job/setting"
        type="setting"
        heading="Jobs"
        subheading="Edit your Job settings such as categories, states and
            templates."
        image="job.png"
      />

      <app-blurb-admin-console
        link="/app/admin/staff/user/setting"
        type="setting"
        heading="Staffs / Users"
        subheading="Manage Staffs / Users"
        image="staff_users.png"
      />

      <app-blurb-admin-console
        link="/app/admin/role/setting"
        type="setting"
        heading="Role"
        subheading="Manage Role for your Staffs"
        image="role.png"
      />

      <app-blurb-admin-console
        link="/app/admin/team/setting"
        type="setting"
        heading="Team"
        subheading="Create Teams and group staffs to team"
        image="staff.png"
      />

      <app-blurb-admin-console
        link="/app/admin/task/setting"
        type="setting"
        heading="Tasks"
        subheading="Manage Tasks that you frequently use on jobs, quotes and
            invoices."
        image="task.png"
      />

      <app-blurb-admin-console
        link="/app/admin/cost/setting"
        type="setting"
        heading="Costs"
        subheading="Manage Costs that you frequently use on jobs, quotes and
            invoices."
        image="cost.png"
      />

      <app-blurb-admin-console
        link="/app/admin/support/setting"
        type="setting"
        heading="Support"
        subheading="Manage Support that you frequently use on quotes and
            invoices."
        image="support.png"
      />

      <app-blurb-admin-console
        link="/app/admin/csv/template"
        type="setting"
        heading="CSV Templates"
        subheading=" Manage template of CSV headers for various
            Organizations."
        image="csv.png"
      />

      <app-blurb-admin-console
        link="/app/admin/email/template"
        type="setting"
        heading="Email Templates"
        subheading=" Manage Email Templates."
        image="mail.png"
      />
    </div>
    <h2
      class="text-2xl ltr:text-left rtl:text-right font-bold text-[#fc4b6c] rounded-t-full py-5"
    >
      Features
    </h2>
    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-8">
      <app-blurb-admin-console
        link="/app/custom/fields"
        type="feature"
        heading="Custom Fields"
        subheading="Custom/user defined fields for clients, jobs and more."
        image="feedback.png"
      />

      <app-blurb-admin-console
        link="/app/custom/template"
        type="feature"
        heading="Custom Templates"
        subheading="Custom/user defined fields for clients, jobs and more."
        image="custom.png"
      />
    </div>
    <h2
      class="text-2xl ltr:text-left rtl:text-right font-bold text-[#03c9d7] rounded-t-full py-5"
    >
      Integrations
    </h2>
    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-8">
      <app-blurb-admin-console
        link="/app/zero/setting"
        type="integration"
        heading="Xero"
        subheading="Send Invoices and purchase receipts to Xero."
        image="xero.png"
      />

      <app-blurb-admin-console
        link="/app/document/Management"
        type="integration"
        heading="Document Management"
        subheading="Store your doucments in the cloud with Google Drive."
        image="document.png"
      />
    </div>
  </div>
</div>
