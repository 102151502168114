import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-orders-main',
  templateUrl: './orders-main.component.html',
})
export class OrdersMainComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      date: '29-May-2023',
      no: 'PO00005',
      supplier: 'HiveMeet',
      job: 'Quote1101',
      state: 'Tamil nadu',
      amount: '1100.00',
    },
    {
      date: '29-May-2023',
      no: 'PO00006',
      supplier: 'Apollo Tyres',
      job: 'Quote-2905',
      state: 'Karnataka',
      amount: '2200.00',
    },
    {
      date: '01-Jun-2023',
      no: 'PO00012',
      supplier: 'ABC Corp',
      job: 'Quote-3107',
      state: 'Kerla',
      amount: '1100.00',
    },
    {
      date: '19-Jun-2023',
      no: '	PO00020',
      supplier: 'XYZ Tech',
      job: 'Quote-1304',
      state: 'Andhra Pradesh',
      amount: '1100.00',
    },
    {
      date: '09-Jun-2023',
      no: 'PO00019',
      supplier: 'Tech Solutions',
      job: 'Quote-0903',
      state: 'Telangana',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00027',
      supplier: 'Globalex Inc',
      job: 'Quote-2302',
      state: 'Uttar Pradesh',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00028',
      supplier: 'MRF Tyres',
      job: 'Quote-2302',
      state: 'Rajasthan',
      amount: '33000.00	',
    },
    {
      date: '29-May-2023',
      no: 'PO00005',
      supplier: 'HiveMeet',
      job: 'Quote1101',
      state: 'Tamil nadu',
      amount: '1100.00',
    },
    {
      date: '29-May-2023',
      no: 'PO00006',
      supplier: 'Apollo Tyres',
      job: 'Quote-2905',
      state: 'Karnataka',
      amount: '2200.00',
    },
    {
      date: '01-Jun-2023',
      no: 'PO00012',
      supplier: 'ABC Corp',
      job: 'Quote-3107',
      state: 'Kerla',
      amount: '1100.00',
    },
    {
      date: '19-Jun-2023',
      no: '	PO00020',
      supplier: 'XYZ Tech',
      job: 'Quote-1304',
      state: 'Andhra Pradesh',
      amount: '1100.00',
    },
    {
      date: '09-Jun-2023',
      no: 'PO00019',
      supplier: 'Tech Solutions',
      job: 'Quote-0903',
      state: 'Telangana',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00027',
      supplier: 'Globalex Inc',
      job: 'Quote-2302',
      state: 'Uttar Pradesh',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00028',
      supplier: 'MRF Tyres',
      job: 'Quote-2302',
      state: 'Rajasthan',
      amount: '33000.00	',
    },
    {
      date: '29-May-2023',
      no: 'PO00005',
      supplier: 'HiveMeet',
      job: 'Quote1101',
      state: 'Tamil nadu',
      amount: '1100.00',
    },
    {
      date: '29-May-2023',
      no: 'PO00006',
      supplier: 'Apollo Tyres',
      job: 'Quote-2905',
      state: 'Karnataka',
      amount: '2200.00',
    },
    {
      date: '01-Jun-2023',
      no: 'PO00012',
      supplier: 'ABC Corp',
      job: 'Quote-3107',
      state: 'Kerla',
      amount: '1100.00',
    },
    {
      date: '19-Jun-2023',
      no: '	PO00020',
      supplier: 'XYZ Tech',
      job: 'Quote-1304',
      state: 'Andhra Pradesh',
      amount: '1100.00',
    },
    {
      date: '09-Jun-2023',
      no: 'PO00019',
      supplier: 'Tech Solutions',
      job: 'Quote-0903',
      state: 'Telangana',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00027',
      supplier: 'Globalex Inc',
      job: 'Quote-2302',
      state: 'Uttar Pradesh',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00028',
      supplier: 'MRF Tyres',
      job: 'Quote-2302',
      state: 'Rajasthan',
      amount: '33000.00	',
    },
    {
      date: '29-May-2023',
      no: 'PO00005',
      supplier: 'HiveMeet',
      job: 'Quote1101',
      state: 'Tamil nadu',
      amount: '1100.00',
    },
    {
      date: '29-May-2023',
      no: 'PO00006',
      supplier: 'Apollo Tyres',
      job: 'Quote-2905',
      state: 'Karnataka',
      amount: '2200.00',
    },
    {
      date: '01-Jun-2023',
      no: 'PO00012',
      supplier: 'ABC Corp',
      job: 'Quote-3107',
      state: 'Kerla',
      amount: '1100.00',
    },
    {
      date: '19-Jun-2023',
      no: '	PO00020',
      supplier: 'XYZ Tech',
      job: 'Quote-1304',
      state: 'Andhra Pradesh',
      amount: '1100.00',
    },
    {
      date: '09-Jun-2023',
      no: 'PO00019',
      supplier: 'Tech Solutions',
      job: 'Quote-0903',
      state: 'Telangana',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00027',
      supplier: 'Globalex Inc',
      job: 'Quote-2302',
      state: 'Uttar Pradesh',
      amount: '11000.00',
    },
    {
      date: '23-Jun-2023',
      no: 'PO00028',
      supplier: 'MRF Tyres',
      job: 'Quote-2302',
      state: 'Rajasthan',
      amount: '33000.00	',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.date}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.date;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `<div class="pl-1">
            <a href="${params.node.data.link}">
            <h4 class=" font-bold list-font hover:underline">${params.node.data.date}</h4>
            
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-sky-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
          Quotes : ${params.value.no}
        </div>
        <div class="flex bg-pink-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
          Invoices: ${params.value.supplier}
        </div>
       
        <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
        Jobs : ${params.value.job}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
      Jobs : ${params.value.state}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
    Jobs : ${params.value.amount}
  </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 300,
      resizable: true,
      cellRenderer: this.nameCellRenderer,
      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'no',
      headerName: 'No',
    },
    {
      field: 'supplier',
      headerName: 'Billing',
    },

    {
      field: 'job',
      headerName: 'Job',
    },
    {
      field: 'state',
      headerName: 'State',
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationPageSize = 10;
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
