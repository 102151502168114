import { Component } from '@angular/core';
import { DatagridClientsContactsComponent } from '../datagrid/datagrid-clients-contacts/datagrid-clients-contacts.component';

@Component({
  selector: 'app-clientdetail-contacts',
  templateUrl: './clientdetail-contacts.component.html'
})
export class ClientdetailContactsComponent {

}
