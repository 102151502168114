<div class="relative rounded-full">
  <button
    class="w-fit hover:bg-gray-200 p-2 rounded-full dark:hover:bg-gray-200/10"
    id="local_Button"
    (click)="toggleDropdown()"
    type="button"
  >
    <img
      class="w-5 rounded-full rounded-circle object-cover h-5"
      style="border-radius: 50px"
      src="./assets/images/icon-flag-en.svg"
    />
  </button>
  <div class="absolute top-0 ltr:ml-3 rtl:mr-3"></div>
</div>
<div
  id="local"
  *ngIf="isDropdownOpen"
  class="z-10 bg-white absolute rounded-lg shadow w-fit dark:bg-gray-700"
  style="margin-right: 20px !important"
>
  <div class="py-2">
    <div class="">
      <div class="">
        <div
          class="flex hover:bg-gray-100 dark:hover:bg-top-hover p-2 px-4 items-center justify-start"
        >
          <div class="rounded-full p-2">
            <img
              class="w-5 rounded-circle object-cover h-5"
              style="border-radius: 50px"
              src="./assets/images/icon-flag-en.svg"
            />
          </div>
          <div class="ml-4">
            <div class="">
              <label
                for="directionToggle"
                class="text-sm hover:text-sky-blue-text cursor-pointer dark:hover:text-sky-blue-text dark:text-white font-bold"
              >
                <input
                  type="checkbox"
                  id="directionToggle"
                  (change)="toggleDirection()"
                  class="hidden"
                />

                {{ directionClass === "rtl" ? "English" : "English" }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 dark:hover:bg-top-hover p-2 px-4 items-center justify-start"
        >
          <div class="rounded-full p-2">
            <img
              class="w-5 rounded-circle object-cover h-5"
              style="border-radius: 50px"
              src="./assets/images/united-arab-emirates.png"
            />
          </div>
          <div class="ml-4">
            <div class="">
              <label
                for="directionToggle"
                class="text-sm hover:text-sky-blue-text cursor-pointer dark:hover:text-sky-blue-text dark:text-white font-bold"
              >
                <input
                  type="checkbox"
                  id="directionToggle"
                  (change)="toggleDirection()"
                  class="hidden"
                />
                {{ directionClass === "ltr" ? "Arabic" : "Arabic" }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 dark:hover:bg-top-hover p-2 px-4 items-center justify-start"
        >
          <div class="rounded-full p-2">
            <img
              class="w-5 rounded-circle object-cover h-5"
              style="border-radius: 50px"
              src="./assets/images/spain.png"
            />
          </div>
          <div class="ml-4 cursor-pointer">
            <div
              class="text-sm hover:text-sky-blue-text dark:text-white dark:hover:text-sky-blue-text font-bold"
            >
              <p>Spanish</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
