<div
  class="flex items-center ltr:space-x-4 pb-5 rtl:space-x-4 rtl:space-x-reverse"
>
  <a
    routerLink="/app/admin"
    class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </a>
  <h1 class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200">
    Organisation Settings
  </h1>
</div>
<div
  class="addSupplierForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 shadow dark:shadow-slate-700 rounded-xl"
>
  <div class="w-full ml-auto">
    <form>
      <div
        class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-x-8 md:gap-x-8 gap-x-0"
      >
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">Account</h4>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="name"
                id="org_name"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="name" class="input_label">Name *</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="time"
              [suggestions]="filteredtimes"
              (completeMethod)="filtertimes($event)"
              [dropdown]="true"
              name="times"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Time Zone</label>
          </span>
        </div>

        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Address</label>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="task_heading"
                id="taskheading"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="task_heading" class="input_label"
                >Task Heading *</label
              >
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="support_heading"
                id="supportheading"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="support_heading" class="input_label"
                >Support Heading *</label
              >
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Cost_heading"
                id="Costheading"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Cost_heading" class="input_label"
                >Cost Heading *</label
              >
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="option_heading"
                id="optionheading"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="option_heading" class="input_label"
                >Option Heading *</label
              >
            </div>
            <p class="text-sm text-gray-500 ml-2 pt-2">
              Option Heading is the heading to print for optional items on
              quotes.
            </p>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="">
            <h4 class="text-xl dark:text-theme-text-blue-200">Client</h4>
          </div>
          <div class="flex pt-5 items-center justify-start">
            <div class="inline-flex items-center">
              <label
                class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
                for="checkbox"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                  id="checkbox"
                />
                <div
                  class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </label>
            </div>
            <div
              class="text-sm dark:text-theme-text-blue-200 ltr:ml-2 rtl:mr-2"
            >
              <p>Show Postal Address</p>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">Cost</h4>
        </div>
        <!-- Coulmn 1 Starts -->

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="mark"
                id="markup"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="Sales_margin" class="input_label">Markup %* </label>
            </div>
          </div>
        </div>

        <div
          class="flex lg:col-span-1 pb-5 md:col-span-2 col-span-2 items-center justify-start"
        >
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm dark:text-theme-text-blue-200 ltr:ml-2 rtl:mr-2">
            <p>Billable</p>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="round"
              [suggestions]="filteredtimes"
              (completeMethod)="filtertimes($event)"
              [dropdown]="true"
              name="rounds"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Time Zone</label>
          </span>
        </div>

        <!-- Column 2 Ends -->

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">Support</h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Export_Code"
                id="export_code_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Export_Code" class="input_label"
                >Help Desk Margin %
              </label>
            </div>
          </div>
        </div>
        <div
          class="text-right text-sm text-blue-500 lg:col-span-1 pb-5 md:col-span-2 col-span-2"
        >
          <a href="#">Add UpLift Margin (+)</a>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="Sales_margin"
                id="Salesmargin"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Sales_margin" class="input_label"
                >Sales Margin %
              </label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="lift"
              [suggestions]="filteredlifts"
              (completeMethod)="filterlifts($event)"
              [dropdown]="true"
              name="lifts"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete"> UpLift Margin %</label>
          </span>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">Financial</h4>
        </div>
        <!-- Coulmn 1 Starts -->

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="tax_name"
                id="taxname"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="tax_name" class="input_label">Tax 1 Name*</label>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="tax_rate"
                id="taxrate"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="tax_rate" class="input_label">Tax 1 Rate *</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="finance"
              [suggestions]="filteredfinances"
              (completeMethod)="filterfinances($event)"
              [dropdown]="true"
              name="finances"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Financial Year End</label>
          </span>
        </div>

        <div class="lg:col-span-2 md:cols-span-2 cols-span-1 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">Job</h4>
        </div>
        <!-- Coulmn 1 Starts -->
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="state"
              [suggestions]="filteredstates"
              (completeMethod)="filterstates($event)"
              [dropdown]="true"
              name="states"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Default State</label>
          </span>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="template"
              [suggestions]="filteredtemplates"
              (completeMethod)="filtertemplates($event)"
              [dropdown]="true"
              name="templates"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Default Template</label>
          </span>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="invoice"
              [suggestions]="filteredinvoices"
              (completeMethod)="filterinvoices($event)"
              [dropdown]="true"
              name="invoices"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Invoiced State</label>
          </span>
          <p class="text-sm text-gray-500 pt-2">
            Progress jobs to the above state when invoiced.
          </p>
        </div>

        <div></div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="job"
              [suggestions]="filteredjobs"
              (completeMethod)="filterjobs($event)"
              [dropdown]="true"
              name="jobs"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete"> My Jobs List</label>
          </span>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="task"
              [suggestions]="filteredtasks"
              (completeMethod)="filtertasks($event)"
              [dropdown]="true"
              name="tasks"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Job Task Order</label>
          </span>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="taskrate"
              [suggestions]="filteredtasksrate"
              (completeMethod)="filtertasksrate($event)"
              [dropdown]="true"
              name="tasksrate"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Task Invoice Rate</label>
          </span>
          <p class="text-sm text-gray-500 pt-2">
            Changes to the Task Invoice Rate will apply to new jobs only.
          </p>
        </div>

        <div></div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="timeallo"
              [suggestions]="filteredtimeallos"
              (completeMethod)="filtertimeallos($event)"
              [dropdown]="true"
              name="timeallos"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Time Allocation</label>
          </span>
          <p class="text-sm text-gray-500 pt-2">
            Changes to the Task Invoice Rate will apply to new jobs only.
          </p>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="staff"
              [suggestions]="filteredstaffs"
              (completeMethod)="filterstaffs($event)"
              [dropdown]="true"
              name="staffs"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Staff Task Allocation</label>
          </span>
        </div>

        <div class="flex pt-5 pb-5 col-span-2 items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>

          <div class="text-sm dark:text-theme-text-blue-200 ltr:ml-4 rtl:mr-4">
            <p>
              When calculating the Estimated Billings for a job, if the job has
              an accepted quote/estimate then base the calculation on the
              quoted/estimated value and not the actual time worked on the job.
            </p>
          </div>
        </div>
        <div class="flex pt-5 pb-5 col-span-2 items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm dark:text-theme-text-blue-200 ltr:ml-4 rtl:mr-4">
            <p>When adding a cost to a job, also add the cost to Cost Admin.</p>
          </div>
        </div>
        <div class="col-span-2 pt-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">Invoices</h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="invoicetype"
              [suggestions]="filteredinvoicetypes"
              (completeMethod)="filterinvoicetypes($event)"
              [dropdown]="true"
              name="invoicetypes"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Default Invoice Type</label>
          </span>
        </div>

        <div></div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="payment"
              [suggestions]="filteredpayments"
              (completeMethod)="filterpayments($event)"
              [dropdown]="true"
              name="payments"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete"> Payment Terms</label>
          </span>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="Days"
                id="Day"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="Days" class="input_label">Days</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="timesheet"
              [suggestions]="filteredtimesheets"
              (completeMethod)="filtertimesheets($event)"
              [dropdown]="true"
              name="timesheets"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Time Sheet Notes</label>
          </span>

          <p class="text-sm pt-2 text-gray-500">
            Include time sheet notes in the description on the invoice.
          </p>
        </div>

        <div></div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="default_percentage"
                id="defaultpercentage"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="default_percentage" class="input_label"
                >Default Percentage:</label
              >
            </div>
            <p class="text-sm text-gray-500 pt-2">
              Default percentage when invoicing based on a percentage of the
              quoted value.
            </p>
          </div>
        </div>
        <div></div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Footer</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 md:cols-span-2 cols-span-1 pt-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">Payment Advice</h4>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="heading"
                id="hed"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="heading" class="input_label">Heading</label>
            </div>
          </div>
        </div>
        <div></div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Details</label>
            </div>
          </div>
        </div>
        <div
          class="lg:col-span-2 md:cols-span-2 dark:text-theme-text-blue-200 cols-span-1 pt-2 mb-8"
        >
          <h4 class="text-xl">Purchase Order</h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="bulk"
              [suggestions]="filteredbulks"
              (completeMethod)="filterbulks($event)"
              [dropdown]="true"
              name="bulks"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Bulk PO Job Order</label>
          </span>
        </div>

        <div></div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label"
                >Default Delivery Address</label
              >
            </div>
          </div>
        </div>
        <div class="lg:block md:block hidden"></div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Footer</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 md:cols-span-2 cols-span-1 pt-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Purchase Receipt
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="paymenterm"
              [suggestions]="filteredpaymenterms"
              (completeMethod)="filterbulks($event)"
              [dropdown]="true"
              name="paymenterms"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Payment Terms</label>
          </span>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="Day"
                id="day"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Day" class="input_label">Day</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 md:cols-span-2 cols-span-1 pt-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">Quote</h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="valid_days"
                id="validays"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="valid_days" class="input_label"
                >Valid For (Days)</label
              >
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="revision"
              [suggestions]="filteredrevisions"
              (completeMethod)="filterrevisions($event)"
              [dropdown]="true"
              name="revisions"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Revision Separator</label>
          </span>
        </div>

        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label"
                >Revision SeperatorQuote Footer</label
              >
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label"
                >Estimate Footer</label
              >
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 cols-span-1 pt-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">Reports</h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="timeformat"
              [suggestions]="filteredtimeformats"
              (completeMethod)="filtertimeformats($event)"
              [dropdown]="true"
              name="timeformats"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Time Format</label>
          </span>
        </div>

        <div class="py-5 col-span-2">
          <div
            class="flex items-center justify-start space-x-2 rtl:space-x-reverse"
          >
            <a class="px-5 text-white mr-2 py-2 cancel rounded-xl" href="#"
              >Cancel</a
            >
            <a class="px-5 text-white save py-2 rounded-xl" href="#">Save</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
