import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-userprofile-account',
  templateUrl: './userprofile-account.component.html',
})
export class UserprofileAccountComponent {
  showSummaryCard: boolean = true;
  showTabNumber: number = 1;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    initFlowbite();
    this.route.queryParams.subscribe((params) => {
      const tabNumber = +params['tab'];
      if (tabNumber) {
        this.showTabNumber = tabNumber;
      }
    });
  }

  toggleTab(tabNumber: number) {
    this.showTabNumber = tabNumber;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabNumber },
      queryParamsHandling: 'merge',
    });
  }
}
