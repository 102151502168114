<div>
  <div class="flex supplier-box justify-between flex-row items-center pb-5">
    <div
      class="flex supplier flex-row lg:justify-none md:justify-between justify-between items-center"
    >
      <h2
        class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200"
      >
        Suppliers
      </h2>
      <div
        class="lg:ltr:ml-4 lg:pt-0 md:pt-0 pt-2 lg:rtl:mr-4 md:ltr:ml-2 md:rtl:mr-2 ltr:ml-2 rtl:mr-0"
      >
        <a
          routerLink="/app/interaction/suppliers/add"
          class="btn bg-theme-primary hover:bg-save-hover text-white rounded-2xl lg:px-4 md:px-4 px-2 lg:py-2 md:py-2 py-1 lg:text-sm md:text-sm text-xs"
        >
          + Add Supplier
        </a>
      </div>
    </div>
    <app-supplier-filter></app-supplier-filter>
  </div>
  <div class="list-page">
    <app-datagrid-supllier-list></app-datagrid-supllier-list>
  </div>
</div>
