import { Component } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-admin-settings-email-templates',
  templateUrl: './admin-settings-email-templates.component.html'
})
export class AdminSettingsEmailTemplatesComponent {
  gridOptions: GridOptions = {}
  gridApi: GridApi = {} as GridApi;

rowData: any = [
    {
      subject: "New Client Welcome Email",
      status: "Active",
      type: "Client"
    },
    {
      subject: "New Client Notification",
      status: "Active",
      type: "Client"
    },
    {
      subject: "New Quote Notification",
      status: "Active",
      type: "Quote"
    },
    {
      subject: "New Invoice Notification",
      status: "Active",
      type: "Invoice"
    },
    {
      subject: "New Purchase Order Notification",
      status: "Active",
      type: "Purchase Order"
    },
    {
      subject: "New Job Notification",
      status: "Active",
      type: "Job"
    },
    {
      subject: "New Task Notification",
      status: "Active",
      type: "Task"
    },
    {
      subject: "Task Update Notification",
      status: "Active",
      type: "Task"
    },
    {
      subject: "Task Complete Notification",
      status: "Active",
      type: "Task"
    },
    {
      subject: "New Time Entry Notification",
      status: "Active",
      type: "Time Entry"
    },
    {
      subject: "Time Entry Approval Notification",
      status: "Active",
      type: "Time Entry"
    },
    {
      subject: "Time Entry Rejection Notification",
      status: "Active",
      type: "Time Entry"
    },
    {
      subject: "Time Entry Query Notification",
      status: "Active",
      type: "Expense"
    },
    {
      subject: "Password Reset Notification",
      status: "Active",
      type: "System"
    },
    {
      subject: "New User Notification",
      status: "Active",
      type: "System"
    }

];

  columnDefs: ColDef[] = [
      { field: 'subject', headerName: 'Subject', sortable: true, filter: true, resizable: false, flex: 1 },
  ];

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onTypeChanged() {
    console.log('onTypeChanged');

    const selectedValue =  (document.getElementById('typeFilter') as HTMLSelectElement).value;
    console.log(selectedValue);

    this.gridApi.setFilterModel({
      field: 'type',
      vale: selectedValue
    });
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

}
