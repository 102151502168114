<div
  class="flex items-center pb-5 ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
>
  <a
    routerLink="/app/admin/staff/user/setting"
    class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </a>
  <h1 class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200">
    Add New Client
  </h1>
</div>
<div
  class="addClientForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 shadow dark:shadow-slate-700 rounded-xl"
>
  <div class="w-full ml-auto">
    <form>
      <div
        class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-x-8 md:gap-x-8 gap-x-0"
      >
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            General Information
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="name"
                id="name"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="name" class="input_label">Name *</label>
            </div>
          </div>
        </div>
        <div
          class="flex lg:col-span-1 pb-5 md:col-span-2 col-span-2 items-center justify-start"
        >
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm dark:text-theme-text-blue-200 ltr:ml-2 rtl:mr-2">
            <p>Currently Working</p>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="role"
              [suggestions]="filteredroles"
              (completeMethod)="filterroles($event)"
              [dropdown]="true"
              name="roles"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Role</label>
          </span>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="email"
                name="e_mail"
                id="emai"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="e_mail" class="input_label">E-mail *</label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Notes</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Financial Information
          </h4>
        </div>
        <!-- Coulmn 1 Starts -->
        <div class="lg:col-span-1 md:col-span-2 col-span-2">
          <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="base_rate"
                  id="baserate"
                  class="peer input_box"
                  placeholder=" "
                  required
                />
                <label for="base_rate" class="input_label">Base Rate</label>
              </div>
              <div class="pt-2 text-sm text-gray-500">
                <p>
                  The Billable Rate is only required if the "Task Invoice Rate"
                  option in Preferences is configured as "Staff Billable Rate".
                </p>
              </div>
            </div>
          </div>
          <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="payroll"
                  id="pay_role"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="payroll" class="input_label">Payroll Code</label>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="billable_rate"
                id="billablerate"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="billable_rate" class="input_label"
                >Billable Rate</label
              >
            </div>
          </div>
        </div>

        <!-- Column 2 Ends -->

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Contact Information
          </h4>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label">Address</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative pt-2">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="phone"
                id="ph_one"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="phone" class="input_label">Phone </label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="mobile"
                id="mo_bile"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="mobile" class="input_label">Mobile</label>
            </div>
          </div>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Interface Information
          </h4>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Export_Code"
                id="export_code_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Export_Code" class="input_label">Export Code </label>
            </div>
          </div>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <hr class="border-gray-300" />
        </div>
        <div class="py-5">
          <div
            class="flex items-center justify-start space-x-2 rtl:space-x-reverse"
          >
            <a class="px-5 text-white save py-2 rounded-xl" href="#">Save</a>
            <a class="px-5 text-white cancel py-2 rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
