import { Component, OnInit } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-clientadd',
  templateUrl: './clientadd.component.html',
})
export class ClientaddComponent implements OnInit {
  date: Date | undefined;
  months: any[] = [];
  bussiness: any = null;
  selectedMonth: any = null;
  filteredMonths: any[] = [];
  filteredbussiness: any[] = [];
  filteredtyre: any[] = [];
  filteredjob: any[] = [];
  filteredaccount: any[] = [];
  filteredclient: any[] = [];
  filteredbustructure: any[] = [];
  filteredbussinesstr: any[] = [];
  formGroup: FormGroup | undefined;
  tyre: any[] | undefined;
  jobs: any[] | undefined;
  accounts: any[] | undefined;
  clients: any[] | undefined;
  bustructures: any[] | undefined;
  bussinesstr: any[] | undefined;

  constructor() {}

  ngOnInit() {
    this.months = [
      'January 01',
      'January 02',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.bussiness = [
      'Compan',
      'Individual',
      'Patnership',
      'Self Managed Superannuation Fund',
      'Sole Trader',
      'Superannuation Fund',
      'Trust',
    ];
    this.clients = ['Client type 1', 'Client type 2', 'Client type 3'];
    this.bustructures = [
      'Individual',
      'Patnership',
      'Self Managed Superannuation Fund',
      'Sole Trader',
      'Superannuation Fund',
      'Trust',
    ];
    this.bussinesstr = [
      'Individual',
      'Patnership',
      'Self Managed Superannuation Fund',
      'Sole Trader',
      'Superannuation Fund',
      'Trust',
    ];
    this.accounts = ['Surya', 'Ashwin', 'Suresh'];
    this.jobs = ['Arun', 'Surji', 'Sandy'];
    this.tyre = ['1', '2', '3'];
    this.formGroup = new FormGroup({
      selectedMonth: new FormControl<object | null>(null),
    });
  }

  filterMonths(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.months as any[]).length; i++) {
      let month = (this.months as any[])[i];
      if (month.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(month);
      }
    }

    this.filteredMonths = filtered;
  }

  filterbussiness(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.bussiness as any[]).length; i++) {
      let bussiness = (this.bussiness as any[])[i];
      if (bussiness.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(bussiness);
      }
    }

    this.filteredbussiness = filtered;
  }
  filtertyre(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tyre as any[]).length; i++) {
      let tyre = (this.tyre as any[])[i];
      if (tyre.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(tyre);
      }
    }

    this.filteredtyre = filtered;
  }
  filterjob(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.jobs as any[]).length; i++) {
      let jobs = (this.jobs as any[])[i];
      if (jobs.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(jobs);
      }
    }

    this.filteredjob = filtered;
  }
  filteraccount(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.accounts as any[]).length; i++) {
      let accounts = (this.accounts as any[])[i];
      if (accounts.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(accounts);
      }
    }

    this.filteredaccount = filtered;
  }
  filterclient(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.clients as any[]).length; i++) {
      let clients = (this.clients as any[])[i];
      if (clients.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(clients);
      }
    }

    this.filteredclient = filtered;
  }
  filterbustructure(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.bustructures as any[]).length; i++) {
      let bustructures = (this.bustructures as any[])[i];
      if (bustructures.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(bustructures);
      }
    }

    this.filteredbustructure = filtered;
  }
  filterbussinesstr(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.bussinesstr as any[]).length; i++) {
      let bussinesstr = (this.bussinesstr as any[])[i];
      if (bussinesstr.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(bussinesstr);
      }
    }

    this.filteredbussinesstr = filtered;
  }
}
