import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkModeKey = 'darkMode';

  constructor() {}

  ngOnInit(): void {
    // get the current theme from local storage
    const darkMode = localStorage.getItem(this.darkModeKey);
    if (darkMode === 'true') {
      this.setDarkMode();
    }
  }

  setDarkMode() {
    localStorage.setItem(this.darkModeKey, 'true');
    document.documentElement.classList.add('dark');
  }

  setLightMode() {
    localStorage.setItem(this.darkModeKey, 'false');
    document.documentElement.classList.remove('dark');
  }

  isDarkModeEnabled(): boolean {
    const darkMode = localStorage.getItem(this.darkModeKey);
    return darkMode === 'true';
  }
}
