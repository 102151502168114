import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-blurb-admin-console',
  template: `
    <a [routerLink]="link">
      <div
        class="btn cursor-pointer flex lg:flex-row md:flex-row flex-col bg-white dark:bg-theme-main-dark dark:border dark:border-theme-wui-700 shadow-sm hover:shadow-xl dark:text-white  p-3 rounded-xl h-auto hover:bg-oragnize-console"
        type="button"
      >
        <div
          class="p-4 rounded-full min-w-20 lg:mx-0 md:mx-0 mx-auto min-h-20 w-20 h-20"
          [ngClass]="type"
        >
          <img
            [src]="'./assets/images/' + image"
            class="min-h-12 min-w-12 h-12 w-12 lg:mx-0 md:mx-0 mx-auto object-contain"
          />
        </div>
        <div
          class="flex-1 flex flex-col lg:text-left md:text-left text-center lg:pt-0 md:pt-0 pt-4 ltr:pl-4 rtl:pr-4"
        >
          <h4 class="text-lg ">{{ heading }}</h4>
          <p class="text-sm font-extralight">{{ subheading }}</p>
        </div>
      </div>
    </a>
  `,
  styles: [],
})
export class BlurbAdminConsoleComponent {
  @Input() heading: string = '';
  @Input() subheading: string = '';
  @Input() image: string = '';
  @Input() type: string = 'setting';
  @Input() link: string = '';

  constructor() {}
}
