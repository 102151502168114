<div
  class="grid lg:grid-cols-7 md:grid-cols-6 grid-cols-1 p-2 lg:gap-0 md:gap-0 gap-4 lg:space-x-6 md:space-x-6 space-x-0"
>
  <div
    class="lg:col-span-3 md:col-span-3 col-span-1 flex lg:flex-row md:flex-row flex-col w-full justify-between px-2 py-2"
  >
    <div class="flex lg:flex-row md:flex-row flex-col">
      <div class="lg:mx-4 md:mx-4 mx-auto">
        <img
          src="https://place-hold.it/100x100?text=BrandLogo"
          class="rounded-3xl"
        />
      </div>
      <div class="lg:pt-0 md:pt-0 pt-4">
        <p class="text-xs lg:text-left md:text-left text-center text-gray-400">
          Account Manager
        </p>
        <p
          class="lg:text-base md:text-base text-center dark:text-white font-bold mb-4"
        >
          Ramakrishnan
        </p>
        <p class="text-xs lg:text-left md:text-left text-center text-gray-400">
          Job Manager
        </p>
        <p
          class="lg:text-base md:text-base text-center dark:text-white font-bold mb-2"
        >
          Muthukumar
        </p>
      </div>
    </div>
    <div class="">
      <p class="text-xs lg:text-right md:text-right text-center text-gray-400">
        Email
      </p>
      <p
        class="text-sm lg:text-right md:text-right text-center dark:text-white font-bold mb-1"
      >
        info@appolotyres.com
      </p>
      <p class="text-xs lg:text-right md:text-right text-center text-gray-400">
        Phone
      </p>
      <p
        class="text-sm lg:text-right md:text-right text-center dark:text-white font-bold mb-1"
      >
        +91 44 1299 9876
      </p>
      <p class="text-xs lg:text-right md:text-right text-center text-gray-400">
        HO
      </p>
      <p
        class="text-sm lg:text-right md:text-right text-center dark:text-white font-bold mb-1"
      >
        Chennai, India
      </p>
    </div>
  </div>

  <div
    class="flex flex-col justify-center items-center transition ease-in-out delay-100 hover:scale-110 duration-300 px-4 py-2 dark:bg-theme-main-dark dark:border-2 dark:border-dashboard-border bg-indigo-50 rounded-3xl hover-animation"
  >
    <div
      class="bg-indigo-100 text-indigo-300 dark:bg-indigo-100 dark:text-indigo-400 w-fit rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    </div>
    <p class="text-2xl text-center dark:text-white text-gray-800 my-1">16</p>
    <p class="text-sm font-medium">Quotes</p>
  </div>
  <div
    class="flex flex-col justify-center transition ease-in-out delay-100 hover:scale-110 duration-300 dark:bg-theme-main-dark dark:border-2 dark:border-dashboard-border items-center px-4 py-2 bg-purple-50 rounded-3xl hover-animation"
  >
    <div
      class="bg-purple-100 dark:bg-purple-100 dark:text-purple-400 text-purple-300 w-fit rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    </div>
    <p class="text-2xl text-center dark:text-white text-gray-800 my-1">4</p>
    <p class="text-sm font-medium">Jobs</p>
  </div>
  <div
    class="flex flex-col justify-center transition ease-in-out delay-100 hover:scale-110 duration-300 dark:bg-theme-main-dark dark:border-2 dark:border-dashboard-border items-center px-4 py-2 bg-sky-50 rounded-3xl hover-animation"
  >
    <div
      class="bg-sky-100 dark:bg-sky-100 dark:text-sky-400 text-sky-300 w-fit rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
        />
      </svg>
    </div>
    <p class="text-2xl text-center dark:text-white text-gray-800 my-1">4</p>
    <p class="text-sm font-medium">Invoices</p>
  </div>
  <div
    class="flex flex-col justify-center dark:bg-theme-main-dark transition ease-in-out delay-100 hover:scale-110 duration-300 dark:border-2 dark:border-dashboard-border items-center px-4 py-2 bg-pink-50 rounded-3xl hover-animation"
  >
    <div
      class="bg-pink-100 dark:bg-pink-100 dark:text-pink-400 text-pink-300 w-fit rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-8 h-8"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        />
      </svg>
    </div>
    <p class="text-2xl dark:text-white text-center text-gray-800 my-1">2</p>
    <p class="text-sm text-center font-medium">Orders</p>
  </div>
</div>
