import {
  Component,
  HostBinding,
  Renderer2,
  ElementRef,
  HostListener,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
})
export class LocalizationComponent {
  @HostBinding('class') directionClass = 'ltr'; // Default direction is LTR
  isDropdownOpen = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Check local storage for the last active state
    const savedDirection = localStorage.getItem('direction');
    if (savedDirection) {
      this.setDirection(savedDirection);
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  localhide() {
    this.isDropdownOpen = false;
  }

  toggleDirection() {
    // Toggle the direction directly based on the current state
    const newDirection = this.directionClass === 'ltr' ? 'rtl' : 'ltr';

    // Set the direction and close the dropdown
    this.setDirection(newDirection);
    this.localhide();
  }

  setDirection(newDirection: string) {
    // Set the direction in the component
    this.directionClass = newDirection;

    // Set the direction in the HTML
    this.renderer.setAttribute(
      this.document.documentElement,
      'dir',
      newDirection
    );

    // Save the active state in local storage
    localStorage.setItem('direction', newDirection);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
}
