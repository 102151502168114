<div class="bg-white p-5 rounded-xl">
  <div class="text-xl font-bold">
    <p>Task-2</p>
  </div>
  <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 pt-5">
    <div class="pb-5">
      <div class="relative">
        <div class="relative z-0 w-full group">
          <input
            type="number"
            name="time"
            id="estimated_time"
            class="input_box peer"
            placeholder=" "
            required
          />
          <label for="time" class="input_label">Estimated Time *</label>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="bg-gray-200 dark:bg-theme-main-dark p-2">
      <div class="grid grid-cols-2">
        <div class="flex items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="ml-2 text-sm font-bold">
            <p>Staff</p>
          </div>
        </div>
        <div class="text-sm font-bold">
          <p>Allocated Time</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 p-2 pt-4">
      <div class="flex items-center justify-start">
        <div class="inline-flex items-center">
          <label
            class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
            for="checkbox"
            data-ripple-dark="true"
          >
            <input
              type="checkbox"
              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
              id="checkbox"
            />
            <div
              class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </label>
        </div>
        <div class="ml-2 text-sm font-bold">
          <p>Suresh</p>
        </div>
      </div>
      <div class="text-sm">
        <input
          type="number"
          class="rounded-xl border dark:border-input-box-border dark:bg-theme-main-dark border-black px-5 py-2"
          placeholder="time"
        />
      </div>
    </div>
    <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 pt-5">
      <span class="p-float-label">
        <p-autoComplete
          formControlName="accounts"
          [suggestions]="filteredaccount"
          (completeMethod)="filteraccount($event)"
          [dropdown]="true"
          name="account"
          class="twPrimeAutoComplete"
          styleClass="twPrimeAutoComplete "
          autocomplete
          [inputStyle]=""
          inputStyleClass="twPrimeAutoCompleteInput"
          aria-label=""
        ></p-autoComplete>
        <label for="autocomplete">Select Staff </label>
      </span>
    </div>
  </div>
  <div class="text-xl pt-10 font-bold">
    <p>Task-1</p>
  </div>
  <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 pt-5">
    <div class="pb-5">
      <div class="relative">
        <div class="relative z-0 w-full group">
          <input
            type="number"
            name="time"
            id="estimated_time"
            class="input_box peer"
            placeholder=" "
            required
          />
          <label for="time" class="input_label">Estimated Time *</label>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="bg-gray-200 dark:bg-theme-main-dark p-2">
      <div class="grid grid-cols-2">
        <div class="flex items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="ml-2 text-sm font-bold">
            <p>Staff</p>
          </div>
        </div>
        <div class="text-sm font-bold">
          <p>Allocated Time</p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 p-2 pt-4">
      <div class="flex items-center justify-start">
        <div class="inline-flex items-center">
          <label
            class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
            for="checkbox"
            data-ripple-dark="true"
          >
            <input
              type="checkbox"
              class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
              id="checkbox"
            />
            <div
              class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </label>
        </div>
        <div class="ml-2 text-sm font-bold">
          <p>Suresh</p>
        </div>
      </div>
      <div class="text-sm">
        <input
          type="number"
          class="rounded-xl border dark:border-input-box-border dark:bg-theme-main-dark border-black px-5 py-2"
          placeholder="time"
        />
      </div>
    </div>
    <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 pt-5">
      <span class="p-float-label">
        <p-autoComplete
          formControlName="jobs"
          [suggestions]="filteredjob"
          (completeMethod)="filterjob($event)"
          [dropdown]="true"
          name="job"
          class=""
          styleClass="twPrimeAutoComplete "
          autocomplete
          [inputStyle]=""
          inputStyleClass="twPrimeAutoCompleteInput"
          aria-label=""
        ></p-autoComplete>
        <label for="autocomplete">Job Manager </label>
      </span>
    </div>

    <div class="py-5">
      <div
        class="flex items-center justify-start ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
      >
        <a class="px-5 text-white py-2 save rounded-xl" href="#">Save</a>
        <a class="px-5 text-white py-2 cancel rounded-xl" href="#">Cancel</a>
      </div>
    </div>
  </div>
</div>
