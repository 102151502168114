import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-quotes-info-support',
  templateUrl: './quotes-info-support.component.html',
})
export class QuotesInfoSupportComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      supplier: '0 Products',
      code: '',
      description: '',
      unitcost: '',
      qty: '',
      totalcost: '',
      deskmargin: '',
      deskprice: '',
      coverage: '',
      upliftprice: '',
      salesmargin: '',
      unitsell: '',
      totalsell: '0.00',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'supplier',
      headerName: 'Supplier',
    },
    { field: 'code', headerName: 'Code' },
    { field: 'description', headerName: 'Description' },
    { field: 'unitcost', headerName: 'Unit Cost' },
    { field: 'qty', headerName: 'Qty' },
    { field: 'totalcost', headerName: 'Total Cost' },
    { field: 'deskmargin', headerName: 'Help Desk Margin' },
    { field: 'deskprice', headerName: 'Help Desk Price' },
    { field: 'coverage', headerName: 'Coverage / Uplift' },
    { field: 'upliftprice', headerName: 'Uplift Price' },
    { field: 'salesmargin', headerName: 'Sales Margin' },
    { field: 'unitsell', headerName: 'Unit Sell' },
    { field: 'totalsell', headerName: 'Total Sell' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    minWidth: 300,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
