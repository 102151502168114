<div
  class="flex items-center ltr:space-x-4 pb-5 rtl:space-x-4 rtl:space-x-reverse"
>
  <a
    routerLink="/app/admin/task/setting"
    class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </a>
  <h1 class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200">
    Add New Task
  </h1>
</div>
<div
  class="addClientForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 shadow dark:shadow-slate-700 rounded-xl"
>
  <div class="w-full ml-auto">
    <form>
      <div
        class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-x-8 md:gap-x-8 gap-x-0"
      >
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            General Information
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="task"
                id="task_name"
                class="input_box peer"
                placeholder=" "
                readonly
              />
              <label for="task" class="input_label">Task Name *</label>
            </div>
          </div>
        </div>
        <div class="lg:block md:block hidden"></div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                type="text"
                name="postal_address"
                id="town_city_input"
                class="peer input_box"
                placeholder=" "
                required
              ></textarea>
              <label for="postal_address" class="input_label"
                >Description</label
              >
            </div>
          </div>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Billing Information
          </h4>
        </div>
        <!-- Coulmn 1 Starts -->

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="base_rate"
                id="baserate"
                class="peer input_box"
                placeholder=" "
                required
              />
              <label for="base_rate" class="input_label">Base Rate</label>
            </div>
            <div class="pt-2 text-sm text-gray-500">
              <p>Base Rate: The hourly rate it costs you to do this task</p>
            </div>
          </div>
        </div>

        <!-- Column 1 Ends -->
        <!-- Column 2 Starts -->

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="billable_rate"
                  id="billablerate"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="billable_rate" class="input_label"
                  >Billable Rate</label
                >
              </div>
              <div class="pt-2 text-sm text-gray-500">
                <p>
                  Billable Rate: The hourly rate you charge the client for this
                  task
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Interface Information
          </h4>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Export_Code"
                id="export_code_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Export_Code" class="input_label">Export Code </label>
            </div>
            <div class="pt-2 text-sm text-gray-500">
              <p>
                Export Code - Used by various exports to map the task to an
                activity or product in your accounting system
              </p>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="income"
                id="income_amount"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="income" class="input_label">Income Amount </label>
            </div>
            <div class="pt-2 text-sm text-gray-500">
              <p>
                Income Account - GL code from your accounting system that the
                task on the invoice can be allocated to
              </p>
            </div>
          </div>
        </div>
        <!-- Column 2 Ends -->

        <div class="py-5">
          <div
            class="flex items-center justify-start space-x-2 rtl:space-x-reverse"
          >
            <a class="px-5 text-white save py-2 rounded-xl" href="#">Save</a>
            <a class="px-5 text-white cancel py-2 rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
