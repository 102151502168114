import { Component, OnInit } from '@angular/core';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-settings-org',
  templateUrl: './admin-settings-org.component.html',
})
export class AdminSettingsOrgComponent {
  filteredtimes: any[] = [];
  time: any[] | undefined;
  filteredrounds: any[] = [];
  round: any[] | undefined;
  filteredlifts: any[] = [];
  lift: any[] | undefined;
  filteredfinances: any[] = [];
  finance: any[] | undefined;
  filteredstates: any[] = [];
  states: any[] | undefined;
  filteredtemplates: any[] = [];
  template: any[] | undefined;
  filteredinvoices: any[] = [];
  invoice: any[] | undefined;
  filteredjobs: any[] = [];
  job: any[] | undefined;
  filteredtasks: any[] = [];
  task: any[] | undefined;
  filteredtasksrate: any[] = [];
  taskrate: any[] | undefined;
  filteredtimeallos: any[] = [];
  timeallo: any[] | undefined;
  filteredstaffs: any[] = [];
  staff: any[] | undefined;
  filteredinvoicetypes: any[] = [];
  invoicetype: any[] | undefined;
  filteredpayments: any[] = [];
  payment: any[] | undefined;
  filteredtimesheets: any[] = [];
  timesheet: any[] | undefined;
  filteredbulks: any[] = [];
  bulk: any[] | undefined;
  filteredpaymenterms: any[] = [];
  paymenterm: any[] | undefined;
  filteredrevisions: any[] = [];
  revision: any[] | undefined;
  filteredtimeformats: any[] = [];
  timeformat: any[] | undefined;
  ngOnInit() {
    this.time = [
      '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
      '(UTC+05:30) Sri Jayawardenepura',
      '(UTC+05:45) Kathmandu',
      '(UTC+06:00) Astana',
      '(UTC+05:30) Dhaka',
      '(UTC+06:30) Omsk',
    ];
    this.round = [' Round Unit Price', '2 Decimal Places', '4 Decimal Places'];
    this.lift = ['24 * 7 Coverage (10.0%)', '9 AM - 6PM Coverage (10.0%)'];
    this.finance = [
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.template = [
      'Template - 1',
      'Quote-0905',
      'Template - 1',
      'Quote-1801',
    ];
    this.invoice = [
      'Invoiced State',
      'Planned',
      'Completed',
      'Template - 1',
      'Quote-1801',
    ];
    this.taskrate = [
      'Task Invoice Rate',
      ' Staf Billable Rate',
      'Task Billable Rate',
      'Template - 1',
      'Quote-1801',
    ];
    this.task = ['Job Task Order', 'Custom', 'Due Date'];
    this.job = [' My Jobs List', 'Current Week Only', 'All'];
    this.states = ['   Default State', 'Planned', 'Completed'];
    this.timeallo = ['Time Allocation', 'Staff', 'Task'];
    this.staff = ['  Staff Task Allocation', 'Inherit From Jobs', 'Explict'];
    this.invoicetype = ['Default Invoice Type', 'Quoted Time'];
    this.payment = ['Next Month', 'Within Days'];
    this.bulk = ['Client', 'Job Number'];
    this.paymenterm = ['Client', 'Job Number'];
    this.timesheet = [
      'None',
      'Invoice Description',
      'Task Description',
      'Task Description - Time Details',
    ];
    this.revision = ['Revision Separator', 'Estimated', 'Actual'];
    this.timeformat = [' Hour Minute - e.g. 1:30', 'Decimal - e.g 1.5'];
  }
  filtertimes(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.time as any[]).length; i++) {
      let time = (this.time as any[])[i];
      if (time.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(time);
      }
    }

    this.filteredtimes = filtered;
  }
  filterrounds(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.round as any[]).length; i++) {
      let round = (this.round as any[])[i];
      if (round.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(round);
      }
    }

    this.filteredrounds = filtered;
  }
  filterlifts(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.lift as any[]).length; i++) {
      let lift = (this.lift as any[])[i];
      if (lift.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(lift);
      }
    }

    this.filteredlifts = filtered;
  }
  filterfinances(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.finance as any[]).length; i++) {
      let finance = (this.finance as any[])[i];
      if (finance.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(finance);
      }
    }

    this.filteredfinances = filtered;
  }
  filterstates(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.states as any[]).length; i++) {
      let state = (this.states as any[])[i];
      if (state.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(state);
      }
    }

    this.filteredstates = filtered;
  }
  filtertemplates(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.template as any[]).length; i++) {
      let template = (this.template as any[])[i];
      if (template.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(template);
      }
    }

    this.filteredtemplates = filtered;
  }
  filterinvoices(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.invoice as any[]).length; i++) {
      let invoice = (this.invoice as any[])[i];
      if (invoice.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(invoice);
      }
    }

    this.filteredinvoices = filtered;
  }
  filterjobs(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.job as any[]).length; i++) {
      let job = (this.job as any[])[i];
      if (job.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(job);
      }
    }

    this.filteredjobs = filtered;
  }
  filtertasks(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.task as any[]).length; i++) {
      let task = (this.task as any[])[i];
      if (task.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(task);
      }
    }

    this.filteredtasks = filtered;
  }
  filtertasksrate(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.taskrate as any[]).length; i++) {
      let taskrate = (this.taskrate as any[])[i];
      if (taskrate.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(taskrate);
      }
    }

    this.filteredtasksrate = filtered;
  }
  filtertimeallos(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.timeallo as any[]).length; i++) {
      let timeallo = (this.timeallo as any[])[i];
      if (timeallo.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(timeallo);
      }
    }

    this.filteredtimeallos = filtered;
  }
  filterstaffs(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.staff as any[]).length; i++) {
      let staff = (this.staff as any[])[i];
      if (staff.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(staff);
      }
    }

    this.filteredstaffs = filtered;
  }
  filterinvoicetypes(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.invoicetype as any[]).length; i++) {
      let invoicetype = (this.invoicetype as any[])[i];
      if (invoicetype.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(invoicetype);
      }
    }

    this.filteredinvoicetypes = filtered;
  }
  filterpayments(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.payment as any[]).length; i++) {
      let payment = (this.payment as any[])[i];
      if (payment.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(payment);
      }
    }

    this.filteredpayments = filtered;
  }
  filtertimesheets(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.timesheet as any[]).length; i++) {
      let timesheet = (this.timesheet as any[])[i];
      if (timesheet.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(timesheet);
      }
    }

    this.filteredtimesheets = filtered;
  }
  filterbulks(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.bulk as any[]).length; i++) {
      let bulk = (this.bulk as any[])[i];
      if (bulk.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(bulk);
      }
    }

    this.filteredbulks = filtered;
  }
  filterpaymenterms(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.paymenterm as any[]).length; i++) {
      let paymenterm = (this.paymenterm as any[])[i];
      if (paymenterm.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(paymenterm);
      }
    }

    this.filteredpaymenterms = filtered;
  }
  filterrevisions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.revision as any[]).length; i++) {
      let revision = (this.revision as any[])[i];
      if (revision.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(revision);
      }
    }

    this.filteredrevisions = filtered;
  }
  filtertimeformats(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.timeformat as any[]).length; i++) {
      let timeformat = (this.timeformat as any[])[i];
      if (timeformat.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(timeformat);
      }
    }

    this.filteredtimeformats = filtered;
  }
}
