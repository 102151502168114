<div
  class="flex themeBgColor themeTextColor"
  [ngClass]="{ sideBarOff: !sidebarVisible }"
>
  <div class="scroll-auto sidebar" id="sideBar">
    <aside
      class="fixed bg-white pr-2 dark:bg-theme-main-dark drop-shadow-lg ltr:left-0 rtl:right-0 bottom-0 z-40"
      style="height: 100vh"
    >
      <div style="max-height: 100%" id="sidebar-scroll">
        <!-- Apply styles here -->
        <app-sidebar></app-sidebar>
      </div>
    </aside>
  </div>

  <div
    class="min-h-screen main overflow-y-auto"
    [ngClass]="{ fullWidth: !sidebarVisible, regularWidth: sidebarVisible }"
    id="mainContent"
  >
    <header
      class="pt-4 lg:rtl:pl-10 md:rtl:pl-10 rtl:pl-5 lg:ltr:pr-10 md:ltr:pr-10 ltr:pr-5 lg:px-20 md:px-10 px-5"
    >
      <app-topnavbar></app-topnavbar>
    </header>
    <main
      class="lg:rtl:pl-10 md:rtl:pl-10 rtl:pl-5 lg:ltr:pr-10 md:ltr:pr-10 ltr:pr-5 lg:px-20 md:px-10 px-5 filter overflow-x-auto relative py-4"
    >
      <router-outlet></router-outlet>

      <div class="overflow-y-auto">
        <aside class="fixed bottom-5 lg:right-3 md:right-3 right-0 z-50">
          <div class="">
            <app-setting-sidebar *ngIf="sidebarVisible2"></app-setting-sidebar>
            <p-button
              class="lg:absolute md:absolute fixed right-0 bottom-10 w-20 h-10"
              type="button bg-theme-secondary  "
              (click)="sidebarVisible2 = !sidebarVisible2"
            >
              <img
                class="w-6 rounded-full h-6 mx-auto"
                src="./assets/images/setting2.png"
              />
            </p-button>
          </div>
        </aside>
      </div>
    </main>
  </div>
</div>
