<div class="">
  <h4 class="text-lg text-theme-primary font-bold mt-4">General Information</h4>
  <dl class="info-list">
    <dt>Job No :</dt>
    <dd>J00051</dd>

    <dt>Client :</dt>
    <dd>Apollo Tyres</dd>

    <dt>Quote Owner:</dt>
    <dd>Suresh</dd>

    <dt>Name</dt>
    <dd>Quote-180701</dd>

    <dt>Description</dt>
    <dd>Quote-180701</dd>

    <dt>Client Order No:</dt>
    <dd>1</dd>

    <dt>Contact:</dt>
    <dd>Reginald</dd>

    <dt>State:</dt>
    <dd>Planned</dd>

    <dt>Category:</dt>
    <dd>None</dd>
  </dl>

  <h4 class="text-lg font-bold text-theme-primary mt-4">
    Schedule Information
  </h4>

  <dl class="info-list">
    <dt>Start Date:</dt>
    <dd>18-Jul-2023</dd>

    <dt>Due Date:</dt>
    <dd>18-Jul-2023</dd>

    <dt>Priority:</dt>
    <dd>Normal</dd>

    <dt class="">Account Manager:</dt>
    <dd>Vignesh</dd>

    <dt>Job Manager:</dt>
    <dd>Sandy</dd>

    <dt>Staff:</dt>
    <dd>Vigneshbabu</dd>
  </dl>
</div>
<div class="pt-10 pb-5">
  <button
    (click)="archiveConfirmation1()"
    class="w-fit btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-gray-200/10 border-gray-600 border rounded text-left flex justify-start"
  >
    <span class="ml-2 text-left">+ New Milestones</span>
  </button>
</div>

<div
  class="w-full pt-6 bg-white dark:bg-theme-wui-dark-100 shadow-lg rounded-2xl p-4 dark:p-0 border-t dark:border-0"
>
  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [paginationPageSize]="10"
    [suppressCellFocus]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  />
</div>
<div class="pt-5 flex items-center justify-start">
  <div class="lg:text-xl md:text-xl text-xs font-bold">
    <p>Tasks</p>
  </div>
  <div class="mx-10">
    <div class="lg:text-sm md:text-sm text-xs">
      <a
        href="/app/job/staff/allocation"
        class="px-5 py-2 hover:bg-gray-200 dark:hover:bg-gray-200/10 text-black dark:text-white rounded border border-black dark:border-white"
        >Staff Allocation</a
      >
    </div>
  </div>
</div>
<div class=""></div>
