import { NgModule } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessagesModule } from 'primeng/messages';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SidebarModule } from 'primeng/sidebar';
import { HttpClientModule } from '@angular/common/http';
import { DirectionService } from './direction.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BaselayoutComponent } from './baselayout/baselayout.component';
import { QuotesComponent } from './quotes/quotes.component';
import { LogoComponent } from './logo/logo.component';
import { TopnavbarComponent } from './topnavbar/topnavbar.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SmalllogoComponent } from './smalllogo/smalllogo.component';
import { ClientslistComponent } from './clientslist/clientslist.component';
import { SupllierslistComponent } from './supllierslist/supllierslist.component';
import { ContactslistComponent } from './contactslist/contactslist.component';
import { ClientaddComponent } from './clientadd/clientadd.component';
import { SupplieraddComponent } from './supplieradd/supplieradd.component';
import { ContactaddComponent } from './contactadd/contactadd.component';
import { ClientdetailComponent } from './clientdetail/clientdetail.component';
import { ClientdetailInfoComponent } from './clientdetail-info/clientdetail-info.component';
import { ClientdetailContactsComponent } from './clientdetail-contacts/clientdetail-contacts.component';
import { ClientdetailQuotesComponent } from './clientdetail-quotes/clientdetail-quotes.component';
import { ClientdetailJobsComponent } from './clientdetail-jobs/clientdetail-jobs.component';
import { ClientdetailInvoicesComponent } from './clientdetail-invoices/clientdetail-invoices.component';
import { ClientdetailNotesComponent } from './clientdetail-notes/clientdetail-notes.component';
import { ClientdetailDocumentsComponent } from './clientdetail-documents/clientdetail-documents.component';
import { ClientdetailLogComponent } from './clientdetail-log/clientdetail-log.component';
import { ClientdetailSummaryComponent } from './clientdetail-summary/clientdetail-summary.component';
import { JobsMainComponent } from './jobs-main/jobs-main.component';
import { TimesheetMainComponent } from './timesheet-main/timesheet-main.component';
import { SupportMainComponent } from './support-main/support-main.component';
import { BusinessMainComponent } from './business-main/business-main.component';
import { QuotesMainComponent } from './quotes-main/quotes-main.component';
import { InvoicesMainComponent } from './invoices-main/invoices-main.component';
import { OrdersMainComponent } from './orders-main/orders-main.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { ReportsMainComponent } from './reports-main/reports-main.component';
import { DatagridClientslistComponent } from './datagrid/datagrid-clientslist/datagrid-clientslist.component';
import { DatagridClientsContactsComponent } from './datagrid/datagrid-clients-contacts/datagrid-clients-contacts.component';
import { BlurbAdminConsoleComponent } from './_shared/blurb-admin-console/blurb-admin-console.component';
import { AdminSettingsOrgComponent } from './admin-settings-org/admin-settings-org.component';
import { OrganisationSettingsComponent } from './organisation-settings/organisation-settings.component';
import { AdminSettingsEmailTemplatesComponent } from './admin-settings-email-templates/admin-settings-email-templates.component';
import { SupplierDetailComponent } from './supplier-detail/supplier-detail.component';
import { SupllierdetailsInfoComponent } from './supllierdetails-info/supllierdetails-info.component';
import { SupllierdetailNotesComponent } from './supllierdetail-notes/supllierdetail-notes.component';
import { SupllierdetailDocumentsComponent } from './supllierdetail-documents/supllierdetail-documents.component';
import { SupllierdetailPurchaseorderComponent } from './supllierdetail-purchaseorder/supllierdetail-purchaseorder.component';
import { SupllierdetailCostComponent } from './supllierdetail-cost/supllierdetail-cost.component';
import { SupllierdetailLogComponent } from './supllierdetail-log/supllierdetail-log.component';
import { UiElementsComponent } from './ui-elements/ui-elements.component';
import { ToggleDarkModeSwitchComponent } from './_shared/toggle-dark-mode-switch/toggle-dark-mode-switch.component';
import { ToggleDirectionChangeComponent } from './_shared/toggle-direction-change/toggle-direction-change.component';
import { DatagridSupllierListComponent } from './datagrid/datagrid-supllier-list/datagrid-supllier-list.component';
import { MessageComponent } from './message/message.component';
import { NotificationComponent } from './notification/notification.component';
import { UserprofileSettingComponent } from './userprofile-setting/userprofile-setting.component';
import { UserprofileAccountComponent } from './userprofile-account/userprofile-account.component';
import { SeeAllNotificationComponent } from './see-all-notification/see-all-notification.component';
import { SeeAllMessagesComponent } from './see-all-messages/see-all-messages.component';
import { DatagridQuotesMainComponent } from './datagrid/datagrid-quotes-main/datagrid-quotes-main.component';
import { SupplierContactComponent } from './supplier-contact/supplier-contact.component';
import { SupplierAddContactComponent } from './supplier-add-contact/supplier-add-contact.component';
import { ClientSettingComponent } from './client-setting/client-setting.component';
import { JobSettingsComponent } from './job-settings/job-settings.component';
import { StaffUserSettingComponent } from './staff-user-setting/staff-user-setting.component';
import { RoleSettingComponent } from './role-setting/role-setting.component';
import { TeamSettingComponent } from './team-setting/team-setting.component';
import { TaskSettingComponent } from './task-setting/task-setting.component';
import { CostSettingComponent } from './cost-setting/cost-setting.component';
import { SupportSettingComponent } from './support-setting/support-setting.component';
import { CsvTemplateSettingComponent } from './csv-template-setting/csv-template-setting.component';
import { EmailTempComponent } from './email-temp/email-temp.component';
import { CustomTemplateComponent } from './custom-template/custom-template.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { ZeroSettingComponent } from './zero-setting/zero-setting.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { AddJobCategoryComponent } from './add-job-category/add-job-category.component';
import { InviteStaffComponent } from './invite-staff/invite-staff.component';
import { NewTasksComponent } from './new-tasks/new-tasks.component';
import { AddCostComponent } from './add-cost/add-cost.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobInfoComponent } from './job-info/job-info.component';
import { JobTimesheetComponent } from './job-timesheet/job-timesheet.component';
import { JobNotesComponent } from './job-notes/job-notes.component';
import { JobDocumentsComponent } from './job-documents/job-documents.component';
import { JobCostsComponent } from './job-costs/job-costs.component';
import { JobSupportComponent } from './job-support/job-support.component';
import { JobFinancialComponent } from './job-financial/job-financial.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ContactNotesComponent } from './contact-notes/contact-notes.component';
import { ContactDocumentsComponent } from './contact-documents/contact-documents.component';
import { ContactJobsComponent } from './contact-jobs/contact-jobs.component';
import { ContactQuotesComponent } from './contact-quotes/contact-quotes.component';
import { ContactInvoicesComponent } from './contact-invoices/contact-invoices.component';
import { CustomRatesComponent } from './custom-rates/custom-rates.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { AddNewContactComponent } from './add-new-contact/add-new-contact.component';
import { JobStaffAllocationComponent } from './job-staff-allocation/job-staff-allocation.component';
import { JobDescriptionComponent } from './job-description/job-description.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';
import { QuotesInfoComponent } from './quotes-info/quotes-info.component';
import { QuotesNoteComponent } from './quotes-note/quotes-note.component';
import { QuotesDocumentsComponent } from './quotes-documents/quotes-documents.component';
import { QuotesHistoryComponent } from './quotes-history/quotes-history.component';
import { QuotesInfoTasksComponent } from './quotes-info-tasks/quotes-info-tasks.component';
import { QuotesInfoProductsComponent } from './quotes-info-products/quotes-info-products.component';
import { QuotesInfoSupportComponent } from './quotes-info-support/quotes-info-support.component';
import { QuotesInfoDescriptionComponent } from './quotes-info-description/quotes-info-description.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ValidateUsersComponent } from './validate-users/validate-users.component';
import { LocalizationComponent } from './localization/localization.component';
import { SettingSidebarComponent } from './setting-sidebar/setting-sidebar.component';
import { OrangeBlueComponent } from './orange-blue/orange-blue.component';
import { MaintainanceModeComponent } from './maintainance-mode/maintainance-mode.component';
import { ToolTipComponent } from './tool-tip/tool-tip.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FilterClientsComponent } from './filter-clients/filter-clients.component';
import { SupplierFilterComponent } from './supplier-filter/supplier-filter.component';
import { SupplierContactListComponent } from './supplier-contact-list/supplier-contact-list.component';
import { ClientsContactListComponent } from './clients-contact-list/clients-contact-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    SidebarComponent,
    BaselayoutComponent,
    QuotesComponent,
    LogoComponent,
    TopnavbarComponent,
    AvatarComponent,
    SmalllogoComponent,
    ClientslistComponent,
    SupllierslistComponent,
    ContactslistComponent,
    ClientaddComponent,
    SupplieraddComponent,
    ContactaddComponent,
    ClientdetailComponent,
    ClientdetailInfoComponent,
    ClientdetailContactsComponent,
    ClientdetailQuotesComponent,
    ClientdetailJobsComponent,
    ClientdetailInvoicesComponent,
    ClientdetailNotesComponent,
    ClientdetailDocumentsComponent,
    ClientdetailLogComponent,
    ClientdetailSummaryComponent,
    JobsMainComponent,
    TimesheetMainComponent,
    SupportMainComponent,
    BusinessMainComponent,
    QuotesMainComponent,
    InvoicesMainComponent,
    OrdersMainComponent,
    AdminMainComponent,
    ReportsMainComponent,
    DatagridClientslistComponent,
    DatagridClientsContactsComponent,
    BlurbAdminConsoleComponent,
    AdminSettingsOrgComponent,
    OrganisationSettingsComponent,
    AdminSettingsEmailTemplatesComponent,
    SupplierDetailComponent,
    SupllierdetailsInfoComponent,
    SupllierdetailNotesComponent,
    SupllierdetailDocumentsComponent,
    SupllierdetailPurchaseorderComponent,
    SupllierdetailCostComponent,
    SupllierdetailLogComponent,
    UiElementsComponent,
    ToggleDarkModeSwitchComponent,
    ToggleDirectionChangeComponent,
    DatagridSupllierListComponent,
    MessageComponent,
    NotificationComponent,
    UserprofileSettingComponent,
    UserprofileAccountComponent,
    SeeAllNotificationComponent,
    SeeAllMessagesComponent,
    DatagridQuotesMainComponent,
    SupplierContactComponent,
    SupplierAddContactComponent,
    ClientSettingComponent,
    JobSettingsComponent,
    StaffUserSettingComponent,
    RoleSettingComponent,
    TeamSettingComponent,
    TaskSettingComponent,
    CostSettingComponent,
    SupportSettingComponent,
    CsvTemplateSettingComponent,
    EmailTempComponent,
    CustomTemplateComponent,
    CustomFieldsComponent,
    ZeroSettingComponent,
    DocumentManagementComponent,
    AddJobCategoryComponent,
    InviteStaffComponent,
    NewTasksComponent,
    AddCostComponent,
    AddContactComponent,
    JobDetailsComponent,
    JobInfoComponent,
    JobTimesheetComponent,
    JobNotesComponent,
    JobDocumentsComponent,
    JobCostsComponent,
    JobSupportComponent,
    JobFinancialComponent,
    ContactInfoComponent,
    ContactNotesComponent,
    ContactDocumentsComponent,
    ContactJobsComponent,
    ContactQuotesComponent,
    ContactInvoicesComponent,
    CustomRatesComponent,
    ContactDetailsComponent,
    AddNewContactComponent,
    JobStaffAllocationComponent,
    JobDescriptionComponent,
    RegistrationPageComponent,
    ProfileComponent,
    PasswordComponent,
    QuotesInfoComponent,
    QuotesNoteComponent,
    QuotesDocumentsComponent,
    QuotesHistoryComponent,
    QuotesInfoTasksComponent,
    QuotesInfoProductsComponent,
    QuotesInfoSupportComponent,
    QuotesInfoDescriptionComponent,
    ForgotPasswordComponent,
    ValidateUsersComponent,
    LocalizationComponent,
    SettingSidebarComponent,
    OrangeBlueComponent,
    MaintainanceModeComponent,
    ToolTipComponent,
    ErrorPageComponent,
    FilterClientsComponent,
    SupplierFilterComponent,
    SupplierContactListComponent,
    ClientsContactListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgGridModule,
    AutoCompleteModule,
    ToastModule,
    ConfirmDialogModule,
    ProgressBarModule,
    MessagesModule,
    CalendarModule,
    SidebarModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
