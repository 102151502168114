<div class="flex lg:pt-0 md:pt-5 pt-5 space-x-6 lg:ml-0 md:ml-0 ml-8">
  <div>
    <button
      (click)="toggleViewFilter()"
      class="btn lg:w-48 md:w-48 w-38 h-12 bg-white dark:bg-theme-wui-dark-100 dark:border dark:border-dashboard-border hover:bg-sky-100 border rounded-md shadow-sm mb-2 p-1 pl-3 flex justify-between items-center"
    >
      <div class="mx-2">
        <p class="text-[10px] text-left text-gray-400">View Filter</p>
        <p class="text-xs dark:text-white text-left">
          {{ selectedViewFilter }}
        </p>
      </div>
      <div class="mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-4 dark:text-white h-4"
        >
          <path
            fill-rule="evenodd"
            d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </button>

    <div
      [hidden]="!showViewFilter"
      class="lg:w-48 md:w-48 w-40 bg-white dark:bg-theme-wui-dark-100 dark:border dark:border-dashboard-border py-1 shadow h-fit absolute z-10 transition"
    >
      <div class="flex flex-col text-xs space-y-1">
        <button
          class="block hover:bg-sky-100 dark:hover:bg-gray-200/10 dark:text-white text-left p-2"
          (click)="callAllSuppliers()"
        >
          All Suppliers
        </button>
        <button
          class="block hover:bg-sky-100 dark:hover:bg-gray-200/10 dark:text-white text-left p-2"
          (click)="callActiveSuppliers()"
        >
          Active Suppliers Only
        </button>
        <button
          class="block hover:bg-sky-100 dark:hover:bg-gray-200/10 dark:text-white text-left p-2"
          (click)="callArchivedSuppliers()"
        >
          Archived Suppliers Only
        </button>
        <button
          class="block hover:bg-sky-100 dark:hover:bg-gray-200/10 dark:text-white text-left p-2"
          (click)="callFavoriteSuppliers()"
        >
          My Favorited Suppliers
        </button>
      </div>
    </div>
  </div>
</div>
