import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { BaselayoutComponent } from './baselayout/baselayout.component';
import { QuotesComponent } from './quotes/quotes.component';
import { ClientslistComponent } from './clientslist/clientslist.component';
import { SupllierslistComponent } from './supllierslist/supllierslist.component';
import { ContactslistComponent } from './contactslist/contactslist.component';
import { ClientaddComponent } from './clientadd/clientadd.component';
import { ClientdetailComponent } from './clientdetail/clientdetail.component';
import { JobsMainComponent } from './jobs-main/jobs-main.component';
import { TimesheetMainComponent } from './timesheet-main/timesheet-main.component';
import { QuotesMainComponent } from './quotes-main/quotes-main.component';
import { InvoicesMainComponent } from './invoices-main/invoices-main.component';
import { OrdersMainComponent } from './orders-main/orders-main.component';
import { SupportMainComponent } from './support-main/support-main.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { ReportsMainComponent } from './reports-main/reports-main.component';
import { SupplieraddComponent } from './supplieradd/supplieradd.component';
import { AdminSettingsOrgComponent } from './admin-settings-org/admin-settings-org.component';
import { OrganisationSettingsComponent } from './organisation-settings/organisation-settings.component';
import { AdminSettingsEmailTemplatesComponent } from './admin-settings-email-templates/admin-settings-email-templates.component';
import { SupplierDetailComponent } from './supplier-detail/supplier-detail.component';
import { SupllierdetailsInfoComponent } from './supllierdetails-info/supllierdetails-info.component';
import { UiElementsComponent } from './ui-elements/ui-elements.component';
import { UserprofileSettingComponent } from './userprofile-setting/userprofile-setting.component';
import { UserprofileAccountComponent } from './userprofile-account/userprofile-account.component';
import { SeeAllNotificationComponent } from './see-all-notification/see-all-notification.component';
import { SeeAllMessagesComponent } from './see-all-messages/see-all-messages.component';
import { ContactaddComponent } from './contactadd/contactadd.component';
import { SupplierContactComponent } from './supplier-contact/supplier-contact.component';
import { DatagridClientsContactsComponent } from './datagrid/datagrid-clients-contacts/datagrid-clients-contacts.component';
import { SupplierAddContactComponent } from './supplier-add-contact/supplier-add-contact.component';
import { ClientSettingComponent } from './client-setting/client-setting.component';
import { JobSettingsComponent } from './job-settings/job-settings.component';
import { StaffUserSettingComponent } from './staff-user-setting/staff-user-setting.component';
import { RoleSettingComponent } from './role-setting/role-setting.component';
import { TeamSettingComponent } from './team-setting/team-setting.component';
import { TaskSettingComponent } from './task-setting/task-setting.component';
import { CostSettingComponent } from './cost-setting/cost-setting.component';
import { SupportSettingComponent } from './support-setting/support-setting.component';
import { CsvTemplateSettingComponent } from './csv-template-setting/csv-template-setting.component';
import { EmailTempComponent } from './email-temp/email-temp.component';
import { CustomTemplateComponent } from './custom-template/custom-template.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { ZeroSettingComponent } from './zero-setting/zero-setting.component';
import { DocumentManagementComponent } from './document-management/document-management.component';
import { AddJobCategoryComponent } from './add-job-category/add-job-category.component';
import { InviteStaffComponent } from './invite-staff/invite-staff.component';
import { NewTasksComponent } from './new-tasks/new-tasks.component';
import { AddCostComponent } from './add-cost/add-cost.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { AddNewContactComponent } from './add-new-contact/add-new-contact.component';
import { JobStaffAllocationComponent } from './job-staff-allocation/job-staff-allocation.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';
import { QuotesInfoComponent } from './quotes-info/quotes-info.component';
import { QuotesNoteComponent } from './quotes-note/quotes-note.component';
import { QuotesDocumentsComponent } from './quotes-documents/quotes-documents.component';
import { QuotesHistoryComponent } from './quotes-history/quotes-history.component';
import { QuotesInfoTasksComponent } from './quotes-info-tasks/quotes-info-tasks.component';
import { QuotesInfoProductsComponent } from './quotes-info-products/quotes-info-products.component';
import { QuotesInfoSupportComponent } from './quotes-info-support/quotes-info-support.component';
import { QuotesInfoDescriptionComponent } from './quotes-info-description/quotes-info-description.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ValidateUsersComponent } from './validate-users/validate-users.component';
import { LocalizationComponent } from './localization/localization.component';
import { SettingSidebarComponent } from './setting-sidebar/setting-sidebar.component';
import { OrangeBlueComponent } from './orange-blue/orange-blue.component';
import { MaintainanceModeComponent } from './maintainance-mode/maintainance-mode.component';
import { ToolTipComponent } from './tool-tip/tool-tip.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FilterClientsComponent } from './filter-clients/filter-clients.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registration/page', component: RegistrationPageComponent },
  { path: 'forgot/password', component: ForgotPasswordComponent },
  { path: 'validate/user', component: ValidateUsersComponent },
  { path: 'maintainance', component: MaintainanceModeComponent },
  { path: 'error', component: ErrorPageComponent },

  {
    path: 'app',
    component: BaselayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'interaction/clients', component: ClientslistComponent },
      { path: 'interaction/clients/add', component: ClientaddComponent },
      { path: 'interaction/clients/detail', component: ClientdetailComponent },
      { path: 'interaction/suppliers', component: SupllierslistComponent },
      { path: 'interaction/suppliers/add', component: SupplieraddComponent },
      { path: 'interaction/contacts', component: ContactslistComponent },
      {
        path: 'client/add/contact',
        component: ContactaddComponent,
      },
      { path: 'collaboration/jobs', component: JobsMainComponent },
      { path: 'collaboration/timesheets', component: TimesheetMainComponent },
      { path: 'supports', component: SupportMainComponent },
      { path: 'business/quotes', component: QuotesMainComponent },
      { path: 'business/invoices', component: InvoicesMainComponent },
      { path: 'business/orders', component: OrdersMainComponent },
      { path: 'admin', component: AdminMainComponent },
      { path: 'reports', component: ReportsMainComponent },
      { path: 'admin/settings/org', component: AdminSettingsOrgComponent },
      { path: 'admin/filter/clients', component: FilterClientsComponent },

      {
        path: 'admin/settings/email-templates',
        component: AdminSettingsEmailTemplatesComponent,
      },
      { path: 'organisation', component: OrganisationSettingsComponent },
      {
        path: 'interaction/suppliers/detail',
        component: SupplierDetailComponent,
      },
      { path: 'supplierdetail/info', component: SupllierdetailsInfoComponent },
      { path: 'ui', component: UiElementsComponent },
      { path: 'userprofile/settings', component: UserprofileSettingComponent },
      { path: 'userprofile/account', component: UserprofileAccountComponent },
      { path: 'see/all/notifications', component: SeeAllNotificationComponent },
      { path: 'see/all/messages', component: SeeAllMessagesComponent },
      { path: 'supplier/contact', component: SupplierContactComponent },
      {
        path: 'client/contact/list',
        component: DatagridClientsContactsComponent,
      },
      {
        path: 'supplier/add/contact',
        component: SupplierAddContactComponent,
      },
      {
        path: 'admin/client/setting',
        component: ClientSettingComponent,
      },
      { path: 'admin/job/setting', component: JobSettingsComponent },
      {
        path: 'admin/staff/user/setting',
        component: StaffUserSettingComponent,
      },
      { path: 'admin/role/setting', component: RoleSettingComponent },
      { path: 'admin/team/setting', component: TeamSettingComponent },
      { path: 'admin/task/setting', component: TaskSettingComponent },
      { path: 'admin/cost/setting', component: CostSettingComponent },
      { path: 'admin/support/setting', component: SupportSettingComponent },
      { path: 'admin/csv/template', component: CsvTemplateSettingComponent },
      { path: 'admin/email/template', component: EmailTempComponent },
      { path: 'custom/template', component: CustomTemplateComponent },
      { path: 'custom/fields', component: CustomFieldsComponent },
      { path: 'zero/setting', component: ZeroSettingComponent },
      { path: 'document/management', component: DocumentManagementComponent },
      { path: 'add/job/category', component: AddJobCategoryComponent },
      { path: 'invite/staff', component: InviteStaffComponent },
      { path: 'new/task', component: NewTasksComponent },
      { path: 'add/cost', component: AddCostComponent },
      { path: 'interaction/contacts/add', component: AddContactComponent },
      { path: 'collaboration/jobs/detail', component: JobDetailsComponent },
      {
        path: 'interaction/contacts/detail',
        component: ContactDetailsComponent,
      },
      { path: 'add/new/contact', component: AddNewContactComponent },
      { path: 'job/staff/allocation', component: JobStaffAllocationComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'password', component: PasswordComponent },
      { path: 'business/quotes/details', component: QuotesComponent },
      { path: 'quote/info', component: QuotesInfoComponent },
      { path: 'quote/note', component: QuotesNoteComponent },
      { path: 'quote/documents', component: QuotesDocumentsComponent },
      { path: 'quote/history', component: QuotesHistoryComponent },
      { path: 'quote/info/tasks', component: QuotesInfoTasksComponent },
      { path: 'quote/info/products', component: QuotesInfoProductsComponent },
      { path: 'quote/info/support', component: QuotesInfoSupportComponent },
      {
        path: 'quotes/info/description',
        component: QuotesInfoDescriptionComponent,
      },
      { path: 'localization', component: LocalizationComponent },
      { path: 'settings', component: SettingSidebarComponent },
      { path: 'orange/blue', component: OrangeBlueComponent },
      { path: 'tool/tip', component: ToolTipComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
