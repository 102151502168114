import { Component, OnInit } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
  IGroupCellRendererParams,
} from 'ag-grid-community';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-datagrid-clientslist',
  templateUrl: './datagrid-clientslist.component.html',
  template: ``,
})
export class DatagridClientslistComponent implements OnInit {
  gridOptions: GridOptions = { suppressRowClickSelection: true };
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      name: 'Apollo Tyres',
      // city: 'Chennai',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Muthu Kumar',
      worksInProcess: {
        quotes: 5,
        invoices: 5,
        jobs: 5,
        po: 0,
      },
      activity: 'Created a new quote',
      activityTime: '2 hours ago',
    },
    {
      name: 'ABC Corp',
      // city: 'Los Angeles',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Emily Johnson',
      worksInProcess: {
        quotes: 2,
        invoices: 3,
        jobs: 6,
        po: 0,
      },
      activity: 'Created a new job',
      activityTime: '30 minutes ago',
    },
    {
      name: 'XYZ Tech',
      // city: 'London',
      // country: 'UK',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Brown',
      jobManager: 'Sophie Williams',
      worksInProcess: {
        quotes: 7,
        invoices: 8,
        jobs: 10,
        po: 2,
      },
      activity: 'Updated a quote',
      activityTime: '1 hour ago',
    },
    {
      name: 'Tech Solutions',
      // city: 'Bangalore',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'Ananya Gupta',
      jobManager: 'Rajesh Sharma',
      worksInProcess: {
        quotes: 4,
        invoices: 6,
        jobs: 9,
        po: 1,
      },
      activity: 'Created a new invoice',
      activityTime: '45 minutes ago',
    },
    {
      name: 'Globex Inc.',
      // city: 'New York',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Johnson',
      jobManager: 'Sarah Brown',
      worksInProcess: {
        quotes: 8,
        invoices: 5,
        jobs: 12,
        po: 3,
      },
      activity: 'Updated a job',
      activityTime: '2 hours ago',
    },
    {
      name: 'TechGen Corp',
      // city: 'Sydney',
      // country: 'Australia',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Benjamin Smith',
      worksInProcess: {
        quotes: 6,
        invoices: 4,
        jobs: 7,
        po: 1,
      },
      activity: 'Created a new PO',
      activityTime: '1 hour ago',
    },
    {
      name: 'Apollo Tyres',
      // city: 'Chennai',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Muthu Kumar',
      worksInProcess: {
        quotes: 5,
        invoices: 5,
        jobs: 5,
        po: 0,
      },
      activity: 'Created a new quote',
      activityTime: '2 hours ago',
    },
    {
      name: 'ABC Corp',
      // city: 'Los Angeles',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Emily Johnson',
      worksInProcess: {
        quotes: 2,
        invoices: 3,
        jobs: 6,
        po: 0,
      },
      activity: 'Created a new job',
      activityTime: '30 minutes ago',
    },
    {
      name: 'XYZ Tech',
      // city: 'London',
      // country: 'UK',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Brown',
      jobManager: 'Sophie Williams',
      worksInProcess: {
        quotes: 7,
        invoices: 8,
        jobs: 10,
        po: 2,
      },
      activity: 'Updated a quote',
      activityTime: '1 hour ago',
    },
    {
      name: 'Tech Solutions',
      // city: 'Bangalore',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'Ananya Gupta',
      jobManager: 'Rajesh Sharma',
      worksInProcess: {
        quotes: 4,
        invoices: 6,
        jobs: 9,
        po: 1,
      },
      activity: 'Created a new invoice',
      activityTime: '45 minutes ago',
    },
    {
      name: 'Globex Inc.',
      // city: 'New York',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Johnson',
      jobManager: 'Sarah Brown',
      worksInProcess: {
        quotes: 8,
        invoices: 5,
        jobs: 12,
        po: 3,
      },
      activity: 'Updated a job',
      activityTime: '2 hours ago',
    },
    {
      name: 'TechGen Corp',
      // city: 'Sydney',
      // country: 'Australia',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Benjamin Smith',
      worksInProcess: {
        quotes: 6,
        invoices: 4,
        jobs: 7,
        po: 1,
      },
      activity: 'Created a new PO',
      activityTime: '1 hour ago',
    },
    {
      name: 'Apollo Tyres',
      // city: 'Chennai',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Muthu Kumar',
      worksInProcess: {
        quotes: 5,
        invoices: 5,
        jobs: 5,
        po: 0,
      },
      activity: 'Created a new quote',
      activityTime: '2 hours ago',
    },
    {
      name: 'ABC Corp',
      // city: 'Los Angeles',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Emily Johnson',
      worksInProcess: {
        quotes: 2,
        invoices: 3,
        jobs: 6,
        po: 0,
      },
      activity: 'Created a new job',
      activityTime: '30 minutes ago',
    },
    {
      name: 'XYZ Tech',
      // city: 'London',
      // country: 'UK',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Brown',
      jobManager: 'Sophie Williams',
      worksInProcess: {
        quotes: 7,
        invoices: 8,
        jobs: 10,
        po: 2,
      },
      activity: 'Updated a quote',
      activityTime: '1 hour ago',
    },
    {
      name: 'Tech Solutions',
      // city: 'Bangalore',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'Ananya Gupta',
      jobManager: 'Rajesh Sharma',
      worksInProcess: {
        quotes: 4,
        invoices: 6,
        jobs: 9,
        po: 1,
      },
      activity: 'Created a new invoice',
      activityTime: '45 minutes ago',
    },
    {
      name: 'Globex Inc.',
      // city: 'New York',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Johnson',
      jobManager: 'Sarah Brown',
      worksInProcess: {
        quotes: 8,
        invoices: 5,
        jobs: 12,
        po: 3,
      },
      activity: 'Updated a job',
      activityTime: '2 hours ago',
    },
    {
      name: 'TechGen Corp',
      // city: 'Sydney',
      // country: 'Australia',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Benjamin Smith',
      worksInProcess: {
        quotes: 6,
        invoices: 4,
        jobs: 7,
        po: 1,
      },
      activity: 'Created a new PO',
      activityTime: '1 hour ago',
    },
    {
      name: 'Apollo Tyres',
      // city: 'Chennai',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Muthu Kumar',
      worksInProcess: {
        quotes: 5,
        invoices: 5,
        jobs: 5,
        po: 0,
      },
      activity: 'Created a new quote',
      activityTime: '2 hours ago',
    },
    {
      name: 'ABC Corp',
      // city: 'Los Angeles',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Emily Johnson',
      worksInProcess: {
        quotes: 2,
        invoices: 3,
        jobs: 6,
        po: 0,
      },
      activity: 'Created a new job',
      activityTime: '30 minutes ago',
    },
    {
      name: 'XYZ Tech',
      // city: 'London',
      // country: 'UK',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Brown',
      jobManager: 'Sophie Williams',
      worksInProcess: {
        quotes: 7,
        invoices: 8,
        jobs: 10,
        po: 2,
      },
      activity: 'Updated a quote',
      activityTime: '1 hour ago',
    },
    {
      name: 'Tech Solutions',
      // city: 'Bangalore',
      // country: 'India',
      link: '/app/interaction/clients/detail',
      accountManager: 'Ananya Gupta',
      jobManager: 'Rajesh Sharma',
      worksInProcess: {
        quotes: 4,
        invoices: 6,
        jobs: 9,
        po: 1,
      },
      activity: 'Created a new invoice',
      activityTime: '45 minutes ago',
    },
    {
      name: 'Globex Inc.',
      // city: 'New York',
      // country: 'USA',
      link: '/app/interaction/clients/detail',
      accountManager: 'Michael Johnson',
      jobManager: 'Sarah Brown',
      worksInProcess: {
        quotes: 8,
        invoices: 5,
        jobs: 12,
        po: 3,
      },
      activity: 'Updated a job',
      activityTime: '2 hours ago',
    },
    {
      name: 'TechGen Corp',
      // city: 'Sydney',
      // country: 'Australia',
      link: '/app/interaction/clients/detail',
      accountManager: 'John Smith',
      jobManager: 'Benjamin Smith',
      worksInProcess: {
        quotes: 6,
        invoices: 4,
        jobs: 7,
        po: 1,
      },
      activity: 'Created a new PO',
      activityTime: '1 hour ago',
    },
  ];

  constructor(private activatedRoute: ActivatedRoute) {
    this.rowData.forEach((data) => {
      data.cName = `${data.name}, `;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }
  ngOnInit() {
    // const url = this.activatedRoute.url;
    // console.log('client', url); // Prints the URL of the current route
  }
  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.name;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `
    <div class="pl-1">
            <a href="${params.node.data.link}">
            <p class="text-lg font-bold  hover:underline list-font text-black  dark:text-white" style="">${params.node.data.name}</p>
            
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-4 gap-2">
        <div class="flex  px-3 py-1 list-font text-sm font-light rounded-full bg-sky-blue-background dark:bg-dark-sky-blue-background text-sky-blue-text h-fit">
          Qu : ${params.value.quotes}
        </div>
        <div class="flex bg-lite-orange-bg px-3 py-1 list-font text-sm font-light dark:bg-dark-sky-blue-background rounded-full text-save-background h-fit">
          In: ${params.value.invoices}
        </div>
        <div class="flex bg-yellow-bg px-3 py-1 list-font text-sm font-light dark:bg-dark-sky-blue-background rounded-full text-text-yellow h-fit">
          Jo : ${params.value.jobs}
        </div>
        <div class="flex bg-pink-100 px-3 py-1 list-font text-sm font-light dark:bg-dark-sky-blue-background rounded-full text-pink-text h-fit">
          PO : ${params.value.po}
        </div>`;
  };

  columnDefs: ColDef[] = [
    {
      field: 'cName',
      headerName: 'Name',
      minWidth: 200,
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: this.baseFilterParams,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      cellRenderer: this.nameCellRenderer,
    },
    { field: 'accountManager', headerName: 'Account Manager', minWidth: 200 },
    {
      field: 'jobManager',
      headerName: 'Job Manager',
      minWidth: 200,
    },
    {
      field: 'worksInProcess',
      minWidth: 300,
      maxWidth: 300,
      sortable: false,
      filter: true,
      cellRenderer: this.worksInProcessCellRenderer,
    },
    {
      field: 'recentActivity',
      headerName: 'Recent Activity',
      sortable: true,
      filter: true,
      cellRenderer: this.activityCellRenderer,
      minWidth: 200,
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
    floatingFilter: true,
    filterParams: this.baseFilterParams,
  };
  public paginationPageSize = 5;
  public paginationFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
