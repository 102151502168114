<div
  class="addClientForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 dark:shadow-slate-700 shadow rounded-xl"
>
  <div class="pt-5">
    <div class="text-xl dark:border-theme-text-blue-200 border-b-2 pb-2">
      <p>Tooltips</p>
    </div>
    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 pt-10">
      <div
        class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
      >
        <div
          class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
        >
          <p class="text-xl p-2 ml-3 font-semibold">Basic</p>
        </div>
        <div class="py-6 ml-3">
          <button
            data-tooltip-target="tooltip-bottom"
            data-tooltip-placement="bottom"
            type="button"
            class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Button
          </button>
          <div
            id="tooltip-bottom"
            role="tooltip"
            class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
          >
            info about the action
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>
      <div
        class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
      >
        <div
          class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
        >
          <p class="text-xl p-2 ml-3 font-semibold">Uppercase</p>
        </div>
        <div class="py-6 ml-3">
          <button
            data-tooltip-target="tooltip-bottom"
            data-tooltip-placement="bottom"
            type="button"
            class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Button
          </button>
          <div
            id="tooltip-bottom"
            role="tooltip"
            class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
          >
            INFO ABOUT THE ACTION
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>
      <div
        class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
      >
        <div
          class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
        >
          <p class="text-xl p-2 ml-3 font-semibold">Disabled on click</p>
        </div>
        <div class="flex items-center justify-start">
          <div class="py-6 ml-3">
            <button
              data-tooltip-target="tooltip-bottom"
              data-tooltip-placement="bottom"
              type="button"
              class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Action
            </button>
            <div
              id="tooltip-bottom"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              info about the action
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
          </div>
          <div class="ml-2">
            <div class="flex items-center justify-start">
              <div class="inline-flex items-center">
                <label
                  class="relative flex cursor-pointer items-center rounded-full p-3"
                  for="checkbox-1"
                  data-ripple-dark="true"
                >
                  <input
                    type="checkbox"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary-dark checked:bg-theme-primary-dark checked:before:bg-theme-primary-dark hover:before:opacity-10"
                    id="checkbox-1"
                    defaultChecked
                  />
                  <div
                    class="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </label>
              </div>
              <div class="text-sm ml-2">
                <p>Tooltip disabled</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-4">
        <div
          class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
        >
          <div
            class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
          >
            <p class="text-xl p-2 ml-3 font-semibold">Uppercase</p>
          </div>
          <div class="flex items-center justify-between mx-5 py-6">
            <div class="">
              <button
                data-tooltip-target="tooltip-bottom"
                data-tooltip-placement="bottom"
                type="button"
                class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Below
              </button>
              <div
                id="tooltip-bottom"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white bg-gray-700 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                info about the action
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div class="ml-2">
              <button
                data-tooltip-target="tooltip-top"
                data-tooltip-placement="top"
                type="button"
                class="mb-2 md:mb-0 text-white bg-theme-primary-dark hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Above
              </button>
              <div
                id="tooltip-top"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                info about the action
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
            <div class="ml-2">
              <!-- Show tooltip on right -->
              <button
                data-tooltip-target="tooltip-right"
                data-tooltip-placement="right"
                type="button"
                class="mb-2 md:mb-0 text-white bg-pink-text hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Tooltip right
              </button>
              <div
                id="tooltip-right"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                info about the action
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>

            <div class="ml-2">
              <!-- Show tooltip on left -->
              <button
                data-tooltip-target="tooltip-left"
                data-tooltip-placement="left"
                type="button"
                class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Tooltip left
              </button>
              <div
                id="tooltip-left"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                info about the action
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
        >
          <div
            class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
          >
            <p class="text-xl p-2 ml-3 font-semibold">Basic</p>
          </div>
          <div class="flex ml-5 justify-start">
            <div class="py-6">
              <div class="">
                <div class="relative">
                  <div class="relative z-0 w-full group">
                    <input
                      type="number"
                      name="Fax"
                      id="fax_input"
                      class="input_box peer"
                      placeholder=" "
                      required
                    />
                    <label for="Fax" class="input_label">Show Delay</label>
                  </div>
                </div>
                <div class="text-xs ml-2 pt-2">
                  <p>milliseconds</p>
                </div>
              </div>
            </div>
            <div class="py-6 ml-3">
              <div class="">
                <div class="relative">
                  <div class="relative z-0 w-full group">
                    <input
                      type="number"
                      name="Fax"
                      id="fax_input"
                      class="input_box peer"
                      placeholder=" "
                      required
                    />
                    <label for="Fax" class="input_label">Hide Delay</label>
                  </div>
                </div>
                <div class="text-xs ml-2 pt-2">
                  <p>milliseconds</p>
                </div>
              </div>
            </div>
            <div class="ml-2 pt-6">
              <button
                data-tooltip-target="tooltip-bottom"
                data-tooltip-placement="bottom"
                type="button"
                class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-xs px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Action
              </button>
              <div
                id="tooltip-bottom"
                role="tooltip"
                class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white bg-gray-700 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                info about the action
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-4">
        <div
          class="border-2 dark:border-theme-text-blue-200 border-gray-200 rounded-2xl"
        >
          <div
            class="border-b-2 dark:border-theme-text-blue-200 border-gray-200 py-2"
          >
            <p class="text-xl p-2 ml-3 font-semibold">Change Message</p>
          </div>
          <div class="py-6 mx-5">
            <div class="">
              <div class="relative">
                <div class="relative z-0 w-full group">
                  <input
                    type="text"
                    name="Fax"
                    id="fax_input"
                    class="input_box peer"
                    placeholder=" "
                    required
                  />
                  <label for="Fax" class="input_label">Tooltip message </label>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <button
                class="mb-2 md:mb-0 text-white bg-theme-primary hover:bg-gray-100/10 focus:ring-4 focus:outline-none font-medium rounded-lg text-xs px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Button
              </button>

              <!-- Extracting component classes: -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
