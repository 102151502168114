import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-quotes-history',
  templateUrl: './quotes-history.component.html',
})
export class QuotesHistoryComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 400,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      changes: 'Accepted',
      date: '	18 Jul 2023 08:03',
      staff: 'Suresh',
      detail: '',
    },
    {
      changes: 'Issued',
      date: '18 Jul 2023 08:03',
      staff: 'Hancock',
      detail: '',
    },
    {
      changes: 'Created',
      date: '	18 Jul 2023 08:02',
      staff: 'Derick',
      detail: '',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'changes',
      headerName: 'Changes',
    },
    { field: 'date', headerName: 'Date' },
    { field: 'staff', headerName: 'Staff' },
    { field: 'detail', headerName: 'Details' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    minWidth: 300,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
