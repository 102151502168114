import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-financial',
  templateUrl: './job-financial.component.html',
})
export class JobFinancialComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 400,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      name: 'Task 1',
      time: '01:00',
      rate: '1,500.00',
      total: '1,500.00',
    },
    {
      name: 'Task 2',
      time: '03:00',
      rate: '0.00',
      total: '0.00',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
    },
    { field: 'time', headerName: 'Time' },
    { field: 'rate', headerName: 'Rate' },
    { field: 'total', headerName: 'Total' },
  ];

  defaultColDef = {
    sortable: true,
    minWidth: 300,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
