import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  isDropdownOpen = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  notificationhide() {
    this.isDropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
}
