import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-task-setting',
  templateUrl: './task-setting.component.html',
})
export class TaskSettingComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      name: 'Service',
      base: '1,500.00	',
      billable: '1,000.00',
    },
    {
      name: 'Decoration',
      base: '2,500.00',
      billable: '2,000.00',
    },
    {
      name: 'Camera man',
      base: '3,600.00',
      billable: '3,000.00',
    },
    {
      name: 'Supplier',
      base: '4,700.00',
      billable: '4,000.00',
    },
    {
      name: 'Zoro',
      base: '5,100.00',
      billable: '5,000.00',
    },
    {
      name: 'Dwarf',
      base: '1,800.00',
      billable: '1,000.00',
    },
    {
      name: 'Andrew',
      base: '3,500.00',
      billable: '3,000.00',
    },
    {
      name: 'Service',
      base: '1,500.00	',
      billable: '1,000.00',
    },
    {
      name: 'Decoration',
      base: '2,500.00',
      billable: '2,000.00',
    },
    {
      name: 'Camera man',
      base: '3,600.00',
      billable: '3,000.00',
    },
    {
      name: 'Supplier',
      base: '4,700.00',
      billable: '4,000.00',
    },
    {
      name: 'Zoro',
      base: '5,100.00',
      billable: '5,000.00',
    },
    {
      name: 'Dwarf',
      base: '1,800.00',
      billable: '1,000.00',
    },
    {
      name: 'Andrew',
      base: '3,500.00',
      billable: '3,000.00',
    },
    {
      name: 'Service',
      base: '1,500.00	',
      billable: '1,000.00',
    },
    {
      name: 'Decoration',
      base: '2,500.00',
      billable: '2,000.00',
    },
    {
      name: 'Camera man',
      base: '3,600.00',
      billable: '3,000.00',
    },
    {
      name: 'Supplier',
      base: '4,700.00',
      billable: '4,000.00',
    },
    {
      name: 'Zoro',
      base: '5,100.00',
      billable: '5,000.00',
    },
    {
      name: 'Dwarf',
      base: '1,800.00',
      billable: '1,000.00',
    },
    {
      name: 'Andrew',
      base: '3,500.00',
      billable: '3,000.00',
    },
    {
      name: 'Service',
      base: '1,500.00	',
      billable: '1,000.00',
    },
    {
      name: 'Decoration',
      base: '2,500.00',
      billable: '2,000.00',
    },
    {
      name: 'Camera man',
      base: '3,600.00',
      billable: '3,000.00',
    },
    {
      name: 'Supplier',
      base: '4,700.00',
      billable: '4,000.00',
    },
    {
      name: 'Zoro',
      base: '5,100.00',
      billable: '5,000.00',
    },
    {
      name: 'Dwarf',
      base: '1,800.00',
      billable: '1,000.00',
    },
    {
      name: 'Andrew',
      base: '3,500.00',
      billable: '3,000.00',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.jobno}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.jobno;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs ">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-pink-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Invoices: ${params.value.name}
        </div>
       
        <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
        Jobs : ${params.value.base}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
      Jobs : ${params.value.billable}
    </div>
  
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Task Name',
      minWidth: 200,
      resizable: true,

      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'base',
      headerName: 'Base Rate',
    },

    {
      field: 'billable',
      headerName: 'Billable Rate',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationPageSize = 10;
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
