<div class="">
  <div class="flex items-center">
    <div class="pt-5">
      <button
        type="button"
        (click)="orangetheme()"
        title="Toggle orange mode"
        class="inline-block btn items-center ml-4 dark:bg-register-dark bg-white shadow p-4 px-6 hover:bg-gray-200 dark:bg-opacity-8 dark:hover:bg-hover-gray-100/10 rounded text-gray-900 dark:text-white text-center transition ease-in-out delay-100 hover:scale-110 duration-300 cursor-pointer"
        [class.active]="activeTheme === 'orange'"
      >
        <div class="bg-save-background relative rounded-full p-3 w-fit mx-auto">
          <!-- Add your active icon here, for example: -->
          <div
            *ngIf="activeTheme === 'orange'"
            class="active-icon absolute left-1 top-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 text-white h-4"
            >
              <path
                fill-rule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </button>
    </div>
    <div class="pt-5">
      <button
        type="button"
        (click)="bluetheme()"
        title="Toggle blue mode"
        class="inline-block btn items-center dark:bg-register-dark ml-4 bg-white shadow p-4 px-6 hover:bg-gray-200 dark:bg-opacity-8 dark:hover:bg-hover-gray-100/10 rounded text-gray-900 dark:text-white text-center transition ease-in-out delay-100 hover:scale-110 duration-300 cursor-pointer"
        [class.active]="activeTheme === 'blue'"
      >
        <div class="bg-blue-500 relative rounded-full p-3 w-fit mx-auto">
          <!-- Add your active icon here, for example: -->
          <div
            *ngIf="activeTheme === 'blue'"
            class="active-icon left-1 top-1 absolute"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 text-white h-4"
            >
              <path
                fill-rule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>
