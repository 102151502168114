import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
@Component({
  selector: 'app-supplier-contact',
  templateUrl: './supplier-contact.component.html',
})
export class SupplierContactComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      name: 'John Smith',
      jobTitle: 'Account Manager',
      email: 'john@testdomain.com',
      phone: '1234567890',
      mobile: '1234567890',
    },
    {
      name: 'Jane Doe',
      jobTitle: 'Sales Representative',
      email: 'jane@testdomain.com',
      phone: '0987654321',
      mobile: '0987654321',
    },
    {
      name: 'Michael Johnson',
      jobTitle: 'Marketing Specialist',
      email: 'michael@testdomain.com',
      phone: '5555555555',
      mobile: '5555555555',
    },
    {
      name: 'Emily Williams',
      jobTitle: 'Project Manager',
      email: 'emily@testdomain.com',
      phone: '7777777777',
      mobile: '7777777777',
    },
    {
      name: 'David Brown',
      jobTitle: 'Software Engineer',
      email: 'david@testdomain.com',
      phone: '2222222222',
      mobile: '2222222222',
    },
    {
      name: 'Olivia Wilson',
      jobTitle: 'HR Coordinator',
      email: 'olivia@testdomain.com',
      phone: '3333333333',
      mobile: '3333333333',
    },
    {
      name: 'Daniel Thompson',
      jobTitle: 'Finance Analyst',
      email: 'daniel@testdomain.com',
      phone: '4444444444',
      mobile: '4444444444',
    },
    {
      name: 'Sophia Martinez',
      jobTitle: 'Graphic Designer',
      email: 'sophia@testdomain.com',
      phone: '6666666666',
      mobile: '6666666666',
    },
    {
      name: 'James Davis',
      jobTitle: 'Operations Manager',
      email: 'james@testdomain.com',
      phone: '9999999999',
      mobile: '9999999999',
    },
    {
      name: 'Ava Anderson',
      jobTitle: 'Customer Support Specialist',
      email: 'ava@testdomain.com',
      phone: '8888888888',
      mobile: '8888888888',
    },
    {
      name: 'Robert Johnson',
      jobTitle: 'Sales Manager',
      email: 'robert@testdomain.com',
      phone: '1111111111',
      mobile: '1111111111',
    },
    {
      name: 'Mia Thompson',
      jobTitle: 'Product Manager',
      email: 'mia@testdomain.com',
      phone: '2222222222',
      mobile: '2222222222',
    },
    {
      name: 'Ethan Wilson',
      jobTitle: 'Software Developer',
      email: 'ethan@testdomain.com',
      phone: '3333333333',
      mobile: '3333333333',
    },
    {
      name: 'Isabella Davis',
      jobTitle: 'Marketing Manager',
      email: 'isabella@testdomain.com',
      phone: '4444444444',
      mobile: '4444444444',
    },
    {
      name: 'William Rodriguez',
      jobTitle: 'Business Analyst',
      email: 'william@testdomain.com',
      phone: '5555555555',
      mobile: '5555555555',
    },
    {
      name: 'Sofia Garcia',
      jobTitle: 'UX Designer',
      email: 'sofia@testdomain.com',
      phone: '6666666666',
      mobile: '6666666666',
    },
    {
      name: 'Benjamin Anderson',
      jobTitle: 'Accountant',
      email: 'benjamin@testdomain.com',
      phone: '7777777777',
      mobile: '7777777777',
    },
    {
      name: 'Charlotte Lee',
      jobTitle: 'Customer Success Manager',
      email: 'charlotte@testdomain.com',
      phone: '8888888888',
      mobile: '8888888888',
    },
    {
      name: 'Alexander Martin',
      jobTitle: 'Operations Coordinator',
      email: 'alexander@testdomain.com',
      phone: '9999999999',
      mobile: '9999999999',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: (params: ICellRendererParams) => {
        return `<a href="#" class="text-base">${params.value}</a>`;
      },
    },
    { field: 'jobTitle', headerName: 'Job Title' },
    { field: 'email', headerName: 'Email' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'mobile', headerName: 'Mobile' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
    minWidth: 300,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
