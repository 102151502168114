import { Component } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  animations: [
    trigger('tabClick', [
      state(
        'inactive',
        style({
          'border-bottom': '2px solid transparent',
        })
      ),
      state(
        'active',
        style({
          'border-bottom': '2px solid var(--ws-theme-primary)', // Change this to the color you want
        })
      ),
      transition('inactive => active', animate('0.5s ease-in')),
      transition('active => inactive', animate('0.2s ease-out')),
    ]),
  ],
})
export class SupplierDetailComponent {
  showSummaryCard: boolean = true;
  showTabNumber: number = 1;
  activeTab: number | undefined;
  getTabClickState(tabNumber: number): string {
    return this.showTabNumber === tabNumber ? 'active' : 'inactive';
  }
  interactionSubMenuRotation: boolean = false;
  collaborationSubMenuRotation: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    initFlowbite();
    this.route.queryParams.subscribe((params) => {
      const tabNumber = +params['tab'];
      if (tabNumber) {
        this.showTabNumber = tabNumber;
      }
    });
  }

  toggleTab(tabNumber: number) {
    this.showTabNumber = tabNumber;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabNumber },
      queryParamsHandling: 'merge',
    });
  }
}
