import { Component } from '@angular/core';

// Initialization for ES Users

@Component({
  selector: 'app-see-all-notification',
  templateUrl: './see-all-notification.component.html',
})
export class SeeAllNotificationComponent {
  showSummaryCard: boolean = false;
  hideSummaryCard() {
    this.showSummaryCard = false;
  }

  unhideSummaryCard() {
    this.showSummaryCard = true;
  }
  showSummaryCard1: boolean = false;
  hideSummaryCard1() {
    this.showSummaryCard1 = false;
  }

  unhideSummaryCard1() {
    this.showSummaryCard1 = true;
  }
  showSummaryCard2: boolean = false;
  hideSummaryCard2() {
    this.showSummaryCard2 = false;
  }

  unhideSummaryCard2() {
    this.showSummaryCard2 = true;
  }
}
