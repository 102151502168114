import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-filter-clients',
  templateUrl: './filter-clients.component.html',
})
export class FilterClientsComponent {
  showViewFilter = false;
  selectedViewFilter = 'All Clients';
  constructor(private el: ElementRef) {}

  // Toggle the view filter
  toggleViewFilter() {
    this.showViewFilter = !this.showViewFilter;
  }

  // Listen for document click
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    console.log('Document click event');
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the view filter button or the dropdown
    if (!this.el.nativeElement.contains(targetElement)) {
      console.log('data', event.target);
      this.showViewFilter = false;
    }
  }
  callAllClients() {
    this.selectedViewFilter = 'All Clients';
    this.toggleViewFilter();
  }

  callActiveClients() {
    this.selectedViewFilter = 'Active Clients Only';
    this.toggleViewFilter();
  }

  callFavoriteClients() {
    this.selectedViewFilter = 'My Favorite Clients';
    this.toggleViewFilter();
  }

  callArchivedClients() {
    this.selectedViewFilter = 'Archived Clients Only';
    this.toggleViewFilter();
  }
}
