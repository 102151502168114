<div
  class="lg:ml-4 md:ml-0 ml-0 hover:bg-gray-200 pl-2 pt-2 px-2 dark:bg-opacity-8 dark:hover:bg-gray-200/10 rounded-full"
>
  <button id="profile_Button" (click)="toggleDropdown()" type="button">
    <img
      class="w-6 rounded-full h-6 mr-auto"
      src="./assets/images/toogle_profile.png"
    />
  </button>
</div>
<div class="mr-5">
  <div
    id="profiles"
    class="z-10 bg-white absolute rtl:left-5 ltr:right-5 rounded-lg shadow w-80 dark:bg-gray-700"
    style="margin-right: 20px !important"
    *ngIf="isDropdownOpen"
  >
    <div class="p-5">
      <div
        class="ltr:text-left rtl:text-right text-lg dark:text-white font-bold"
      >
        <p>User Profile</p>
      </div>
      <div class="pt-2 border-b border-gray-200 pb-4">
        <div class="flex items-center justify-start">
          <div class="">
            <img
              class="w-20 rounded-full h-auto mr-auto"
              src="./assets/images/toogle_profile.png"
            />
          </div>
          <div class="ltr:ml-5 rtl:mr-5">
            <div class="text-sm dark:text-white font-bold">
              <p>Mathew Anderson</p>
            </div>
            <div class="text-xs pt-2 text-gray-600 dark:text-white">
              <p>Designer</p>
            </div>
            <div class="flex items-center pt-2 justify-start text-xs font-bold">
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 dark:text-white h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
              <div class="dark:text-white">
                <p class="mx-2">info@modernize.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex pt-5 items-center justify-start">
        <div class="bg-orange-100 rounded p-2">
          <img
            class="w-8 rounded-full h-auto mr-auto"
            src="./assets/images/setting1.png"
          />
        </div>
        <div class="ltr:ml-4 rtl:mr-4">
          <a routerLink="userprofile/settings">
            <div
              class="text-sm hover:text-theme-primary dark:hover:text-save-background dark:text-white font-bold"
            >
              <p>Setting</p>
            </div>
          </a>
        </div>
      </div>
      <div class="flex pt-5 items-center justify-start">
        <div class="bg-orange-100 rounded p-2">
          <img
            class="w-8 rounded-full h-auto mr-auto"
            src="./assets/images/profile.png"
          />
        </div>
        <div class="ltr:ml-4 rtl:mr-4">
          <a routerLink="userprofile/account">
            <div
              class="text-sm hover:text-theme-primary dark:hover:text-save-background dark:text-white font-bold"
              data-dropdown-="profiles"
              (click)="profilehide()"
            >
              <p>Account</p>
            </div>
          </a>
        </div>
      </div>
      <div class="pt-8 text-center">
        <a
          href="#"
          class="border-2 border-save-background hover:bg-theme-primary-light rounded px-28 py-2 text-save-background text-sm"
          >Log Out</a
        >
      </div>
    </div>
  </div>
</div>
