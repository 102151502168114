import { Component } from '@angular/core';

import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-timesheet',
  templateUrl: './job-timesheet.component.html',
})
export class JobTimesheetComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 400,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      date: '18/July/2023',
      task: 'Ac Mechanic',
      staff: 'Suresh',
      time: '10.30',
    },
    {
      date: '17/June/2023',
      task: 'Bike Mechanic',
      staff: 'Luffy',
      time: '11.45',
    },
    {
      date: '10/july/2023',
      task: 'Laptop Service',
      staff: 'Obito',
      time: '1.30',
    },
    {
      date: '20/july/2023',
      task: 'Decoration',
      staff: 'Naruto',
      time: '3.10',
    },
    {
      date: '24/july/2023',
      task: 'Camera man',
      staff: 'Itachi',
      time: '12.53',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
    },
    { field: 'task', headerName: 'task' },

    { field: 'staff', headerName: 'Staff' },
    { field: 'time', headerName: 'Time' },
  ];

  defaultColDef = {
    sortable: true,
    minWidth: 300,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
