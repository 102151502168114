import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-documents',
  templateUrl: './job-documents.component.html',
})
export class JobDocumentsComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      documents: 'Detail PDF',
      filename: 'Accounts',
      size: '2MB',
      date: '09/oct/2023',
      downlode: '1',
      delete: '0',
    },
    {
      documents: 'Detail PDF1',
      filename: 'Commerce',
      size: '2.5MB',
      date: '18/jul/20223',
      downlode: '5',
      delete: '1',
    },
    {
      documents: 'Detail PDF2',
      filename: 'Law',
      size: '3MB',
      date: '20/aug/2023',
      downlode: '3',
      delete: '2',
    },
    {
      documents: 'Detail PDF3',
      filename: 'Animation',
      size: '3.5MB',
      date: '28/feb/2023',
      downlode: '4',
      delete: '2',
    },
    {
      documents: 'Detail PDF4',
      filename: 'Chrome',
      size: '4MB',
      date: '16/dec/2023',
      downlode: '1',
      delete: '0',
    },
    {
      documents: 'Detail PDF',
      filename: 'Slack',
      size: '4.5MB',
      date: '09/mar/2023',
      downlode: '2',
      delete: '5',
    },
  ];

  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.name}, ${data.city}, ${data.country}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.name;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `<div class="pl-1">
            <a href="${params.node.data.link}">
            <h4 class="text-lg font-bold hover:underline">${params.node.data.documents}</h4>
         
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-sky-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Quotes : ${params.value.filename}
        </div>
        <div class="flex bg-pink-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Invoices: ${params.value.size}
        </div>
        <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Jobs : ${params.value.date}
        </div>
        <div class="flex bg-indigo-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          PO : ${params.value.downlode}
        </div>
        <div class="flex bg-indigo-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
        PO : ${params.value.delete}
      </div>`;
  };

  columnDefs: ColDef[] = [
    {
      field: 'documents',
      headerName: 'Documents',
      minWidth: 300,
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: this.baseFilterParams,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      cellRenderer: this.nameCellRenderer,
    },
    { field: 'filename', headerName: 'File name' },
    {
      field: 'size',
      headerName: 'Size',
    },
    {
      field: 'date',
      headerName: 'Date',
    },
    {
      field: 'downlode',
      headerName: 'Downlode',
    },
    {
      field: 'delete',
      headerName: 'Delete',
    },
  ];

  defaultColDef = {
    flex: 1,
    minWidth: 180,
    resizable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    filterParams: this.baseFilterParams,
  };

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
