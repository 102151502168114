import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-quotes-info-tasks',
  templateUrl: './quotes-info-tasks.component.html',
})
export class QuotesInfoTasksComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      name: 'Task-1',
      time: '10:00',
      rate: '1500.0',
      cost: '15,000.00',
      billable: '1000.00',
      sell: '10,000.00',
    },
    {
      name: 'Task 2',
      time: '10:00',
      rate: '2000.00',
      cost: '20,000.00',
      billable: '1500.00',
      sell: '0.00',
    },
    {
      name: '1 Task',
      time: '20:00',
      rate: '',
      cost: '35,000.00',
      billable: '',
      sell: '10,000.00',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
    },
    { field: 'time', headerName: 'Time' },
    { field: 'rate', headerName: 'Base Rate' },
    { field: 'cost', headerName: 'Total Cost' },
    { field: 'billable', headerName: 'Billable Rate' },
    { field: 'sell', headerName: 'Total Sell' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
