<div class="addClientForm">
  <div
    class="flex items-center ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
  >
    <a
      routerLink="/app/contacts"
      class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </a>
    <h1 class="text-3xl">Add New Contact</h1>
  </div>
  <div class="w-full py-10 ml-auto">
    <form>
      <div class="grid grid-cols-2 gap-x-8">
        <div class="col-span-2 mb-8">
          <h4 class="text-xl">General Information</h4>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="contact_name"
                id="contact"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="contact_name" class="input_label">Contact Name</label>
            </div>
          </div>
        </div>

        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="position"
                id="pos_ition"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="position" class="input_label">Position</label>
            </div>
          </div>
        </div>

        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="sal_utation"
                id="salutation"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="sal_utation" class="input_label">Salutation</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="add_ress"
                id="address"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="add_ress" class="input_label">Address</label>
            </div>
          </div>
        </div>
        <div class="text-sm py-5 text-center col-span-2">
          <p>
            Use Salutation and Addressee fields to personalise names on custom
            templates e.g. John or Mr J Smith
          </p>
        </div>

        <div class="text-xl col-span-2 py-5 pb-10">
          <p>Contact Information</p>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="ph_one"
                id="phone"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="ph_one" class="input_label">Phone</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="mo_bile"
                id="mobile"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="mo_bile" class="input_label">Mobile</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="email"
                name="e_mail"
                id="email"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="e_mail" class="input_label">E-mail Address</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="email"
                name="de_partment"
                id="dep"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="de_partment" class="input_label">Department</label>
            </div>
          </div>
        </div>
        <div class="flex py-5 items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm ltr:ml-2 rtl:mr-2">
            <p>Set as primary contact</p>
          </div>
        </div>

        <div class="py-5">
          <div
            class="flex items-center justify-start ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
          >
            <a class="px-5 text-white py-2 save rounded-xl" href="#">Save</a>
            <a class="px-5 text-white py-2 cancel rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
