<div class="flex">
  <button
    type="button"
    (click)="lightheme()"
    title="Toggle light mode"
    class="flex btn mx-2 items-center bg-white shadow-md p-4 px-6 hover:bg-gray-200 dark:bg-opacity-8 dark:bg-register-dark dark:hover:bg-hover-gray-100/10 rounded text-gray-900 dark:text-white text-center transition ease-in-out delay-100 hover:scale-110 duration-300 cursor-pointer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      [attr.stroke]="isDarkMode ? 'white' : '#fb9778'"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
      />
    </svg>
    <p class="text-black dark:text-white mx-2">Light</p>
  </button>
  <button
    type="button"
    (click)="darktheme()"
    title="Toggle dark mode"
    class="flex btn mx-2 items-center bg-white shadow-md p-4 px-6 dark:bg-register-dark hover:bg-gray-200 dark:bg-opacity-8 dark:hover:bg-hover-gray-100/10 rounded text-gray-900 dark:text-white text-center transition ease-in-out delay-100 hover:scale-110 duration-300 cursor-pointer disabled:bg-gray-400"
    [disabled]="isDarkMode"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      [attr.stroke]="isDarkMode ? '#fb9778' : 'black'"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
      />
    </svg>
    <p class="text-black dark:text-white mx-2">Dark</p>
  </button>
</div>
