import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isSidebarVisible = false;
  private sidebarVisibilitySubject = new Subject<boolean>();
  sidebarVisibility$ = this.sidebarVisibilitySubject.asObservable();

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
    this.sidebarVisibilitySubject.next(this.isSidebarVisible);
  }

  getSidebarVisibility(): boolean {
    return this.isSidebarVisible;
  }
}

export const fadeAnimation = trigger('fadeAnimation', [
  state('void', style({ width: '250px' })),
  state('*', style({ width: '80px' })),
  transition('void <=> *', animate('0.5s ease-in-out')),
]);
