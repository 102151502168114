<div class="bg-white dark:bg-theme-main-dark shadow-2xl mt-2 rounded-2xl">
  <!-- <div
    *ngIf="showSummaryCard"
    class="mt-8 mb-4 bg-white dark:bg-theme-wui-800 shadow-sm border dark:border-theme-wui-700 w-full rounded-3xl"
  >
    <div class="p-5">
      <div class="text-3xl">
        <p>Ramakrishnan</p>
      </div>
      <div class="text-sm pt-2">
        <p>ramakrishnan@gmail.com</p>
      </div>
    </div>
  </div> -->
  <div class="mt-2 rounded-2xl">
    <ul class="tab-nav">
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 1 }">
        <button
          type="button"
          class="w-full flex items-center justify-center dark:hover:bg-blue-50 py-2"
          (click)="toggleTab(1)"
          data-tab="home"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <p class="ml-2">Profile</p>
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 2 }">
        <button
          type="button"
          class="w-full flex items-center justify-center hover:bg-tab-hover dark:hover:bg-blue-50 btn py-2"
          (click)="toggleTab(2)"
          data-tab="contacts"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>

          <p class="ml-2">Password</p>
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div
        class="tab-pane"
        [ngClass]="{ block: showTabNumber === 1, hidden: showTabNumber !== 1 }"
        data-tab="profile"
      >
        <app-profile></app-profile>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{ block: showTabNumber === 2, hidden: showTabNumber !== 2 }"
        data-tab="contacts"
      >
        <app-password></app-password>
      </div>
    </div>
  </div>
</div>
