import { Component } from '@angular/core';
import {
  ColDef,
  FirstDataRenderedEvent,
  ITextFilterParams,
  ICellRendererParams,
  GridApi,
  GridOptions,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-quotes-info-description',
  templateUrl: './quotes-info-description.component.html',
})
export class QuotesInfoDescriptionComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      description: '0 Products',
      unitcost: '',
      quantity: '',
      totalcost: '',
      margin: '',
      unitsell: '',
      totalsell: '0.00',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
    },
    { field: 'unitcost', headerName: 'Unit Cost' },
    { field: 'quantity', headerName: 'Quantity' },
    { field: 'totalcost', headerName: 'Total Cost' },
    { field: 'margin', headerName: 'Margin' },
    { field: 'unitsell', headerName: 'Unit Sell' },
    { field: 'totalsell', headerName: 'Total Sell' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
