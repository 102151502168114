<div
  class="bg-white dark:bg-theme-main-darker rounded-xl dark:shadow-slate-700 shadow p-4 ltr:right-0 rtl:left-0 top-0"
>
  <div class="flex items-center justify-between">
    <div class="">
      <div class="flex items-center justify-start themeTextColor">
        <!-- Toggle sidebar button  start -->
        <button
          (click)="toggleSidebar()"
          class="btn dark:hover:bg-gray-200/10 text-black dark:text-top-nav-bar hover:dark:text-top-nav-bar hover:bg-gray-200 rounded-full p-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            ></path>
          </svg>
        </button>
        <!-- Toggle sidebar button  end -->
        <div
          class="lg:ml-4 md:ml-0 ml-0 text-black dark:text-top-nav-bar hover:dark:text-top-nav-bar dark:hover:bg-gray-200/10 hover:bg-gray-200 p-2 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 cursor-pointer h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="">
      <div class="flex pt-1 lg:justify-end md:justify-end justify-center">
        <app-localization></app-localization>
        <app-message></app-message>
        <app-notification></app-notification>

        <app-avatar></app-avatar>
      </div>
    </div>
  </div>
</div>
