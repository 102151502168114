<div>
  <div class="flex supplier-boxes justify-between mb-4">
    <div class="flex justify-between">
      <div class="flex supply items-center">
        <h2 class="lg:text-2xl md:text-2xl text-xl">Contacts</h2>
        <div class="ltr:ml-4 rtl:mr-4">
          <a
            routerLink="/app/supplier/add/contact"
            class="text-white bg-theme-primary rounded-2xl px-4 lg:py-2 md:py-2 py-1 lg:text-sm md:text-sm text-xs"
          >
            + Add Contact
          </a>
        </div>
      </div>
    </div>
    <div>
      <input
        type="search"
        id="filter-text-box"
        class="form-control border-2 lg:mt-0 md:mt-0 mt-4 border-gray-300 bg-white dark:border dark:border-theme-wui-700 dark:bg-theme-wui-dark-100 h-10 px-5 pr-3 rounded-lg text-sm focus:outline-none ring:1 ring-sky-300"
        placeholder="Filter"
        (input)="onFilterTextBoxChanged()"
      />

      <!-- <button
        (click)="clearAllFilters()"
        class="ltr:ml-4 rtl:mr-4 btn rounded-full text-white bg-sky-400 px-4 py-2 mb-4 text-sm"
      >
        Clear Filters
      </button> -->
    </div>
  </div>

  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [paginationPageSize]="10"
    [suppressCellFocus]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  />
</div>
