<div
  class="flex items-center pb-5 ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
>
  <a
    routerLink="/app/interaction/contacts"
    class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </a>
  <h1 class="text-3xl dark:text-theme-text-blue-200">Add New Contact</h1>
</div>
<div
  class="addClientForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 shadow dark:shadow-slate-700 rounded-xl"
>
  <div class="w-full ml-auto">
    <form>
      <div class="grid grid-cols-2 gap-x-8">
        <div class="col-span-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Client Information
          </h4>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="client_name"
                id="client_name"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="client_name" class="input_label">Client Name *</label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="email"
                name="E_mail"
                id="E_mail"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="E_mail" class="input_label">E-mail *</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="accounts"
              [suggestions]="filteredaccount"
              (completeMethod)="filteraccount($event)"
              [dropdown]="true"
              name="account"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Account Manager </label>
          </span>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="jobs"
              [suggestions]="filteredjob"
              (completeMethod)="filterjob($event)"
              [dropdown]="true"
              name="job"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Job Manager </label>
          </span>
        </div>

        <div class="col-span-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Contact Information
          </h4>
        </div>
        <!-- Coulmn 1 Starts -->
        <div class="lg:col-span-1 md:col-span-1 col-span-2">
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <textarea
                  type="text"
                  name="postal_address"
                  id="town_city_input"
                  class="peer input_box"
                  placeholder=" "
                  required
                ></textarea>
                <label for="postal_address" class="input_label">Address</label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Town_City"
                  id="town_city_input"
                  class="peer input_box"
                  placeholder=" "
                  required
                />
                <label for="town_City" class="input_label">Town/City</label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="State_Region"
                  id="Stateregion_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="State_Region" class="input_label"
                  >State/Region</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Postal_Code"
                  id="postal_code_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Postal_Code" class="input_label">Postal Code</label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Country"
                  id="country_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Country" class="input_label">Country </label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Phone"
                  id="phone_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Phone" class="input_label">Phone</label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="url"
                  name="Website"
                  id="website_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Website" class="input_label">Website</label>
              </div>
            </div>
          </div>
          <div
            class="pb-5 lg:col-span-1 md:col-span-1 col-span-2 lg:pt-7 md:pt-0 pt-0"
          >
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Department"
                  id="department_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Department" class="input_label">Department </label>
              </div>
            </div>
          </div>
        </div>
        <!-- Column 1 Ends -->
        <!-- Column 2 Starts -->
        <div class="lg:col-span-1 md:col-span-1 col-span-2">
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <textarea
                  type="text"
                  name="postal_address"
                  id="town_city_input"
                  class="peer input_box"
                  placeholder=" "
                  required
                ></textarea>
                <label for="postal_address" class="input_label"
                  >Postal Address</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="town_city_input1"
                  id="towncity_input1"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="town_city_input1" class="input_label"
                  >Town/City</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="State_Region1"
                  id="floating_phone"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="State_Region1" class="input_label"
                  >State/Region</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Postal_Code1"
                  id="floating_phone"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Postal_Code1" class="input_label"
                  >Postal Code</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Country1"
                  id="Country"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Country1" class="input_label">Country </label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Fax"
                  id="fax_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Fax" class="input_label">Fax </label>
              </div>
            </div>
          </div>
          <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="url"
                  name="Referal_Source"
                  id="referal_source_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Referal_Source" class="input_label"
                  >Referral Source</label
                >
              </div>
            </div>
            <div class="">
              <p class="text-xs text-gray-600 dark:text-gray-400 pl-4 pt-2">
                Use Referral Source to track how your clients came to you, e.g.
                Google, Twitter, BNI etc
              </p>
            </div>
          </div>
          <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
            <span class="p-float-label">
              <p-autoComplete
                formControlName="tyres"
                [suggestions]="filteredbussiness"
                (completeMethod)="filterbussiness($event)"
                [dropdown]="true"
                name="tyr"
                class=""
                styleClass="twPrimeAutoComplete "
                autocomplete
                [inputStyle]=""
                inputStyleClass="twPrimeAutoCompleteInput"
                aria-label=""
              ></p-autoComplete>
              <label for="autocomplete">Bussiness Strucutre </label>
            </span>
          </div>
        </div>
        <!-- Column 2 Ends -->

        <div class="col-span-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Tax and Company Information
          </h4>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="TFN"
                id="input_box"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="TFN" class="input_label">TFN </label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative pt-2">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="ACN"
                id="acn_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="ACN" class="input_label">ACN </label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="ABN"
                id="input_box"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="ABN" class="input_label">ABN </label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Branch"
                id="branch_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Branch" class="input_label">Branch/CAC/ICA # </label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="bussiness"
              [suggestions]="filteredbussiness"
              (completeMethod)="filterbussiness($event)"
              [dropdown]="true"
              name="bussines"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Bussiness Strucutre </label>
          </span>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="selectedMonth"
              [suggestions]="filteredMonths"
              (completeMethod)="filterMonths($event)"
              [dropdown]="true"
              name="month"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">January </label>
          </span>
        </div>

        <div class="col-span-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Financial Information
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="tyres"
              [suggestions]="filteredtyre"
              (completeMethod)="filtertyre($event)"
              [dropdown]="true"
              name="tyre"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Select Tyre </label>
          </span>
        </div>
        <div class="flex ml-4 lg:pb-0 md:pb-5 pb-2 items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>

          <div class="text-sm ltr:ml-3 dark:text-theme-text-blue-200 rtl:mr-3">
            <p>Zero Rated Tax</p>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="billing"
                id="billing_details"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="billing" class="input_label">Billing Details </label>
            </div>
          </div>
        </div>

        <div class="col-span-2 mb-8">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Interface Information
          </h4>
        </div>

        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Export_Code"
                id="export_code_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Export_Code" class="input_label">Export Code </label>
            </div>
          </div>
        </div>

        <div class="col-span-2">
          <hr class="border-gray-300" />
        </div>
        <div class="py-5">
          <div
            class="flex items-center justify-start ltr:space-x-2 rtl:space-x-2 rtl:space-x-reverse"
          >
            <a class="px-5 text-white py-2 save rounded-xl" href="#">Save</a>
            <a class="px-5 text-white cancel py-2 rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
