<div class="">
  <h4 class="text-lg text-theme-primary font-bold mt-4">Quotes Information</h4>
  <dl class="info-list">
    <dt>Quote :</dt>
    <dd>Q00076</dd>

    <dt>Job :</dt>
    <dd>Quote-180701 - J00051</dd>

    <dt>Date :</dt>
    <dd>18-Jul-2023</dd>

    <dt>Client :</dt>
    <dd>MyCortex</dd>

    <dt>Name :</dt>
    <dd>Quote-180701</dd>

    <dt>Description :</dt>
    <dd>Quote-180701</dd>

    <dt>Valid To :</dt>
    <dd>15-Aug-2023</dd>

    <dt>Contact :</dt>
    <dd>Reginald</dd>
  </dl>
</div>
<div class="pt-10">
  <div class="font-bold text-theme-primary pb-5 text-lg">
    <p>Tasks</p>
  </div>
  <app-quotes-info-tasks></app-quotes-info-tasks>
</div>
<div class="pt-10">
  <div class="font-bold text-theme-primary pb-5 text-lg">
    <p>Product / License</p>
  </div>
  <app-quotes-info-products></app-quotes-info-products>
</div>
<div class="pt-10">
  <div class="font-bold text-theme-primary pb-5 text-lg">
    <p>Support</p>
  </div>
  <app-quotes-info-support></app-quotes-info-support>
</div>
<div class="pt-5 quotes-info">
  <div class="col-span-2">
    <h4 class="text-lg text-theme-primary text-left font-bold mt-4">Summary</h4>
    <dl>
      <dt class="text-lg font-bold pt-2">Task Total</dt>

      <dt class="text-lg font-bold pt-2">Product / License Total</dt>

      <dt class="text-lg font-bold pt-2">Support Total</dt>

      <dt class="text-lg font-bold pt-2">Total excluding GST</dt>

      <dt class="text-lg font-bold pt-2">Tax (10.00%)</dt>

      <dt class="text-lg font-bold pt-2">Total including GST</dt>

      <dt class="text-lg font-bold pt-2">Gross Profit Margin</dt>

      <dt class="text-lg font-bold pt-2">Gross Profit %</dt>
    </dl>
  </div>
  <div class="">
    <h4 class="text-lg text-theme-primary text-right font-bold mt-4">
      Product / License
    </h4>
    <dl>
      <dd class="text-sm pt-2 text-right">35,000.00</dd>

      <dd class="text-sm pt-2 text-right">0.00</dd>

      <dd class="text-sm pt-2 text-right">0.00</dd>

      <dd
        class="text-sm pt-2 text-right border-t dark:border-white border-black"
      >
        35,000.00
      </dd>

      <dd
        class="text-sm pt-2 text-right border-b dark:border-white border-black"
      >
        0.00
      </dd>

      <dd class="text-sm pt-2 text-right">35,000.00</dd>
    </dl>
  </div>
  <div class="">
    <h4 class="text-lg text-theme-primary font-bold mt-4">Sell</h4>
    <dl>
      <dd class="text-sm pt-2 text-right">10,000.00</dd>

      <dd class="text-sm pt-2 text-right">0.00</dd>

      <dd class="text-sm pt-2 text-right">0.00</dd>

      <dd
        class="text-sm pt-2 text-right border-t border-black dark:border-white"
      >
        10,000.00
      </dd>

      <dd
        class="text-sm pt-2 text-right border-b dark:border-white border-black"
      >
        0.00
      </dd>

      <dd class="text-sm pt-2 text-right">10,000.00</dd>

      <dd class="text-sm pt-2 text-right">-25,000.00</dd>

      <dd class="text-sm pt-2 text-right">0.00</dd>
    </dl>
  </div>
</div>
<div class="pt-5">
  <div class="">
    <h4 class="text-lg text-theme-primary font-bold mt-4">Option</h4>
  </div>
  <div class="text-sm pt-2">
    <p>Explanation</p>
  </div>
</div>
<div class="pt-5">
  <app-quotes-info-description></app-quotes-info-description>
</div>
