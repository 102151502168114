import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-setting-sidebar',
  templateUrl: './setting-sidebar.component.html',
})
export class SettingSidebarComponent {
  sidebarVisible2: boolean = false; // Variable to control sidebar visibility

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    // Check if the clicked element has a specific class
    if (
      !this.el.nativeElement.contains(targetElement) ||
      targetElement.classList.contains('sidebar-settings')
    ) {
      this.sidebarVisible2 = false; // Close the sidebar
    }
  }
}
