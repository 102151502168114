<div class="clientDetailWrapper">
  <div class="flex justify-between items-center">
    <div
      class="flex items-center ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
    >
      <a
        routerLink="/app/collaboration/jobs"
        class="bg-gray-200 dark:bg-theme-wui-700 hover:bg-gray-300 dark:hover:bg-theme-wui-800 rounded-full p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </a>
      <h1 class="text-3xl dark:text-theme-text-blue-200">Apollo Tyres</h1>
    </div>
    <div class="flex ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse">
      <div
        class="bg-theme-primary px-6 cursor-pointer py-2 rounded-full flex space-x-6 text-gray-400"
      >
        <button class="btn text-base font-light text-white">Edit</button>
      </div>
      <div>
        <button
          id="dropdownButton"
          data-dropdown-toggle="dropdown"
          class="text-gray-900 dark:text-gray-100 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-orange-300"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="moreMenu"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </button>
        <!-- Dropdown menu -->
        <div
          id="dropdown"
          class="hidden z-10 bg-white rounded-lg shadow w-32 dark:bg-gray-700"
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownButton"
          >
            <li *ngIf="showSummaryCard" class="block">
              <button
                (click)="hideSummaryCard()"
                class="w-full btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-theme-wui-800 rounded text-left flex justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                  />
                </svg>
                <span class="mx-2 text-left">Hide Card</span>
              </button>
            </li>
            <li *ngIf="!showSummaryCard">
              <button
                (click)="unhideSummaryCard()"
                class="w-full btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-theme-wui-800 rounded text-left flex justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                  />
                </svg>
                <span class="mx-2 text-left">Show Card</span>
              </button>
            </li>
            <li>
              <button
                data-modal-target="mergeModal"
                data-modal-toggle="mergeModal"
                type="button"
                class="w-full btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-theme-wui-800 rounded text-left flex justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                  />
                </svg>
                <span class="mx-2 text-left">Merge</span>
              </button>
            </li>
            <li>
              <button
                (click)="archiveConfirmation()"
                class="w-full btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-theme-wui-800 rounded text-left flex justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <span class="mx-2 text-left">Archive</span>
              </button>
            </li>
            <li>
              <button
                (click)="deleteConfirmation()"
                class="w-full btn px-2 py-2 hover:bg-gray-200 dark:hover:bg-theme-wui-800 rounded text-left flex justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>

                <span class="mx-2 text-left">Delete</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- <div
    *ngIf="showSummaryCard"
    class="mt-4 mb-4 bg-white dark:bg-theme-wui-800 shadow-sm border dark:border-theme-wui-700 w-full rounded-3xl"
  ></div> -->
  <div
    class="content-warpper border-2 p-10 rounded-3xl dark:border-top-nav-bar mt-8"
  >
    <ul class="tab-navs">
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 1 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(1)"
          (click)="toggleTab(1)"
          data-tab="home"
        >
          Info
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 2 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(2)"
          (click)="toggleTab(2)"
          data-tab="contacts"
        >
          Timesheet
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 3 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(3)"
          (click)="toggleTab(3)"
          data-tab="quotes"
        >
          Notes
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 4 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(4)"
          (click)="toggleTab(4)"
          data-tab="jobs"
        >
          Documents
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 5 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(5)"
          (click)="toggleTab(5)"
          data-tab="invoices"
        >
          Costs
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 6 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(6)"
          (click)="toggleTab(6)"
          data-tab="notes"
        >
          Supports
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 7 }">
        <button
          type="button"
          class="block w-full py-2"
          [@tabClick]="getTabClickState(7)"
          (click)="toggleTab(7)"
          data-tab="documents"
        >
          Financial
        </button>
      </li>
    </ul>

    <div class="tab-content overflow-hidden shadow dark:shadow-slate-400">
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 1,
          hidden: showTabNumber !== 1,
          'tab-animation': showTabNumber === 1
        }"
        data-tab="info"
      >
        <app-job-info></app-job-info>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 2,
          hidden: showTabNumber !== 2,
          'tab-animation': showTabNumber === 2
        }"
        data-tab="contacts"
      >
        <app-job-timesheet></app-job-timesheet>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 3,
          hidden: showTabNumber !== 3,
          'tab-animation': showTabNumber === 3
        }"
        data-tab="quotes"
      >
        <app-job-notes></app-job-notes>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 4,
          hidden: showTabNumber !== 4,
          'tab-animation': showTabNumber === 4
        }"
        data-tab="jobs"
      >
        <app-job-documents></app-job-documents>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 5,
          hidden: showTabNumber !== 5,
          'tab-animation': showTabNumber === 5
        }"
        data-tab="invoices"
      >
        <app-job-costs></app-job-costs>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 6,
          hidden: showTabNumber !== 6,
          'tab-animation': showTabNumber === 6
        }"
        data-tab="notes"
      >
        <app-job-support></app-job-support>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 7,
          hidden: showTabNumber !== 7,
          'tab-animation': showTabNumber === 7
        }"
        data-tab="documents"
      >
        <app-job-financial></app-job-financial>
      </div>
    </div>
  </div>
</div>
<!-- Main modal -->
<div
  id="mergeModal"
  tabindex="-1"
  aria-hidden="true"
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-slate-500 bg-opacity-50"
>
  <div class="relative w-full max-w-2xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div
        class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
      >
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
          Merge Clients
        </h3>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-hide="mergeModal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-6 space-y-6">
        <form>
          <div class="flex flex-col space-y-4">
            <div>
              <label
                for="mergeClient"
                class="block text-sm font-medium text-gray-900 dark:text-gray-100"
                >Merge into</label
              >
              <select
                id="mergeClient"
                name="mergeClient"
                class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-gray-100 form-select focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option value="1">Client 1</option>
                <option value="2">Client 2</option>
                <option value="3">Client 3</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div
        class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600"
      >
        <button
          data-modal-hide="mergeModal"
          type="button"
          class="text-white bg-orange-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
        <button
          data-modal-hide="mergeModal"
          type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
