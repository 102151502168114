<div class="p-4 flex flex-col justify-center items-center">
  <h1 class="text-3xl font-bold underline">Worksheet UI</h1>
  <div class="my-8">
    <nav>
      <ul class="w-full space-x-8">
        <li>
          <a routerLink="/registration/page" class="text-blue-500"
            >Register Page</a
          >
        </li>
        <li><a routerLink="/login" class="text-blue-500">Login</a></li>
        <li>
          <a routerLink="/app/dashboard" class="text-blue-500">Dashboard</a>
        </li>
        <li><a routerLink="/app/quotes" class="text-blue-500">Quotes</a></li>
        <li>
          <a routerLink="/app/admin" class="text-blue-500">Organizer Console</a>
        </li>
        <li>
          <a routerLink="/app/organisation" class="text-blue-500"
            >Organisation Settings</a
          >
        </li>
        <li>
          <a routerLink="/app/clients" class="text-blue-500"
            >Clients List Page</a
          >
        </li>
        <li>
          <a routerLink="/app/clients/add " class="text-blue-500">Add Client</a>
        </li>
        <li>
          <a routerLink="/app/clients/detail " class="text-blue-500"
            >Client Detail</a
          >
        </li>
        <li>
          <a routerLink="/app/suppliers " class="text-blue-500"
            >Supplier Lists</a
          >
        </li>
        <li>
          <a routerLink="/app/suppliers/add" class="text-blue-500"
            >Add Supplier</a
          >
        </li>
        <li>
          <a routerLink="/app/contacts" class="text-blue-500">Contacts List</a>
        </li>
        <li>
          <a routerLink="/app/jobs" class="text-blue-500">Jobs List page</a>
        </li>
        <li>
          <a routerLink="/app/invoices" class="text-blue-500"
            >Invoices List page</a
          >
        </li>
        <li>
          <a routerLink="/app/orders" class="text-blue-500"
            >Purchase Order page</a
          >
        </li>
        <li>
          <a routerLink="/maintainance" class="text-blue-500"
            >Maintainance Mode</a
          >
        </li>
        <li>
          <a routerLink="/error" class="text-blue-500">404 Page</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
