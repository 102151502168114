import { Component } from '@angular/core';
import {
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-contactadd',
  templateUrl: './contactadd.component.html',
  providers: [ConfirmationService, MessageService],
})
export class ContactaddComponent {}
