<div class="">
  <div class="flex justify-end lg:p-2 md:p-2 p-2">
    <div>
      <input
        type="search"
        id="filter-text-box"
        class="form-control border-2 dark:text-white border-gray-300 hover:border-black focus:border-theme-primary bg-white dark:border focus:dark:border-2 focus:dark:border-theme-wui-700 dark:border-theme-wui-700 border-dark dark:bg-transparent h-10 lg:px-5 md:px-5 px-3 lg:pr-20 md:pr-10 pr-0 rounded-lg text-sm"
        placeholder="Filter"
        (input)="onFilterTextBoxChanged()"
      />

      <!-- <button
          (click)="clearAllFilters()"
          class="ltr:ml-4 rtl:mr-4 btn rounded-full dark:bg-[#22c9d6] text-white bg-sky-400 px-4 py-2 mb-4 dark:mb-0 text-sm"
        >
          Clear Filters
        </button> -->
    </div>
  </div>
  <div class="relative">
    <ag-grid-angular
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [paginationPageSize]="10"
      [suppressCellFocus]="true"
      (firstDataRendered)="onFirstDataRendered($event)"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    />
    <div
      class="example-wrapper pages w-fit block absolute bottom-2 ltr:right-0 rtl:left-0 left-5"
    >
      <div class="example-header flex items-center justify-start">
        <p class="page dark:text-white mx-2">Page Size</p>
        <select
          class="mx-2 py-1 bg-transparent border page dark:text-white dark:border dark:border-white border-gray-400 hover:border-black px-5 rounded-lg"
          (change)="onPageSizeChanged()"
          id="page-size"
        >
          <option value="5">5</option>
          <option value="10">10</option>

          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  </div>
</div>
