<div class="overflow-x-auto">
  <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-8">
    <div class="lg:col-span-2 md:col-span-2 col-span-1">
      <div
        class="flex lg:flex-row md:flex-row flex-col bg-white dark:bg-theme-main-dark rounded-3xl shadow-rounded-3xl dashboard-shadow dark:border dark:shadow dark:border-dashboard-border items-center justify-between"
      >
        <div class="p-6 py-8">
          <div class="dashboard text-black dark:text-white">
            <p>Hey Julia,</p>
            <p class="pt-2">Download Latest Report</p>
          </div>
          <div class="py-5 pt-8">
            <a
              href="#"
              class="text-sm text-white rounded-lg p-2 px-4 bg-theme-secondary"
              >Download</a
            >
          </div>
        </div>
        <div class="">
          <img
            class="w-60 h-50 ml-auto"
            src="../assets/images/login_image.png"
          />
        </div>
      </div>
    </div>
    <div class="lg:col-span-2 md:col-span-2 col-span-1">
      <div
        class="p-6 py-8 dark:border dark:border-dashboard-border dark:bg-transparent dark:text-gray-400 dark:shadow dashboard-shadow bg-white rounded-3xl"
      >
        <div
          class="flex bg-white dark:bg-theme-main-dark ro lg:flex-row md:flex-row flex-col items-center justify-between"
        >
          <div class="dashboard text-black dark:text-white">
            <p class="">Weekly Time Sheet</p>
          </div>

          <div class="">
            <div class="mr-3 relative">
              <input
                type="text"
                class="border-2 dark:border-dashboard-border dark:bg-transparent dark:text-white rounded-xl px-2 py-2"
                placeholder="Search by Staff"
                name="name"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 dark:text-white absolute ltr:right-2 rtl:left-2 text-gray-800 bottom-2 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          class="flex bg-white dark:bg-theme-main-dark items-center lg:justify-between md:justify-center justify-center"
        >
          <div class="">
            <div class="pt-3 text-start">
              <p class="font-bold text-theme-primary">
                24-Apr-2023 To 30-Apr-2023
              </p>
            </div>
            <div class="flex pt-2 items-center justify-between">
              <p class="font-bold text-sm">Staff Name :</p>
              <p class="text-sm">Surya</p>
            </div>
            <div class="">
              <div class="flex pt-2 items-center justify-between">
                <p class="font-bold text-sm">Task Name :</p>
                <p class="text-sm">Ac Mechanic</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rounded-3xl lg:block md:hidden hidden dashboard-shadow dark:shadow bg-theme-primary"
    >
      <div class="p-5 pt-10">
        <div class="flex items-start justify-between">
          <div class="font-semibold text-white">
            <p>Supports</p>
          </div>
          <div class="bg-white p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-10 text-gray-500 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>
          </div>
        </div>
        <div class="text-3xl font-bold text-white">
          <p>1</p>
        </div>
        <div class="text-gray-100 text-sm pt-1">
          <p>This Month</p>
        </div>
      </div>
    </div>
    <div
      class="rounded-3xl dark:border lg:block md:hidden hidden dark:border-dashboard-border dark:shadow dark:bg-theme-main-dark dark:text-gray-400 bg-white dashboard-shadow"
    >
      <div class="p-5 pt-10">
        <div class="flex items-start justify-between">
          <div class="font-semibold">
            <p>Purchase Orders</p>
          </div>
          <div class="bg-yellow-400 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 text-white h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </div>
        </div>
        <div class="text-3xl font-bold">
          <p>15</p>
        </div>
        <div class="text-sm pt-1">
          <p>This Month</p>
        </div>
      </div>
    </div>

    <div class="">
      <div
        class="rounded-3xl col-span-1 dark:border dark:border-dashboard-border dark:bg-theme-main-dark dark:text-gray-400 dark:shadow bg-white dashboard-shadow"
      >
        <div class="p-5 py-10">
          <div class="flex items-start justify-between">
            <div class="font-semibold lg:text-lg md:text-sm text-sm">
              <p>Quotes</p>
            </div>
            <div class="p-2 rounded-full bg-green-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-10 h-10 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </div>
          </div>
          <div class="text-3xl font-bold">
            <p>52</p>
          </div>
          <div class="text-sm pt-1">
            <p>This Month</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rounded-3xl bg-white dark:bg-theme-primary col-span-1 lg:hidden md:block block dashboard-shadow dark:shadow"
    >
      <div class="p-5 py-10 col-span-1">
        <div class="flex items-start justify-between">
          <div class="font-semibold lg:text-lg md:text-sm text-sm text-white">
            <p>Supports</p>
          </div>
          <div class="bg-white p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-10 text-gray-500 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>
          </div>
        </div>
        <div class="text-3xl font-bold text-white">
          <p>1</p>
        </div>
        <div class="text-gray-100 text-sm pt-1">
          <p>This Month</p>
        </div>
      </div>
    </div>
    <div
      class="rounded-3xl dark:border col-span-1 lg:hidden md:block block dark:shadow dark:border-dashboard-border dark:bg-theme-main-dark dark:text-gray-400 bg-white dashboard-shadow"
    >
      <div class="p-5 py-10">
        <div class="flex items-start justify-between">
          <div class="font-semibold lg:text-lg md:text-sm text-sm">
            <p>Purchase Orders</p>
          </div>
          <div class="bg-yellow-400 p-2 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 text-white h-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </div>
        </div>
        <div class="text-3xl font-bold">
          <p>15</p>
        </div>
        <div class="text-sm pt-1">
          <p>This Month</p>
        </div>
      </div>
    </div>
    <div class="">
      <div
        class="rounded-3xl dark:border col-span-1 dark:border-dashboard-border dark:bg-theme-main-dark dark:text-gray-400 dark:shadow bg-white dashboard-shadow"
      >
        <div class="p-5 py-10">
          <div
            class="flex items-start lg:text-lg md:text-sm text-sm justify-between"
          >
            <div class="font-semibold">
              <p>Jobs</p>
            </div>
            <div class="p-2 rounded-full bg-cyan-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-10 h-10 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
          </div>
          <div class="text-3xl font-bold">
            <p>49</p>
          </div>
          <div class="text-sm pt-1">
            <p>This Month</p>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:block md:block hidden"></div>
    <div class="lg:block md:block hidden"></div>
    <div class="lg:block md:block hidden"></div>
    <div>
      <div class="row-span-2">
        <div
          class="rounded-3xl dark:border col-span-1 dark:border-dashboard-border dark:bg-theme-main-dark dark:text-gray-400 dark:shadow bg-white dashboard-shadow"
        >
          <div class="p-5 py-10">
            <div class="flex items-start justify-between">
              <div class="font-semibold lg:text-lg md:text-sm text-sm">
                <p>Invoices</p>
              </div>
              <div class="p-2 rounded-full bg-pink-text">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-10 h-10 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                  />
                </svg>
              </div>
            </div>
            <div class="text-3xl font-bold">
              <p>16</p>
            </div>
            <div class="text-sm pt-1">
              <p>This Month</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
