<div class="mb-4">

<nav class="flex" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-3">
    <li class="inline-flex items-center">
      <a href="app/admin/" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
        <svg class="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
        </svg>
        Organiser Console
      </a>
    </li>

    <li aria-current="page">
      <div class="flex items-center">
        <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Email Templates</span>
      </div>
    </li>
  </ol>
</nav>

</div>
<div class="EmailTemplatesWrapper bg-white p-5 rounded-sm">

  <h2 class="text-2xl mb-4">Email Templates</h2>

  <div class="wrapper flex">
    <aside>
      <div class="flex flex-col">
      <div class="mt-2 p-2 bg-gray-50">
        <input
          type="search"
          id="filter-text-box"
          class="w-full form-control border-1 border-gray-300 bg-white h-10 px-5 pr-3 rounded-sm text-sm focus:outline-none ring:1 ring-sky-300 mb-2"
          placeholder="Filter in Subject..."
          (input)="onFilterTextBoxChanged()"
        />
        <ag-grid-angular
          class="ag-theme-alpine"
          style="width: 350px; height: 100%;"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [pagination]="true"
          [paginationPageSize]="10"
          [suppressCellFocus]="true"
          [headerHeight]="0"
        />

      </div>
      </div>
    </aside>
    <main class="bg-white p-4 ">
      <form>
          <div>
            <label>Template Name</label>
            <input class="w-full form-control border" name="templateName">
          </div>
          <div class="flex flex-col">
            <label >Type</label>
            <select>
              <option value="1">Quote</option>

            </select>
          </div>
          <div>
            <label>From</label>
            <input class="w-full form-control border" name="from">
          </div>
          <div>
            <label>Copy To</label>
            <input class="w-full form-control border" name="subject">
          </div>
          <div>
            <label>Blind Copy To</label>
            <input class="w-full form-control border" name="subject">
          </div>
          <div>
            <label >Format</label>
            <select>
              <option value="1">Plain Text</option>
              <option value="1">HTML</option>
            </select>
          </div>
          <div>
            <label>Subject</label>
            <input class="w-full form-control border" name="subject">
          </div>
          <div>
            <label>Body</label>
            <textarea class="w-full form-control border" name="body"></textarea>
          </div>
          <div class="flex space-x-6">
            <input type="submit" class="btn btn-primary bg-orange-400 border-2 border-orange-500 px-4 py-2 rounded-xl text-white" value="Save">
            <input type="submit" class="btn btn-primary bg-sky-100 px-4 py-2 border-2 border-sky-300 rounded-xl" value="Cancel">
          </div>
      </form>
    </main>
  </div>
</div>
