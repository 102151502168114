import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-see-all-messages',
  templateUrl: './see-all-messages.component.html',
})
export class SeeAllMessagesComponent {
  showSummaryCard: boolean = false;
  hideSummaryCard() {
    this.showSummaryCard = false;
  }

  unhideSummaryCard() {
    this.showSummaryCard = true;
  }
  archiveConfirmation() {
    Swal.fire({
      title: '#1801 Marla Darsuz',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
  archiveConfirmation1() {
    Swal.fire({
      title: ' #1802   Jim Beam',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
  archiveConfirmation2() {
    Swal.fire({
      title: '#1803 Paul Ferrez',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
  archiveConfirmation3() {
    Swal.fire({
      title: '#1804 Sara Dechicco',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
  archiveConfirmation4() {
    Swal.fire({
      title: '#1805 Freddy Murro',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
  archiveConfirmation5() {
    Swal.fire({
      title: '#1806 Carla Sandaers',
      text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Error accusantium molestias fugit commodi doloremque.',
      showCancelButton: false,
      confirmButtonText: 'Cancel.',
    }).then((result) => {});
  }
}
