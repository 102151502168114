<div class="supplierDetailWrapper">
  <div class="flex justify-between items-center">
    <div
      class="flex items-center ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
    >
      <a
        routerLink="/app/interaction/suppliers"
        class="bg-gray-200 dark:bg-theme-wui-700 hover:bg-gray-300 rounded-full p-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 ltr:rotate-0 rtl:rotate-180 dark:text-theme-text-blue-200 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </a>
      <h1 class="text-3xl dark:text-theme-text-blue-200">Voltas</h1>
    </div>
  </div>

  <div
    class="content-warpper border-2 p-10 rounded-3xl dark:border-top-nav-bar mt-8"
  >
    <ul class="tab-navs">
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 1 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(1)"
          (click)="toggleTab(1)"
          data-tab="home"
        >
          Info
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 2 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(2)"
          (click)="toggleTab(2)"
          data-tab="contacts"
        >
          Contacts
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 3 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(3)"
          (click)="toggleTab(3)"
          data-tab="contacts"
        >
          Notes
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 4 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(4)"
          (click)="toggleTab(4)"
          data-tab="quotes"
        >
          Documents
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 5 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(5)"
          (click)="toggleTab(5)"
          data-tab="jobs"
        >
          Purchase Order
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 6 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(6)"
          (click)="toggleTab(6)"
          data-tab="invoices"
        >
          Cost
        </button>
      </li>
      <li class="tab" [ngClass]="{ 'tab-active': showTabNumber === 7 }">
        <button
          type="button"
          class="block w-full btn py-2"
          [@tabClick]="getTabClickState(7)"
          (click)="toggleTab(7)"
          data-tab="notes"
        >
          Log
        </button>
      </li>
    </ul>

    <div class="tab-content overflow-hidden shadow dark:shadow-slate-400">
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 1,
          hidden: showTabNumber !== 1,
          'tab-animation': showTabNumber === 1
        }"
        data-tab="info"
      >
        <app-supllierdetails-info></app-supllierdetails-info>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 2,
          hidden: showTabNumber !== 2,
          'tab-animation': showTabNumber === 2
        }"
        data-tab="contact"
      >
        <app-supplier-contact></app-supplier-contact>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 3,
          hidden: showTabNumber !== 3,
          'tab-animation': showTabNumber === 3
        }"
        data-tab="notes"
      >
        <app-supllierdetail-notes></app-supllierdetail-notes>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 4,
          hidden: showTabNumber !== 4,
          'tab-animation': showTabNumber === 4
        }"
        data-tab="documents"
      >
        <app-supllierdetail-documents></app-supllierdetail-documents>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 5,
          hidden: showTabNumber !== 5,
          'tab-animation': showTabNumber === 5
        }"
        data-tab="purchase"
      >
        <app-supllierdetail-purchaseorder></app-supllierdetail-purchaseorder>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 6,
          hidden: showTabNumber !== 6,
          'tab-animation': showTabNumber === 6
        }"
        data-tab="cost"
      >
        <app-supllierdetail-cost></app-supllierdetail-cost>
      </div>
      <div
        class="tab-pane"
        [ngClass]="{
          block: showTabNumber === 7,
          hidden: showTabNumber !== 7,
          'tab-animation': showTabNumber === 7
        }"
        data-tab="log"
      >
        <app-supllierdetail-log></app-supllierdetail-log>
      </div>
    </div>
  </div>
</div>
<!-- Main modal -->
<div
  id="mergeModal"
  tabindex="-1"
  aria-hidden="true"
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-slate-500 bg-opacity-50"
>
  <div class="relative w-full max-w-2xl max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div
        class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
      >
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
          Merge Clients
        </h3>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-hide="mergeModal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-6 space-y-6">
        <form>
          <div class="flex flex-col space-y-4">
            <div>
              <label
                for="mergeClient"
                class="block text-sm font-medium text-gray-900 dark:text-gray-100"
                >Merge into</label
              >
              <select
                id="mergeClient"
                name="mergeClient"
                class="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-gray-100 form-select focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
              >
                <option value="1">Client 1</option>
                <option value="2">Client 2</option>
                <option value="3">Client 3</option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div
        class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600"
      >
        <button
          data-modal-hide="mergeModal"
          type="button"
          class="text-white bg-orange-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
        <button
          data-modal-hide="mergeModal"
          type="button"
          class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
