<div class="text-xl font-bold py-5">
  <p>Financial</p>
</div>
<div class="text-sm font-bold pb-5">
  <p>Estimated Billings</p>
</div>
<div class="addClientForm">
  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="false"
    [paginationPageSize]="10"
    [suppressCellFocus]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  />
</div>
<div class="bg-gray-200 dark:bg-theme-main-dark grid grid-cols-4 p-4">
  <div class="text-xs">
    <p></p>
  </div>
  <div class="text-sm ml-2">
    <p>04:00</p>
  </div>
  <div class="">
    <p></p>
  </div>
  <div class="text-sm ml-5">
    <p>1,500.00</p>
  </div>
</div>
<app-job-description></app-job-description>
