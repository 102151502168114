import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-settings',
  templateUrl: './job-settings.component.html',
})
export class JobSettingsComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      name: 'AC Mechanic',
    },
    {
      name: 'Car Mechanic',
    },
    {
      name: 'Laptop Service',
    },
    {
      name: 'Plumber',
    },
    {
      name: 'Bike Mechanic',
    },
    {
      name: 'Decoration Service',
    },
    {
      name: 'AC Mechanic',
    },
    {
      name: 'Car Mechanic',
    },
    {
      name: 'Laptop Service',
    },
    {
      name: 'Plumber',
    },
    {
      name: 'Bike Mechanic',
    },
    {
      name: 'Decoration Service',
    },
    {
      name: 'AC Mechanic',
    },
    {
      name: 'Car Mechanic',
    },
    {
      name: 'Laptop Service',
    },
    {
      name: 'Plumber',
    },
    {
      name: 'Bike Mechanic',
    },
    {
      name: 'Decoration Service',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.jobno}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.jobno;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs ">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-pink-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Invoices: ${params.value.name}
        </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Category Name',
      minWidth: 100,
      resizable: true,
      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
