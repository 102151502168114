<button
  (click)="leftDirection($event)"
  [ngClass]="{
    active: isLeftDirectionActive,
    inactive: !isLeftDirectionActive
  }"
  class="mt-1 relative ltr:ml-2 rtl:mr-2 inline-flex items-center cursor-pointer dark:bg-register-dark select-none px-7 py-4 rounded bg-white shadow-md dark:text-white text-gray-700 dark:hover:bg-hover-gray-100/10 hover:bg-theme-wui-100 transition ease-in-out delay-100 hover:scale-110 duration-300"
  [style.color]="isLeftDirectionActive ? activeButtonColor : ''"
>
  <svg
    class="mx-2 fill-current"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M360-360v-200q-66 0-113-47t-47-113q0-66 47-113t113-47h320v80h-80v440h-80v-440h-80v440h-80Zm-88 160 64 64-56 56-160-160 160-160 56 56-64 64h568v80H272Zm88-440v-160q-33 0-56.5 23.5T280-720q0 33 23.5 56.5T360-640Zm0-80Z"
    />
  </svg>
  {{ directionClass === "ltr" ? "LTR" : "LTR" }}
  <!-- ... SVG and other content ... -->
</button>

<!-- Right Direction Button -->
<button
  (click)="rightDirection($event)"
  [ngClass]="{
    active: isRightDirectionActive,
    inactive: !isRightDirectionActive
  }"
  class="mt-1 relative ltr:ml-5 rtl:mr-5 inline-flex items-center dark:bg-register-dark justify-between cursor-pointer select-none px-7 py-4 rounded dark:text-white shadow-md dark:hover:bg-hover-gray-100/10 bg-white hover:bg-theme-wui-100 transition ease-in-out delay-100 hover:scale-110 duration-300"
  [style.color]="isRightDirectionActive ? activeButtonColor : ''"
>
  <svg
    class="mx-2 fill-current"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      d="M360-360v-200q-66 0-113-47t-47-113q0-66 47-113t113-47h320v80h-80v440h-80v-440h-80v440h-80Zm0-280v-160q-33 0-56.5 23.5T280-720q0 33 23.5 56.5T360-640Zm0-80ZM680-80l-56-56 64-64H120v-80h568l-64-64 56-56 160 160L680-80Z"
    />
  </svg>
  {{ directionClass === "rtl" ? "RTL" : "RTL" }}
  <!-- ... SVG and other content ... -->
</button>
