import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators'; // Import the filter operator from RxJS
import { fadeAnimation } from './sidebar.service'; // Adjust the path

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [fadeAnimation],
})
export class SidebarComponent implements OnInit {
  interactionSubMenuRotation: boolean = false;
  collaborationSubMenuRotation: boolean = false;
  businessSubMenuRotation: boolean = false;
  miniSidebar: boolean = false;
  fullSidebar: boolean = false;
  interactionSubMenu = true;
  private activeRoute: string | null = null;
  subMenuUrl: string = '';
  miniSidebarVisible: any;
  activeHeadMenu: string | undefined;
  currentWidthClass: string | undefined;

  setActiveRoute(route: string) {
    this.activeRoute = route;
  }

  getActiveRoute() {
    return this.activeRoute;
  }

  sidebarVisible = true;
  isHovered = true;

  collaborationSubMenu = true;
  businessSubMenu = true;
  urlSegment2 = '';
  constructor(
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef
  ) {
    this.checkScreenWidth();
    console.log('activate', route);
    // this.checkScreensWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  ngOnInit() {
    this.mainContentElement = document.getElementById('mainContent');

    {
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd
          )
        )
        .subscribe((event: NavigationEnd) => {
          console.log('Link clicked:', event.url);
          this.checkMainElement();
          if (this.isLinkInSidebar(event.url)) {
            console.log('Sidebar Link clicked:', event.url);
            if (this.checkMainElement()) {
              const mainContentElement: any =
                document.getElementById('mainContent');
              mainContentElement.classList.remove('fullWidth');
              mainContentElement.classList.add('regularWidth');
            }

            this.checkAndToggleSidebar();
          }
        });
    }

    this.subMenuUrl = this.router.url.split('/')[2];
    if (this.subMenuUrl == 'interaction') {
      this.interactionSubMenu = false;
    } else {
      this.interactionSubMenu = true;
    }

    if (this.subMenuUrl == 'collaboration') {
      this.collaborationSubMenu = false;
    } else {
      this.collaborationSubMenu = true;
    }

    if (this.subMenuUrl == 'business') {
      this.businessSubMenu = false;
    } else {
      this.businessSubMenu = true;
    }

    this.sidebarService.sidebarVisibility$.subscribe(() => {
      this.sidebarVisible = !this.sidebarVisible;
    });
  }
  handleSubmenuClick(menuName: string) {
    console.log('menuName', menuName);

    // Close all other sub-menus
    if (menuName !== 'interactionSubMenu') {
      this.interactionSubMenu = true;
      this.interactionSubMenuRotation = false;
    }

    if (menuName !== 'collaborationSubMenu') {
      this.collaborationSubMenu = true;
      this.collaborationSubMenuRotation = false;
    }

    if (menuName !== 'businessSubMenu') {
      this.businessSubMenu = true;
      this.businessSubMenuRotation = false;
    }

    // Toggle the state of the clicked sub-menu
    if (menuName === 'interactionSubMenu') {
      this.interactionSubMenu = !this.interactionSubMenu;
      this.interactionSubMenuRotation = !this.interactionSubMenuRotation;
    } else if (menuName === 'collaborationSubMenu') {
      this.collaborationSubMenu = !this.collaborationSubMenu;
      this.collaborationSubMenuRotation = !this.collaborationSubMenuRotation;
    } else if (menuName === 'businessSubMenu') {
      this.businessSubMenu = !this.businessSubMenu;
      this.businessSubMenuRotation = !this.businessSubMenuRotation;
    }

    // Set the currently active head menu
    this.activeHeadMenu = menuName;

    // Toggle the sidebar if needed
    this.checkAndToggleSidebar();
  }

  private checkAndToggleSidebar() {
    if (!this.sidebarVisible) {
      this.sidebarService.toggleSidebar();
    }
  }

  private checkMainElement() {
    const mainContentElement = document.getElementById('mainContent');
    if (mainContentElement) {
      if (mainContentElement.classList.contains('regularWidth')) {
        // 'regularWidth' class is present
        console.log('Element has the class "regularWidth"');
        return false;
      } else if (mainContentElement.classList.contains('fullWidth')) {
        // 'fullWidth' class is present
        console.log('Element has the class "fullWidth"');
        return true;
      } else {
        // Neither class is present
        console.log('Element does not have either class');
        return false;
      }
    } else {
      // Element with ID 'mainContent' not found
      console.log('Element with ID "mainContent" not found');
      return false;
    }
  }

  private isLinkInSidebar(url: string): boolean {
    const links = this.el.nativeElement.querySelectorAll('.sidebarLink');
    return Array.from(links).some((link: any) => {
      const routerLinkAttribute = link.getAttribute('routerLink');
      if (routerLinkAttribute !== null) {
        //console.log('routerLinkAttribute', routerLinkAttribute);

        if (routerLinkAttribute == url) {
          return true;
        } else {
          const cleanedEventUrl = url.startsWith('/app/') ? url.slice(5) : url;
          // console.log('cleanedEventUrl', cleanedEventUrl);
          return routerLinkAttribute === cleanedEventUrl;
        }
      }
      return false;
    });
  }

  private checkScreenWidth() {
    this.sidebarVisible = window.innerWidth > 1027;

    if (window.innerWidth > 1027) {
      document.documentElement.classList.add('regularWidth');
    } else {
      document.documentElement.classList.remove('regularWidth');
    }
  }
  closeSidebar() {
    this.sidebarVisible = false;
  }
  mainContentElement: any = document.getElementById('mainContent');

  // Method to toggle the sidebar when hovering
  onMiniSidebarHover(isHovered: boolean) {
    this.sidebarVisible = true;
    console.log('mouse enter');
  }

  // Method to hide the sidebar when not hovering
  onHideSidebarHover(isHovered: boolean) {
    if (isHovered) {
      // Do something when hovering (if needed)
    } else {
      if (this.mainContentElement.classList.contains('fullWidth')) {
        this.sidebarVisible = false;
      }
      console.log('you');
    }
    // Remove the unnecessary forward slash ("/") at the end of the code.
  }
}
