import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-costs',
  templateUrl: './job-costs.component.html',
})
export class JobCostsComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      date: '18/July/2023',
      description: 'good',
      quantity: '1 litre',
      unitcost: '10.00',
      price: '150',
      cost: '10.30',
    },
    {
      date: '18/July/2023',
      description: 'Not good',
      quantity: '2cm',
      unitcost: '12.00',
      price: '200',
      cost: '10.30',
    },
    {
      date: '18/July/2023',
      description: 'Excelllent',
      quantity: '13.5 inches',
      unitcost: '15.00',
      price: '500',
      cost: '10.30',
    },
    {
      date: '18/July/2023',
      description: 'good',
      quantity: '55cm',
      unitcost: '17.00',
      price: '700',
      cost: '10.30',
    },
    {
      date: '18/July/2023',
      description: 'good',
      quantity: '10cm',
      unitcost: '14.00',
      price: '400',
      cost: '10.30',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
    },
    { field: 'description', headerName: 'Description' },

    { field: 'quantity', headerName: 'Quantity' },
    { field: 'unitcost', headerName: 'Unit Cost' },
    { field: 'price', headerName: 'Unit Price' },
    { field: 'cost', headerName: 'Cost' },
  ];

  defaultColDef = {
    sortable: true,
    resizable: true,
    minWidth: 300,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
