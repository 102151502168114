import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent {
  isDropdownOpen = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  messagehide() {
    this.isDropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      console.log('mess', event.target);
      this.isDropdownOpen = false;
    }
  }
}
