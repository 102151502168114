<div class="addClientForm">
  <div class="w-full py-10 ml-auto">
    <form>
      <div class="grid grid-cols-2 gap-x-8">
        <div class="col-span-2 mb-8">
          <h4 class="text-xl font-bold">Notes</h4>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="ti_tle"
                id="title"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="ti_tle" class="input_label">Title</label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                name="not_es"
                id="notes"
                class="input_box peer"
                placeholder=""
                required
              >
              </textarea>
              <label for="not_es" class="address_label text-xl">Notes</label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="fol_der"
                id="folder"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="fol_der" class="input_label">Folder</label>
            </div>
          </div>
        </div>
        <div class="pb-5 lg:col-span-1 md:col-span-1 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="tas_k"
                id="task"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="tas_k" class="input_label">Task</label>
            </div>
          </div>
        </div>
        <div class="flex ml-4 lg:pb-0 md:pb-5 pb-2 items-center justify-start">
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm ltr:ml-3 dark:text-theme-text-blue-200 rtl:mr-3">
            <p>public</p>
          </div>
        </div>

        <div class="col-span-2"></div>
        <div class="py-5">
          <div
            class="flex items-center justify-start ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
          >
            <a class="px-5 text-white save py-2 rounded-xl" href="#">Save</a>
            <a class="px-5 text-white py-2 cancel rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
    <div class="">
      <div class="text-xl font-bold pt-5">
        <p>Note History</p>
      </div>
      <div class="pt-2 text-sm">
        <p>There are no jobNotesList to display.</p>
      </div>
    </div>
  </div>
</div>
