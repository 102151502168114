import { Component, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-supplier-filter',
  templateUrl: './supplier-filter.component.html',
})
export class SupplierFilterComponent {
  showViewFilter = false;
  selectedViewFilter = 'All Suppliers';

  // ngOnInit(): void {
  //   this.showViewFilter = true;
  // }

  // showViewFilter = false;

  constructor(private el: ElementRef) {}

  // Toggle the view filter
  toggleViewFilter() {
    this.showViewFilter = !this.showViewFilter;
  }

  // Listen for document click
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the view filter button or the dropdown
    if (!this.el.nativeElement.contains(targetElement)) {
      this.showViewFilter = false;
    }
  }
  callAllSuppliers() {
    this.selectedViewFilter = 'All Suplliers';
    this.toggleViewFilter();
  }

  callActiveSuppliers() {
    this.selectedViewFilter = 'Active Suppliers Only';
    this.toggleViewFilter();
  }

  callFavoriteSuppliers() {
    this.selectedViewFilter = 'My Favorite Suppliers';
    this.toggleViewFilter();
  }

  callArchivedSuppliers() {
    this.selectedViewFilter = 'Archived Suppliers Only';
    this.toggleViewFilter();
  }
}
