<div>
  <div class="flex lg:flex-row md:flex-row flex-col justify-between mb-4">
    <div class="flex justify-between">
      <div class="flex lg:flex-row md:flex-row flex-row items-center">
        <h2 class="lg:text-3xl md:text-3xl text-xl">Contacts</h2>
        <div class="ltr:ml-4 lg:pt-0 pt-0 rtl:mr-4">
          <a
            routerLink="/app/client/add/contact"
            class="btn bg-theme-primary text-white rounded-2xl lg:px-4 md:px-2 px-2 lg:py-2 md:py-2 py-1 lg:text-sm md:text-sm text-xs"
          >
            + Add Contact
          </a>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <input
        type="search"
        id="filter-text-box"
        class="form-control border-2 border-gray-300 dark:text-white hover:border-black focus:border-theme-primary bg-white dark:border focus:dark:border-2 focus:dark:border-theme-wui-700 dark:border-theme-wui-700 border-dark dark:bg-transparent h-10 px-5 lg:pr-20 md:pr-20 pr-10 rounded-lg text-sm"
        placeholder="Filter"
        (input)="onFilterTextBoxChanged()"
      />
      <!-- <button
        (click)="clearAllFilters()"
        class="ltr:ml-4 rtl:mr-4 btn rounded-full text-white bg-sky-400 px-4 py-2 mb-4 text-sm"
      >
        Clear Filters
      </button> -->
    </div>
  </div>
  <div class="relative">
    <ag-grid-angular
      class="ag-theme-alpine overflow-x-auto"
      style="width: 100%; height: 100%"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [pagination]="false"
      [paginationPageSize]="10"
      [suppressCellFocus]="true"
      (firstDataRendered)="onFirstDataRendered($event)"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
    />
    <!-- <div
      class="example-wrapper w-fit pagesize block absolute bottom-2 right-0 left-5"
    >
      <div class="example-header flex items-center justify-start">
        <p class="page dark:text-white">Page Size:</p>
        <select
          class="ml-2 py-1 bg-transparent border page dark:text-white dark:border dark:border-white border-gray-400 hover:border-black px-5 rounded-lg"
          (change)="onPageSizeChanged()"
          id="page-size"
        >
          <option value="10">10</option>
          <option value="5">5</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div> -->
  </div>
</div>
