<div class="">
  <h4 class="text-lg text-theme-primary font-bold mt-4">General Information</h4>
  <dl class="info-list">
    <dt>Name :</dt>
    <dd>Apollo Tyres</dd>

    <dt>Account Manager :</dt>
    <dd>Ramakrishnan</dd>

    <dt>Job Manager :</dt>
    <dd>Muthukumar</dd>

    <dt>E-mail :</dt>
    <dd>info@appolotyres.com</dd>
  </dl>

  <h4 class="text-lg font-bold text-theme-primary mt-4">Contact Information</h4>

  <dl class="info-list">
    <dt>Address :</dt>
    <dd>310 Mint Street, Periamet, Chennai</dd>

    <dt>Postal Address :</dt>
    <dd>Apollo Tyres Limited 3rd Floor, KL 682036 IN</dd>

    <dt>Phone :</dt>
    <dd>+91 44 1299 9876</dd>

    <dt class="">Fax :</dt>
    <dd>+44 161 999 8888</dd>

    <dt>Website :</dt>
    <dd>https://www.google.co.in/?gws_rd=ssl</dd>

    <dt>Referral Source :</dt>
    <dd>Google</dd>

    <dt>Department :</dt>
    <dd>Mechanical</dd>

    <dt>Tyre :</dt>
    <dd>III</dd>
  </dl>

  <h4 class="text-lg text-theme-primary font-bold mt-4">
    Tax and Company Information
  </h4>

  <dl class="info-list">
    <dt>TFN :</dt>
    <dd>123 456 782</dd>

    <dt>ACN :</dt>
    <dd>051 775 556</dd>

    <dt>ABN :</dt>
    <dd>+61-455-593-366</dd>

    <dt>Branch/CAC/ICA :</dt>
    <dd>Yes</dd>

    <dt>Business Structure :</dt>
    <dd>Company</dd>

    <dt>Balance Data :</dt>
    <dd>January</dd>
  </dl>

  <h4 class="text-lg text-theme-primary font-bold mt-4">
    Financial Information
  </h4>

  <dl class="info-list">
    <dt>Type :</dt>
    <dd>Client Type 1</dd>

    <dt>Zero Rated GST :</dt>
    <dd>Yes</dd>

    <dt>Billing Details :</dt>
    <dd>+61-455-593-366</dd>
  </dl>
</div>
