import { Component } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-topnavbar',
  templateUrl: './topnavbar.component.html',
})
export class TopnavbarComponent {
  constructor(private sidebarService: SidebarService) {}
  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }
}
