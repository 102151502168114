import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  animations: [
    trigger('tabClick', [
      state(
        'inactive',
        style({
          'border-bottom': '2px solid transparent',
        })
      ),
      state(
        'active',
        style({
          'border-bottom': '2px solid var(--ws-theme-primary)', // Change this to the color you want
        })
      ),
      transition('inactive => active', animate('0.5s ease-in')),
      transition('active => inactive', animate('0.2s ease-out')),
    ]),
  ],
})
export class ContactDetailsComponent {
  activeTab: number | undefined;
  getTabClickState(tabNumber: number): string {
    return this.showTabNumber === tabNumber ? 'active' : 'inactive';
  }
  interactionSubMenuRotation: boolean = false;
  collaborationSubMenuRotation: boolean = false;
  showSummaryCard: boolean = true;
  showTabNumber: number = 1;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    initFlowbite();
    this.route.queryParams.subscribe((params) => {
      const tabNumber = +params['tab'];
      if (tabNumber) {
        this.showTabNumber = tabNumber;
      }
    });
  }

  toggleTab(tabNumber: number) {
    this.showTabNumber = tabNumber;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabNumber },
      queryParamsHandling: 'merge',
    });
  }

  hideSummaryCard() {
    this.showSummaryCard = true;
  }

  unhideSummaryCard() {
    this.showSummaryCard = true;
  }

  archiveConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to archive this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      confirmButtonColor: '#f9977c',
      cancelButtonText: 'No, let me think',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Removed!', 'Client archived successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Client is still in Active Status', 'error');
      }
    });
  }

  deleteConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think',
      confirmButtonColor: '#c9302c',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Removed!', 'Client deleted successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Client is still in Active Status', 'error');
      }
    });
  }
}
