import { Component, OnInit } from '@angular/core';
import { ThemeService } from './theme-service.services';

const THEME_COLOR_KEY = 'blueMode';

@Component({
  selector: 'app-orange-blue',
  templateUrl: './orange-blue.component.html',
})
export class OrangeBlueComponent implements OnInit {
  isBlueMode: boolean = false;
  activeTheme: string = '';
  activeIcon: string = '';

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    // Load the theme preference from local storage
    const savedTheme = localStorage.getItem(THEME_COLOR_KEY);

    if (savedTheme) {
      this.isBlueMode = savedTheme === 'orange';
      this.activeTheme = this.isBlueMode ? 'blue' : 'orange';
      this.applyTheme();
    } else {
      // If no saved theme, default to 'orange' and set it as active
      this.orangetheme();
    }

    // Subscribe to the theme observable to get notified of theme changes
    this.themeService.theme$.subscribe((theme) => {
      this.activeTheme = theme;
      this.setActiveIcon();
    });

    // Initialize the theme based on the saved value
    this.themeService.initTheme(); // Make sure this method is implemented correctly
  }

  setBlueMode() {
    this.themeService.setBlueMode();
  }

  setOrangeMode() {
    this.themeService.setOrangeMode();
  }

  private applyTheme() {
    if (this.isBlueMode) {
      document.body.classList.add('blue-theme');
    } else {
      document.body.classList.remove('blue-theme');
    }
  }

  private setActiveIcon() {
    this.activeIcon = this.activeTheme === 'blue' ? 'blue-icon' : 'orange-icon';
  }

  orangetheme() {
    this.themeService.setOrangeMode();
  }

  bluetheme() {
    this.themeService.setBlueMode();
  }
}
