<div class="text-3xl pb-5">
  <p>Messages</p>
</div>
<div
  class="h-full min-h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white"
>
  <main class="flex-grow flex min-h-0">
    <section aria-label="main content" class="flex min-h-0 flex-col flex-auto">
      <nav class="bg-gray-100 dark:bg-theme-wui-dark-100 flex p-4">
        <section
          aria-labelledby="open-tickets-tabs-label"
          class="mr-4 focus:outline-none"
        >
          <ul class="flex">
            <li>
              <button
                class="focus:outline-none focus:bg-yellow-200 p-2 rounded-l-md border border-t-theme-primary dark:border-2 dark:border-dashboard-border border-b-theme-primary border-l-theme-primary dark:bg-transparent bg-theme-primary/10 flex flex-col items-center w-40"
              >
                <p class="font-semibold text-black dark:text-white text-lg">
                  672
                </p>
                <p class="text-sm dark:text-gray-300 text-gray-600">Read</p>
              </button>
            </li>
            <li>
              <button
                class="focus:outline-none focus:bg-yellow-200 p-2 border border-sky-blue-text rounded-r-md bg-sky-blue-text/10 dark:border-2 dark:border-dashboard-border dark:bg-transparent flex flex-col items-center w-40 cursor-pointer"
              >
                <p class="font-semibold text-lg dark:text-white text-black">
                  75
                </p>
                <p class="text-sm dark:text-gray-300 text-gray-600">Unread</p>
              </button>
            </li>
          </ul>
        </section>
      </nav>

      <header
        class="bg-white dark:bg-theme-wui-dark-100 flex items-center py-1 px-4"
      >
        <div class="flex dark:text-white pb-2">
          <h2 id="content-caption" class="font-semibold">Today (6) Messages</h2>
          <span class="ml-3 group relative"> </span>
        </div>
      </header>

      <table
        aria-describedby="info-popup"
        aria-label="open tickets"
        class="border-t dark:border-2 dark:border-dashboard-border w-full min-h-0 h-full dark:bg-[#334155] dark:text-white flex flex-col"
      >
        <thead class="flex w-full flex-col px-4">
          <tr
            class="border-b dark:border-b-2 dark:border-dashboard-border flex"
          >
            <th class="font-semibold text-left py-3 pl-3 pr-1 w-20">
              <input type="checkbox" name="" id="" />
            </th>
            <th class="font-semibold text-left py-3 px-1 w-24 truncate">No</th>
            <th
              class="font-semibold text-left py-3 px-1 w-full max-w-xs xl:max-w-lg truncate"
            >
              Subject
            </th>
            <th class="font-semibold text-left py-3 px-1 flex-1 truncate">
              Name
            </th>
            <th class="font-semibold text-left py-3 px-1 flex-1 truncate">
              Time
            </th>
            <th class="font-semibold text-left py-3 px-1 flex-1 truncate">
              Comapany
            </th>
          </tr>
        </thead>
        <tbody class="flex w-full flex-col flex-1 min-h-0 overflow-hidden px-4">
          <tr
            class="border-b dark:border-b-2 dark:border-dashboard-border flex"
          >
            <th
              class="bg-gray-100 dark:bg-theme-wui-dark-100 text-left px-3 py-2 flex-1"
              colspan="7"
            >
              <h2 class="text-sm">
                <span class="font-normal mr-1">Today</span>
              </h2>
            </th>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 text-left w-24">#1801</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  Quo laudantium error corporis accusamus unde, labore quidem
                  non officiis.
                </p>
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Marla Darsuz</td>
            <td class="py-3 px-1 flex-1 truncate">Tuesday 09:56</td>
            <td class="py-3 px-1 flex-1 truncate">UK Support</td>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 w-24">#1802</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation1()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                </p>
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Jim Beam</td>
            <td class="py-3 px-1 flex-1 truncate">Friday 10:29</td>
            <td class="py-3 px-1 flex-1 truncate">UK Support</td>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 w-24">#1803</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation2()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  Excepturi at labore vel accusamus exercitationem quam, amet
                  provident!
                </p>
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Paul Ferrez</td>
            <td class="py-3 px-1 flex-1 truncate">Today 13:45</td>
            <td class="py-3 px-1 flex-1 truncate">-</td>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 w-24">#1804</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation3()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  impedit possimus praesentium voluptatum omnis assumenda rem
                  autem magni consequatur nostrum distinctio unde.
                </p>
                <!-- dropdown -->
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Sara Dechicco</td>
            <td class="py-3 px-1 flex-1 truncate">Thursday 09:22</td>
            <td class="py-3 px-1 flex-1 truncate">UK Support</td>
          </tr>
          <!-- medium -->
          <tr class="border-b flex">
            <th
              class="bg-gray-100 dark:bg-theme-wui-dark-100 text-left px-3 py-2 flex-1"
              colspan="7"
            >
              <h2 class="text-sm">
                <span class="font-normal mr-1">1 Day</span>
                <span>ago</span>
              </h2>
            </th>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 w-24">#1805</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation4()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  Excepturi at labore vel accusamus exercitationem assumenda ex
                  incidunt eum quam, amet provident!
                </p>
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Freddy Murro</td>
            <td class="py-3 px-1 flex-1 truncate">Today 12:13</td>
            <td class="py-3 px-1 flex-1 truncate">-</td>
          </tr>
          <tr role="row" class="ag-theme-alpine border-b flex cursor-pointer">
            <td
              role="cell"
              headers="select"
              class="py-3 pl-3 pr-1 w-20 flex items-start"
            >
              <input class="mt-1" type="checkbox" />
            </td>
            <td class="py-3 px-1 w-24">#1806</td>
            <td
              class="py-3 px-1 w-full max-w-xs xl:max-w-lg"
              (click)="archiveConfirmation5()"
            >
              <div class="relative group w-full">
                <p class="w-full truncate">
                  Odit consequatur natus aut reiciendis nisi sed!
                </p>
              </div>
            </td>
            <td class="py-3 px-1 flex-1 truncate">Carla Sandaers</td>
            <td class="py-3 px-1 flex-1 truncate">Today 08:13</td>
            <td class="py-3 px-1 flex-1 truncate">-</td>
          </tr>

          <tr class="border-b hidden">
            <th class="bg-gray-100 text-left px-3 py-2 flex-1" colspan="7">
              <h2 class="text-sm">
                <span class="font-normal mr-1">Priority</span>
                <span>High</span>
              </h2>
            </th>
          </tr>
        </tbody>
      </table>

      <footer aria-label="content footer" class="p-3 bg-white border-t hidden">
        footer
      </footer>
    </section>
  </main>
</div>
