<div class="addSupplierForm">
  <div class="flex items-center space-x-4">
    <a
      routerLink="/app/suppliers"
      class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </a>
    <h1 class="text-3xl">Organisation Settings</h1>
  </div>
  <div class="w-full py-10 ml-auto">
    <form>
      <div class="grid grid-cols-2 gap-x-8">
        <div class="col-span-2 mb-8">
          <h4 class="text-xl">Account</h4>
        </div>

        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Name"
                id="name"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="name" class="input_label">Name *</label>
            </div>
          </div>
        </div>
        <div></div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                name="address"
                id="address_area"
                class="input_box peer"
                placeholder=""
                required
              >
              </textarea>
              <label for="address" class="address_label text-xl">Address</label>
            </div>
          </div>
        </div>
        <div class="col-span-2 mb-8">
          <h4 class="text-xl">Contact Information</h4>
        </div>
        <!-- Coulmn 1 Starts -->
        <div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <textarea
                  name="address"
                  id="address_area"
                  class="input_box peer"
                  placeholder=""
                  required
                >
                </textarea>
                <label for="address" class="address_label text-xl"
                  >Address</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Town_City"
                  id="town_city_input"
                  class="peer input_box"
                  placeholder=" "
                  required
                />
                <label for="town_City" class="input_label">Town/City</label>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="State_Region"
                  id="Stateregion_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="State_Region" class="input_label"
                  >State/Region</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Postal_Code"
                  id="postal_code_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Postal_Code" class="input_label">Postal Code</label>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Country"
                  id="country_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Country" class="input_label">Country </label>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Phone"
                  id="phone_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Phone" class="input_label">Phone</label>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="url"
                  name="Website"
                  id="website_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Website" class="input_label">Website</label>
              </div>
            </div>
          </div>
        </div>
        <!-- Column 1 Ends -->
        <!-- Column 2 Starts -->
        <div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <textarea
                  name="Postal_Address"
                  id="floating_phone"
                  class="input_box peer"
                >
                </textarea>
                <label for="Postal_Address" class="address_label text-xl"
                  >Postal Address</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="town_city_input1"
                  id="towncity_input1"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="town_city_input1" class="input_label"
                  >Town/City</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="State_Region1"
                  id="floating_phone"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="State_Region1" class="input_label"
                  >State/Region</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="number"
                  name="Postal_Code1"
                  id="floating_phone"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Postal_Code1" class="input_label"
                  >Postal Code</label
                >
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Country1"
                  id="Country"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Country1" class="input_label">Country </label>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="relative">
              <div class="relative z-0 w-full group">
                <input
                  type="text"
                  name="Fax"
                  id="fax_input"
                  class="input_box peer"
                  placeholder=" "
                  required
                />
                <label for="Fax" class="input_label">Fax </label>
              </div>
            </div>
          </div>
        </div>
        <!-- Column 2 Ends -->

        <div class="col-span-2 mb-8">
          <h4 class="text-xl">Financial Information</h4>
        </div>

        <div class="flex items-start pb-5 justify-start">
          <div class="">
            <input type="checkbox" style="padding: 10px" />
          </div>
          <div class="text-sm ltr:ml-2 rtl:mr-2">
            <p>Zero Rated Tax</p>
          </div>
        </div>

        <div class="col-span-2 mb-8">
          <h4 class="text-xl">Interface Information</h4>
        </div>

        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="Export_Code"
                id="export_code_input"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="Export_Code" class="input_label">Export Code </label>
            </div>
          </div>
        </div>

        <div class="col-span-2">
          <hr class="border-gray-300" />
        </div>
        <div class="py-5">
          <div class="flex items-center justify-start space-x-8">
            <a class="px-5 text-white py-2 cancel rounded-xl" href="#"
              >Cancel</a
            >
            <a class="px-5 save text-white py-2 rounded-xl" href="#">Save</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
