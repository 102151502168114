import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-jobs-main',
  templateUrl: './jobs-main.component.html',
})
export class JobsMainComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      jobno: 'J00051',
      client: 'Apollo Tyres',
      name: 'Suresh',
      state: 'Tamil nadu',
      start: '18-Jun',
      due: '18-Jul',
    },
    {
      jobno: 'J00050',
      client: 'ABC Corp',
      name: 'David',
      state: 'Karnataka',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00049',
      client: 'XYZ Tech',
      name: 'Luffy',
      state: 'Kerla',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00048',
      client: '	Tech Solutions',
      name: 'Hancock',
      state: 'Andhra Pradesh',
      start: '30-Jun',
      due: '30-Jul',
    },
    {
      jobno: 'J00047',
      client: 'Globelex Inc',
      name: 'Zoro',
      state: 'Telangana',
      start: '29-Jun',
      due: '29-Jul',
    },
    {
      jobno: 'J00051',
      client: 'Apollo Tyres',
      name: 'Suresh',
      state: 'Tamil nadu',
      start: '18-Jun',
      due: '18-Jul',
    },
    {
      jobno: 'J00050',
      client: 'ABC Corp',
      name: 'David',
      state: 'Karnataka',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00049',
      client: 'XYZ Tech',
      name: 'Luffy',
      state: 'Kerla',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00048',
      client: '	Tech Solutions',
      name: 'Hancock',
      state: 'Andhra Pradesh',
      start: '30-Jun',
      due: '30-Jul',
    },
    {
      jobno: 'J00047',
      client: 'Globelex Inc',
      name: 'Zoro',
      state: 'Telangana',
      start: '29-Jun',
      due: '29-Jul',
    },
    {
      jobno: 'J00051',
      client: 'Apollo Tyres',
      name: 'Suresh',
      state: 'Tamil nadu',
      start: '18-Jun',
      due: '18-Jul',
    },
    {
      jobno: 'J00050',
      client: 'ABC Corp',
      name: 'David',
      state: 'Karnataka',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00049',
      client: 'XYZ Tech',
      name: 'Luffy',
      state: 'Kerla',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00048',
      client: '	Tech Solutions',
      name: 'Hancock',
      state: 'Andhra Pradesh',
      start: '30-Jun',
      due: '30-Jul',
    },
    {
      jobno: 'J00047',
      client: 'Globelex Inc',
      name: 'Zoro',
      state: 'Telangana',
      start: '29-Jun',
      due: '29-Jul',
    },
    {
      jobno: 'J00051',
      client: 'Apollo Tyres',
      name: 'Suresh',
      state: 'Tamil nadu',
      start: '18-Jun',
      due: '18-Jul',
    },
    {
      jobno: 'J00050',
      client: 'ABC Corp',
      name: 'David',
      state: 'Karnataka',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00049',
      client: 'XYZ Tech',
      name: 'Luffy',
      state: 'Kerla',
      start: '04-Jun',
      due: '04-Jul',
    },
    {
      jobno: 'J00048',
      client: '	Tech Solutions',
      name: 'Hancock',
      state: 'Andhra Pradesh',
      start: '30-Jun',
      due: '30-Jul',
    },
    {
      jobno: 'J00047',
      client: 'Globelex Inc',
      name: 'Zoro',
      state: 'Telangana',
      start: '29-Jun',
      due: '29-Jul',
    },
  ];

  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.jobno}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.jobno;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
    <div class="flex bg-sky-300 px-3 py-1  list-font font-light rounded-full text-white h-fit">
    Quotes :${params.node.data.jobno}
  </div>
        <div class="flex bg-sky-300 px-3 py-1 list-font  font-light rounded-full text-white h-fit">
          Quotes : ${params.value.client}
        </div>
        <div class="flex bg-pink-300 px-3 py-1 list-font  font-light rounded-full text-white h-fit">
          Invoices: ${params.value.name}
        </div>
       
        <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
        Jobs : ${params.value.state}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 list-font  font-light rounded-full text-white h-fit">
      Jobs : ${params.value.start}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
    Jobs : ${params.value.due}
  </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'jobno',
      headerName: 'Job No',
      cellRenderer: (params: ICellRendererParams) => {
        return `<a href="app/collaboration/jobs/detail" class="text-base">${params.value}</a>`;
      },
    },

    {
      field: 'client',
      headerName: 'Client',
    },
    {
      field: 'name',
      headerName: 'Name',
    },

    {
      field: 'state',
      headerName: 'State',
    },
    {
      field: 'start',
      headerName: 'Start',
    },
    {
      field: 'due',
      headerName: 'Due',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
