<div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
  <div
    class="bg-white dark:border-blue-300 dark:drop-shadow-lg border-2 gray-100 dark:drop-shadow-blue-300 dark:border-2 dark:bg-theme-main-dark drop-shadow-lg rounded-xl p-5"
  >
    <div class="">
      <p class="text-xl dark:text-blue-100 text-gray-700 font-semibold">
        Change Password
      </p>
      <p class="text-sm dark:text-blue-200 text-gray-700 pt-1">
        To change your password please confirm here
      </p>
    </div>
    <div class="pt-8">
      <label
        class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
        for="username"
      >
        Current Password
      </label>
      <input
        class="hadow border hover:border dark:hover:border-lite-dark hover:border-black dark:bg-transparent bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
        id="current"
        type="password"
        placeholder=""
      />
    </div>
    <div class="pt-4">
      <label
        class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
        for="username"
      >
        New Password
      </label>
      <input
        class="hadow border hover:border dark:hover:border-lite-dark hover:border-black dark:bg-transparent placeholder:text-3xl bg-transparent rounded-lg w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
        id="current"
        type="password"
        placeholder="........."
      />
    </div>
    <div class="pt-4">
      <label
        class="block text-gray-700 dark:text-blue-200 text-sm font-bold mb-2"
        for="username"
      >
        Confirm Password
      </label>
      <input
        class="hadow border hover:border dark:hover:border-lite-dark hover:border-black dark:bg-transparent bg-transparent rounded-lg placeholder:text-3xl w-full py-4 px-3 text-black placeholder:text-gray-700 dark:placeholder:text-blue-400 dark:text-white focus:border-2 focus:border-theme-primary"
        id="current"
        type="password"
        placeholder="........."
      />
    </div>
    <div class="pt-5">
      <div
        class="flex items-center justify-start ltr:space-x-2 rtl:space-x-2 rtl:space-x-reverse"
      >
        <a class="px-5 save text-white py-2 text-sm rounded-lg" href="#"
          >Save</a
        >
        <a
          class="px-5 text-pink-text text-sm border dark:hover:bg-blue-50 hover:bg-pink-100 border-pink-text py-2 rounded-lg"
          href="#"
          >Cancel</a
        >
      </div>
    </div>
  </div>
</div>
