import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
})
export class AvatarComponent {
  isDropdownOpen = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  profilehide() {
    this.isDropdownOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
}
