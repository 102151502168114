import { Component, OnInit } from '@angular/core';
import { ThemeService } from './theme-service.services';

const THEME_KEY = 'selected-theme';

@Component({
  selector: 'app-toggle-dark-mode-switch',
  templateUrl: './toggle-dark-mode-switch.component.html',
})
export class ToggleDarkModeSwitchComponent implements OnInit {
  isDarkMode: boolean = false;

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    const savedTheme = localStorage.getItem(THEME_KEY);
    const darkMode = localStorage.getItem('darkMode');
    if (savedTheme) {
      this.isDarkMode = savedTheme === 'true';
      this.applyTheme();
    }
    if (darkMode) {
      this.isDarkMode = darkMode === 'true';
    } else {
      this.isDarkMode = darkMode === 'true';
    }
  }

  setDarkMode() {
    this.isDarkMode = true;
    this.applyTheme();
    localStorage.setItem(THEME_KEY, 'true');
  }

  setLightMode() {
    this.isDarkMode = false;
    this.applyTheme();
    localStorage.setItem(THEME_KEY, 'false');
  }

  private applyTheme() {
    // Apply the selected theme by adding/removing classes to the body element or updating styles
    if (this.isDarkMode) {
      this.isDarkMode = true;
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme'); // remove light theme class if set
    } else {
      document.body.classList.remove('dark-theme'); // remove dark theme class if set
      document.body.classList.add('light-theme');
    }
  }

  lightheme() {
    this.setLightMode();
    this.themeService.setLightMode();
  }

  darktheme() {
    this.setDarkMode();
    this.themeService.setDarkMode();
  }
}
