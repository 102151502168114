import { Component } from '@angular/core';
import { ThemeService } from './theme-service.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  isDarkMode: boolean = false; // Provide an initial value here

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.isDarkMode = JSON.parse(localStorage.getItem('darkMode') || 'false');
    this.themeService.setDarkMode(this.isDarkMode);
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    this.themeService.setDarkMode(this.isDarkMode);
  }
}
