<div class="text-xl font-bold text-left">
  <p>Upload Document</p>
</div>
<div class="flex pt-3 items-center justify-center w-full">
  <label
    for="dropzone-file"
    class="flex flex-col items-center justify-center w-full h-28 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
  >
    <div class="flex flex-col items-center justify-center pt-5 pb-6">
      <p class="text-sm text-gray-500 dark:text-gray-400">
        Drag one or more files here to upload them
      </p>
    </div>
    <input id="dropzone-file" type="file" class="hidden" />
  </label>
</div>
<div class="my-3">
  <input
    class="relative m-0 block w-fit min-w-0 flex-auto cursor-pointer rounded-xl border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
    id="formFileSm"
    type="file"
  />
</div>
<div class="">
  <div class="flex justify-end dark:p-5">
    <div>
      <input
        type="search"
        id="filter-text-box"
        class="form-control border-2 border-gray-300 bg-white dark:border dark:border-theme-wui-700 dark:bg-theme-wui-dark-100 h-10 px-5 pr-3 rounded-lg text-sm focus:outline-none ring:1 ring-sky-300"
        placeholder="Filter"
        (input)="onFilterTextBoxChanged()"
      />

      <button
        (click)="clearAllFilters()"
        class="ltr:ml-4 rtl:mr-4 btn rounded-full lg:mt-0 md:mt-5 mt-5 dark:bg-theme-primary-dark text-white bg-sky-400 px-4 py-2 mb-4 dark:mb-0 text-sm"
      >
        Clear Filters
      </button>
    </div>
  </div>
  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [dataTypeDefinitions]="dataTypeDefinitions"
    [rowHeight]="50"
    [columnHoverHighlight]="false"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    [pagination]="true"
    [paginationPageSize]="5"
    [suppressCellFocus]="true"
  >
  </ag-grid-angular>
</div>
