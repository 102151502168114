<div class="notesWrapper flex">
  <aside>
      <div class="min-w-[200px]">

        <ul>
          <li>Note Title</li>
        </ul>
      </div>
  </aside>
  <main>

  </main>
</div>
