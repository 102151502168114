import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  PaginationNumberFormatterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-datagrid-quotes-main',
  templateUrl: './datagrid-quotes-main.component.html',
})
export class DatagridQuotesMainComponent {
  gridOptions: GridOptions = { suppressRowClickSelection: true };
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00077',
      link: '/app/business/quotes/details',
      name: 'Quote-180702',
      Quoteowner: 'Ratan Tata',
      clientname: 'TCS',
      state: 'Accepted',
      validuntil: '16-sep-2023',
      amount: '20000.00',
    },
    {
      no: 'Q00078',
      link: '/app/business/quotes/details',
      name: 'Quote-180703',
      Quoteowner: 'sandy',
      clientname: 'Benz',
      state: 'Accepted',
      validuntil: '12-Nov-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00079',
      link: '/app/business/quotes/details',
      name: 'Quote-180704',
      Quoteowner: 'Surjith',
      clientname: 'BMW',
      state: 'Accepted',
      validuntil: '11-sep-2023',
      amount: '80000.00',
    },
    {
      no: 'Q00080',
      link: '/app/business/quotes/details',
      name: 'Quote-180705',
      Quoteowner: 'Suresh',
      clientname: 'Lamborgini',
      state: 'Accepted',
      validuntil: '11-oct-2023',
      amount: '50000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00077',
      link: '/app/business/quotes/details',
      name: 'Quote-180702',
      Quoteowner: 'Ratan Tata',
      clientname: 'TCS',
      state: 'Accepted',
      validuntil: '16-sep-2023',
      amount: '20000.00',
    },
    {
      no: 'Q00078',
      link: '/app/business/quotes/details',
      name: 'Quote-180703',
      Quoteowner: 'sandy',
      clientname: 'Benz',
      state: 'Accepted',
      validuntil: '12-Nov-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00079',
      link: '/app/business/quotes/details',
      name: 'Quote-180704',
      Quoteowner: 'Surjith',
      clientname: 'BMW',
      state: 'Accepted',
      validuntil: '11-sep-2023',
      amount: '80000.00',
    },
    {
      no: 'Q00080',
      link: '/app/business/quotes/details',
      name: 'Quote-180705',
      Quoteowner: 'Suresh',
      clientname: 'Lamborgini',
      state: 'Accepted',
      validuntil: '11-oct-2023',
      amount: '50000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00077',
      link: '/app/business/quotes/details',
      name: 'Quote-180702',
      Quoteowner: 'Ratan Tata',
      clientname: 'TCS',
      state: 'Accepted',
      validuntil: '16-sep-2023',
      amount: '20000.00',
    },
    {
      no: 'Q00078',
      link: '/app/business/quotes/details',
      name: 'Quote-180703',
      Quoteowner: 'sandy',
      clientname: 'Benz',
      state: 'Accepted',
      validuntil: '12-Nov-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00079',
      link: '/app/business/quotes/details',
      name: 'Quote-180704',
      Quoteowner: 'Surjith',
      clientname: 'BMW',
      state: 'Accepted',
      validuntil: '11-sep-2023',
      amount: '80000.00',
    },
    {
      no: 'Q00080',
      link: '/app/business/quotes/details',
      name: 'Quote-180705',
      Quoteowner: 'Suresh',
      clientname: 'Lamborgini',
      state: 'Accepted',
      validuntil: '11-oct-2023',
      amount: '50000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
    {
      no: 'Q00076',
      link: '/app/business/quotes/details',
      name: 'Quote-180701',
      Quoteowner: 'Silver',
      clientname: 'Facebook',
      state: 'Accepted',
      validuntil: '15-Aug-2023',
      amount: '10000.00',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.no}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.no;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `<div class="pl-1">
            <a href="${params.node.data.link}">
            <h2 class="text-lg font-bold  list-font hover:underline">${params.node.data.no}</h2>
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-sky-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Quotes : ${params.value.name}
        </div>
        <div class="flex bg-pink-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Invoices: ${params.value.Quoteowner}
        </div>
        <div class="flex bg-purple-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
          Jobs : ${params.value.clientname}
        </div>
        <div class="flex bg-purple-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
        Jobs : ${params.value.state}
      </div>
      <div class="flex bg-purple-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
      Jobs : ${params.value.validuntil}
    </div>
    <div class="flex bg-purple-300 px-3 list-font py-1 text-sm font-light rounded-full text-white h-fit">
    Jobs : ${params.value.amount}
  </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'no',
      headerName: 'No',
      minWidth: 300,
      resizable: true,
      sortable: true,
      filter: true,
      filterParams: this.baseFilterParams,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      cellRenderer: this.nameCellRenderer,
    },
    {
      field: 'name',
      headerName: 'Name',
    },

    {
      field: 'Quoteowner',
      headerName: 'Quote owner',
    },
    {
      field: 'clientname',
      headerName: 'client name',
    },
    {
      field: 'state',
      headerName: 'State',
    },
    {
      field: 'validuntil',
      headerName: 'valid Until',
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
