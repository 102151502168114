import { Component, OnInit } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
@Component({
  selector: 'app-job-staff-allocation',
  templateUrl: './job-staff-allocation.component.html',
})
export class JobStaffAllocationComponent {
  filteredjob: any[] = [];
  jobs: any[] | undefined;
  formGroup: FormGroup | undefined;
  filteredaccount: any[] = [];
  accounts: any[] | undefined;
  ngOnInit() {
    this.accounts = ['Surya', 'Ashwin', 'Suresh'];
    this.jobs = ['Arun', 'Surji', 'Sandy'];

    this.formGroup = new FormGroup({
      selectedMonth: new FormControl<object | null>(null),
    });
  }
  filteraccount(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.accounts as any[]).length; i++) {
      let accounts = (this.accounts as any[])[i];
      if (accounts.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(accounts);
      }
    }

    this.filteredaccount = filtered;
  }
  filterjob(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.jobs as any[]).length; i++) {
      let jobs = (this.jobs as any[])[i];
      if (jobs.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(jobs);
      }
    }

    this.filteredjob = filtered;
  }
}
