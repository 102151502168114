<div class="text-xl font-bold py-5">
  <p>Costs</p>
</div>
<div class="addClientForm">
  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="false"
    [paginationPageSize]="10"
    [suppressCellFocus]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  />
</div>
<div class="bg-gray-200 dark:bg-theme-main-dark p-4">
  <div class="text-sm">
    <p>Total Cost (excl. GST)</p>
  </div>
</div>
