<div class="flex flex-col space-y-8">
  <button
    type="button"
    class="inline-block btn bg-theme-primary hover:bg-theme-primary-dark px-4 py-2 w-48 text-white text-center rounded-md cursor-pointer"
  >
    Primary Button
  </button>

  <button
    type="button"
    class="inline-block btn bg-theme-secondary hover:bg-theme-secondary-dark px-4 py-2 w-48 text-white text-center rounded-md cursor-pointer"
  >
    Secondary Button
  </button>

  <div class="text-lg dark:text-theme-text-blue-200 pt-5 font-bold">
    <p>Toaster</p>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-toast class="toast-success"></p-toast>
    <p-toast class="Warn"></p-toast>
    <p-toast class="Error"></p-toast>

    <button
      type="button"
      pButton
      pRipple
      (click)="showSuccess()"
      label="Success"
      class="px-10 py-2 rounded-xl text-white"
      style="background-color: #1ea87c !important; border: none !important"
    ></button>

    <button
      type="button"
      pButton
      pRipple
      (click)="showWarn()"
      label="Warn"
      class="px-10 py-2 rounded-xl text-white"
      style="background-color: #cc8925 !important; border: none !important"
    ></button>
    <button
      type="button"
      pButton
      pRipple
      (click)="showError()"
      label="Error"
      class="px-10 py-2 rounded-xl text-white"
      style="background-color: #ff5757 !important; border: none !important"
    ></button>
  </div>
  <div class="">
    <div class="pb-5 dark:text-theme-text-blue-200 text-lg font-bold">
      <p>Loading</p>
    </div>
    <div class="card">
      <p-progressBar
        mode="indeterminate"
        [style]="{ height: '6px' }"
      ></p-progressBar>
    </div>

    <div
      class="inline-block h-20 ml-10 mt-5 w-20 animate-spin rounded-full border-8 border-solid border-[#f9977c] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <span
        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span
      >
    </div>
  </div>

  <div class="card">
    <div class="text-lg dark:text-theme-text-blue-200 font-bold py-5">
      <p>Message alert</p>
    </div>
    <div class="flex justify-content-center gap-2">
      <button
        type="button"
        pButton
        class="p-button-primary"
        (click)="addSingle()"
        style="background-color: #1ea87c !important; border: none !important"
        label="Single"
      ></button>
      <!-- <button
        type="button"
        pButton
        class="p-button-success"
        (click)="addMultiple()"
        label="Multiple"
      ></button> -->
      <button
        type="button"
        pButton
        class="p-button-secondary"
        style="background-color: #ff5757 !important; border: none !important"
        (click)="clear()"
        label="Clear"
      ></button>
    </div>
    <p-messages></p-messages>
  </div>
  <div class="">
    <p class="text-lg dark:text-theme-text-blue-200 font-bold py-5">Calendar</p>
    <div class="card flex justify-content-center">
      <p-calendar></p-calendar>
    </div>
  </div>
  <div class="pt-5">
    <p class="text-lg dark:text-theme-text-blue-200 font-bold">
      Information Popup
    </p>
    <div class="pt-4">
      <button
        (click)="Confirmation()"
        class="w-fit btn px-2 py-2 bg-theme-primary rounded text-left flex justify-start"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 text-white h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>

        <span class="ml-2 text-white text-left">Information Popup</span>
      </button>
    </div>
  </div>
  <div class="pt-5">
    <nav>
      <ul class="flex">
        <li>
          <a
            class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            href="#"
            aria-label="Previous"
          >
            <span class="material-icons text-sm"><-</span>
          </a>
        </li>
        <li>
          <a
            class="mx-1 flex h-9 w-9 items-center justify-center rounded-full bg-gradient-to-tr from-pink-600 to-pink-400 p-0 text-sm text-white shadow-md shadow-pink-500/20 transition duration-150 ease-in-out"
            href="#"
          >
            1
          </a>
        </li>
        <li>
          <a
            class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            href="#"
          >
            2
          </a>
        </li>
        <li>
          <a
            class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            href="#"
          >
            3
          </a>
        </li>
        <li>
          <a
            class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out hover:bg-light-300"
            href="#"
            aria-label="Next"
          >
            <span class="material-icons text-sm">-></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="pt-5">
    <nav aria-label="Page navigation example">
      <ul class="flex items-center -space-x-px h-8 text-sm">
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span class="sr-only">Previous</span>
            <svg
              class="w-2.5 h-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >1</a
          >
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >2</a
          >
        </li>
        <li>
          <a
            href="#"
            aria-current="page"
            class="z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
            >3</a
          >
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >4</a
          >
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >5</a
          >
        </li>
        <li>
          <a
            href="#"
            class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span class="sr-only">Next</span>
            <svg
              class="w-2.5 h-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
