import {
  Component,
  HostBinding,
  Renderer2,
  Inject,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-toggle-direction-change',
  templateUrl: './toggle-direction-change.component.html',
})
export class ToggleDirectionChangeComponent implements OnInit {
  @HostBinding('class') directionClass = 'ltr'; // Default direction is RTL
  isRightDirectionActive = true;
  isLeftDirectionActive = false;
  activeButtonColor: string | undefined;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    // Retrieve the stored direction from localStorage
    const storedDirection = localStorage.getItem('direction');
    if (storedDirection) {
      this.setDirection(storedDirection);
    } else {
      // Set the default direction to RTL when no direction is stored
      this.setDirection('ltr');
    }
  }

  private setDirection(direction: string) {
    this.renderer.setAttribute(this.document.documentElement, 'dir', direction);
    this.directionClass = direction;
    this.isLeftDirectionActive = direction === 'ltr';
    this.isRightDirectionActive = direction === 'rtl';
    this.activeButtonColor = '#fb9778'; // Set the color based on the direction
  }

  rightDirection(event: any) {
    this.setDirection('rtl');
    localStorage.setItem('direction', 'rtl'); // Store the direction in localStorage
    // Other logic for right direction
  }

  leftDirection(event: any) {
    this.setDirection('ltr');
    localStorage.setItem('direction', 'ltr'); // Store the direction in localStorage
    // Other logic for left direction
  }
}
