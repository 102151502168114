<div
  class="lg:ml-4 md:ml-0 ml-0 dark:bg-opacity-8 mt-1 relative text-black dark:text-top-nav-bar hover:dark:text-top-nav-bar rounded-full"
>
  <button
    class="hover:bg-gray-200 p-2 rounded-full dark:hover:bg-gray-200/10"
    id="notification_Button"
    (click)="toggleDropdown()"
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
      ></path>
    </svg>
  </button>
  <div class="absolute top-0 ltr:ml-5 rtl:mr-5">
    <div class="text-xs text-white bg-theme-secondary w-4 h-4 rounded-full">
      <p class="ltr:ml-1.5 rtl:mr-1.5">1</p>
    </div>
  </div>
</div>
<div
  id="notifications"
  *ngIf="isDropdownOpen"
  class="z-10 bg-white rounded-lg absolute ltr:right-0 rtl:left-5 shadow w-80 dark:bg-gray-700"
  style="margin-right: 20px !important"
>
  <div class="py-5">
    <div class="">
      <div class="flex px-5 items-center justify-between">
        <div class="text-left text-lg text-black dark:text-white font-bold">
          <p>Notifications</p>
        </div>
        <div class="text-left text-xs text-white font-bold">
          <a href="#" class="bg-theme-primary rounded-lg px-2 py-1">5 new</a>
        </div>
      </div>
      <div class="pt-5">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile4.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-theme-primary text-black dark:text-white font-bold"
            >
              <p>Jon Doe reacted to your post</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile22.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-theme-primary text-black dark:text-white font-bold"
            >
              <p>Richard Miles liked your post</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile33.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-theme-primary text-black dark:text-white font-bold"
            >
              <p>Patric liked on your post</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile44.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-theme-primary text-black dark:text-white font-bold"
            >
              <p>Jolly completed task</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile55.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-theme-primary text-black dark:text-white font-bold"
            >
              <p>Lance Bagrol reacted to your post</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-8 text-center">
        <a
          routerLink="see/all/notifications"
          (click)="notificationhide()"
          class="border-2 border-save-background hover:bg-gray-200/20 dark:hover:bg-gray-600/10 rounded px-20 py-2 text-save-background text-sm"
          >See all notifications</a
        >
      </div>
    </div>
  </div>
</div>
