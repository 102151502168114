import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { initFlowbite } from 'flowbite';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
})
export class JobInfoComponent {
  showViewFilter = true;

  archiveConfirmation1() {
    Swal.fire({
      title: 'New Milestones',
      text: 'Do you want to archive this client?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      confirmButtonColor: '#f9977c',
      cancelButtonText: 'No, let me think',
    });
  }

  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  constructor() {}

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  rowData: any = [
    {
      date: '18/02/2018',
      milestone: 'Gaming',
    },
    {
      date: '20/04/2022',
      milestone: 'Prestige',
    },
  ];

  columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellRenderer: (params: ICellRendererParams) => {
        return `<a href="#" class="text-base">${params.value}</a>`;
      },
    },
    { field: 'milestone', headerName: 'MileStone' },
  ];

  defaultColDef = {
    sortable: true,
    minWidth: 600,
    resizable: true,
    filter: true,
    filterParams: this.baseFilterParams,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
