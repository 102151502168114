import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-baselayout',
  templateUrl: './baselayout.component.html',
  host: {
    document: 'click',
    '(document:click)': 'documentClick($event)',
    '(document:keydown.escape)': 'closeSidebarOnEscape()',
  },
})
export class BaselayoutComponent {
  closeSidebar() {
    this.sidebarVisible2 = false;
  }
  closeSidebarOnEscape() {
    this.sidebarVisible2 = false; // Close the sidebar
  }
  sidebarVisible: boolean = false;
  darkModeKey = 'darkMode';
  BlueModeKey = 'blueMode';
  windowWidth: number = window.innerWidth;
  sidebarVisible2: boolean = false;

  constructor(
    private sidebarService: SidebarService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.sidebarService.sidebarVisibility$.subscribe(() => {
      this.sidebarVisible = !this.sidebarVisible;
    });
  }
  ngOnInit() {
    this.checkScreenWidth();

    const darkMode = localStorage.getItem(this.darkModeKey);
    if (darkMode === 'true') {
      this.renderer.addClass(document.documentElement, 'dark');
    }
    const BlueMode = localStorage.getItem(this.BlueModeKey);
    if (BlueMode === 'blue') {
      this.renderer.addClass(document.documentElement, 'blue');
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const targetElement = event.target as HTMLElement;

    if (
      !this.el.nativeElement.contains(targetElement) ||
      targetElement.classList.contains('sidebar-settings')
    ) {
      this.sidebarVisible2 = false; // Close the sidebar
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    this.checkScreenWidth();
  }
  private checkScreenWidth() {
    if (this.windowWidth > 1027) {
      this.sidebarVisible = true;
    } else {
      this.sidebarVisible = false;
    }
  }
}
