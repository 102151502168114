<div class="addClientForm">
  <div class="w-full py-10 ml-auto">
    <form>
      <div class="grid grid-cols-2 gap-x-8">
        <div class="col-span-2 mb-8">
          <h4 class="text-xl font-bold">Timesheet Information</h4>
        </div>

        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="date"
                name="da_te"
                id="date"
                class="input_box peer dark:text-addres-darkmode"
                placeholder=" "
                required
              />
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="st_aff"
                id="staff"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="st_aff" class="input_label">Staff</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="tas_k"
                id="task"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="tas_k" class="input_label">Task</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="ti_me"
                id="time"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="ti_me" class="input_label">Time</label>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <textarea
                name="not_es"
                id="notes"
                class="input_box peer"
                placeholder=""
                required
              >
              </textarea>
              <label for="not_es" class="address_label text-xl">Notes</label>
            </div>
          </div>
        </div>

        <div class="col-span-2">
          <hr class="border-gray-300" />
        </div>
        <div class="py-5">
          <div
            class="flex items-center justify-start ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
          >
            <a class="px-5 save text-white py-2 rounded-xl" href="#">Save</a>
            <a class="px-5 cancel text-white py-2 rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <ag-grid-angular
    class="ag-theme-alpine"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [paginationPageSize]="10"
    [suppressCellFocus]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  />
</div>
