<ag-grid-angular
  class="ag-theme-alpine"
  style="width: 100%; height: 100%"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [pagination]="false"
  [paginationPageSize]="10"
  [suppressCellFocus]="true"
  (firstDataRendered)="onFirstDataRendered($event)"
  [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)"
/>
