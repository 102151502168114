import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-staff-user-setting',
  templateUrl: './staff-user-setting.component.html',
})
export class StaffUserSettingComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      name: 'Suresh',
      role: 'Admin',
      phone: '1234567890',
      mobile: '0987654321',
      email: 'suresh@gmail.com',
    },
    {
      name: 'Santosh',
      role: 'Employee',
      phone: '8493023948',
      mobile: '4928948399',
      email: 'santosh@gmail.com',
    },
    {
      name: 'Luffy',
      role: 'Manager',
      phone: '8855443355',
      mobile: '5544332211',
      email: 'luffy@gmail.com',
    },
    {
      name: 'Obito',
      role: 'Co-worker',
      phone: '9343456789',
      mobile: '23985544338',
      email: 'obito@gmail.com',
    },
    {
      name: 'David',
      role: 'Supplier',
      phone: '9854378921',
      mobile: '7589034523',
      email: 'david@gmail.com',
    },
    {
      name: 'Bruce',
      role: 'Assistant Manager',
      phone: '8995437856',
      mobile: '2845678945',
      email: 'bruce@gmail.com',
    },
    {
      name: 'Hancock',
      role: 'Admin',
      phone: '5984321568',
      mobile: '0923459867',
      email: 'hancock@gmail.com',
    },
    {
      name: 'Suresh',
      role: 'Admin',
      phone: '1234567890',
      mobile: '0987654321',
      email: 'suresh@gmail.com',
    },
    {
      name: 'Santosh',
      role: 'Employee',
      phone: '8493023948',
      mobile: '4928948399',
      email: 'santosh@gmail.com',
    },
    {
      name: 'Luffy',
      role: 'Manager',
      phone: '8855443355',
      mobile: '5544332211',
      email: 'luffy@gmail.com',
    },
    {
      name: 'Obito',
      role: 'Co-worker',
      phone: '9343456789',
      mobile: '23985544338',
      email: 'obito@gmail.com',
    },
    {
      name: 'David',
      role: 'Supplier',
      phone: '9854378921',
      mobile: '7589034523',
      email: 'david@gmail.com',
    },
    {
      name: 'Bruce',
      role: 'Assistant Manager',
      phone: '8995437856',
      mobile: '2845678945',
      email: 'bruce@gmail.com',
    },
    {
      name: 'Hancock',
      role: 'Admin',
      phone: '5984321568',
      mobile: '0923459867',
      email: 'hancock@gmail.com',
    },
    {
      name: 'Suresh',
      role: 'Admin',
      phone: '1234567890',
      mobile: '0987654321',
      email: 'suresh@gmail.com',
    },
    {
      name: 'Santosh',
      role: 'Employee',
      phone: '8493023948',
      mobile: '4928948399',
      email: 'santosh@gmail.com',
    },
    {
      name: 'Luffy',
      role: 'Manager',
      phone: '8855443355',
      mobile: '5544332211',
      email: 'luffy@gmail.com',
    },
    {
      name: 'Obito',
      role: 'Co-worker',
      phone: '9343456789',
      mobile: '23985544338',
      email: 'obito@gmail.com',
    },
    {
      name: 'David',
      role: 'Supplier',
      phone: '9854378921',
      mobile: '7589034523',
      email: 'david@gmail.com',
    },
    {
      name: 'Bruce',
      role: 'Assistant Manager',
      phone: '8995437856',
      mobile: '2845678945',
      email: 'bruce@gmail.com',
    },
    {
      name: 'Hancock',
      role: 'Admin',
      phone: '5984321568',
      mobile: '0923459867',
      email: 'hancock@gmail.com',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.jobno}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.jobno;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs ">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-pink-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Name: ${params.value.name}
        </div>
       
        <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
        Role : ${params.value.role}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
      Phone : ${params.value.phone}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
      Mobile : ${params.value.mobile}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
      E-mail : ${params.value.email}
    </div>
  
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      resizable: true,

      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'role',
      headerName: 'Role',
    },
    {
      field: 'phone',
      headerName: 'Phone',
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
    },
    {
      field: 'email',
      headerName: 'E-mail',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationPageSize = 10;
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
