import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() { }
  private darkModeKey = 'darkMode';

  isDarkMode(): boolean {
    return JSON.parse(localStorage.getItem(this.darkModeKey) || 'false');
  }

  setDarkMode(isDarkMode: boolean) {
    localStorage.setItem(this.darkModeKey, JSON.stringify(isDarkMode));
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }
}
