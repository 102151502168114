<div class="sidebar-settings">
  <div
    class="fixed setting-side overflow-y-auto top-0 bottom-0 lg:ltr:right-0 md:ltr:right-0 ltr:-right-16 lg:rtl:left-0 md:rtl:left-0 rtl:-left-16 bg-white dark:bg-register-dark w-[335px] z-50 p-4"
  >
    <div class="pt-5 z-50">
      <div class="text-2xl dark:text-white border-b pb-4 font-semibold">
        <p>Settings</p>
      </div>
      <div class="pt-4">
        <div class="text-lg dark:text-white font-semibold">
          <p>Theme Option</p>
        </div>
        <div class="pt-5">
          <app-toggle-dark-mode-switch></app-toggle-dark-mode-switch>
        </div>
        <div class="pt-8">
          <div class="text-lg dark:text-white font-semibold">
            <p>Theme Colors</p>
          </div>
          <app-orange-blue></app-orange-blue>
        </div>
        <div class="pt-8">
          <div class="text-lg dark:text-white font-semibold">
            <p>Sidebar type</p>
          </div>
          <div class="pt-5">
            <app-toggle-direction-change></app-toggle-direction-change>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
