<div class="flex pb-5 justify-between">
  <div class="flex justify-between">
    <div class="flex items-center">
      <h2
        class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200"
      >
        Tasks
      </h2>
      <div class="ltr:ml-4 rtl:mr-4">
        <a
          routerLink="/app/new/task"
          class="btn bg-theme-primary hover:bg-save-hover text-white rounded-2xl px-4 py-2 text-sm"
        >
          + New Task
        </a>
      </div>
    </div>
  </div>
</div>
<div
  class="w-full bg-white dark:bg-theme-wui-dark-100 shadow-lg rounded-2xl p-4 dark:p-4 border-t dark:border-0"
>
  <div class="flex justify-end pb-4">
    <div>
      <input
        type="search"
        id="filter-text-box"
        class="form-control border-2 border-gray-300 bg-white dark:border dark:border-theme-wui-700 dark:bg-theme-wui-dark-100 h-10 px-5 pr-3 rounded-lg text-sm focus:outline-none ring:1 ring-sky-300"
        placeholder="Filter"
        (input)="onFilterTextBoxChanged()"
      />

      <!-- <button
        (click)="clearAllFilters()"
        class="ltr:ml-4 rtl:mr-4 btn rounded-full dark:bg-[#22c9d6] text-white bg-sky-400 px-4 py-2 mb-4 text-sm"
      >
        Clear Filters
      </button> -->
    </div>
  </div>
  <div class="relative">
    <ag-grid-angular
      class="ag-theme-alpine"
      style="width: 100%; height: 100%"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [dataTypeDefinitions]="dataTypeDefinitions"
      [rowHeight]="50"
      [columnHoverHighlight]="false"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      [pagination]="true"
      [paginationPageSize]="5"
      [suppressCellFocus]="true"
    >
    </ag-grid-angular>
    <div
      class="example-wrapper pagesize block absolute bottom-2 ltr:left-5 rtl:left-5"
    >
      <div class="example-header flex items-center justify-start">
        <p class="page dark:text-white mx-2">Page Size</p>
        <select
          class="mx-2 py-1 bg-transparent border page dark:text-white dark:border dark:border-white border-gray-400 hover:border-black px-5 rounded-lg"
          (change)="onPageSizeChanged()"
          id="page-size"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  </div>
</div>
