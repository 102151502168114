<div>
  <div class="flex justify-between items-center pb-5">
    <div class="flex lg:flex-row md:flex-row flex-row items-center">
      <h2
        class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200"
      >
        Quotes
      </h2>
      <div class="ltr:ml-4 lg:pt-o md:pt-0 rtl:mr-4">
        <a
          routerLink="/app/suppliers/add"
          class="btn bg-theme-primary hover:bg-save-hover text-white rounded-2xl lg:px-4 md:px-4 px-2 lg:py-2 md:py-2 py-1 text-sm"
        >
          + Add Quote
        </a>
      </div>
    </div>
  </div>
  <div class="list-page">
    <app-datagrid-quotes-main></app-datagrid-quotes-main>
  </div>
</div>
