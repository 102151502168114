<div
  class="flex items-center pb-5 ltr:space-x-4 rtl:space-x-4 rtl:space-x-reverse"
>
  <a
    routerLink="/app/admin/cost/setting"
    class="bg-gray-200 dark:border dark:border-dashboard-border dark:bg-transparent hover:bg-gray-300 rounded-full p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 rtl:rotate-180 ltr:rotate-0 dark:text-theme-text-blue-200 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15.75 19.5L8.25 12l7.5-7.5"
      />
    </svg>
  </a>
  <h1 class="lg:text-3xl md:text-3xl text-2xl dark:text-theme-text-blue-200">
    Add New Cost
  </h1>
</div>
<div
  class="addClientForm bg-white dark:bg-theme-main-dark overflow-x-auto lg:p-10 md:p-10 p-5 shadow dark:shadow-slate-600 rounded-xl"
>
  <div class="w-full ml-auto">
    <form>
      <div class="grid grid-cols-2 lg:gap-x-8 md:gap-x-8 gap-x-0">
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            General Information
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="description"
                id="description"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="description" class="input_label">Description</label>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="until"
                id="until_cost"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="until" class="input_label">Unit Cost</label>
            </div>
            <div class="pt-2 text-gray-500 text-sm">
              <p>Unit Cost - What it costs per unit to purchase this item</p>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="supp_lier"
                id="supplier"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="supp_lier" class="input_label">Supplier</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="code"
                id="code"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="code" class="input_label">Code</label>
            </div>
          </div>
        </div>
        <div
          class="flex lg:col-span-1 pb-5 md:col-span-2 col-span-2 items-center justify-start"
        >
          <div class="inline-flex items-center">
            <label
              class="relative flex items-center p-3 hover:bg-check-hover dark:hover:bg-sidebar-hover rounded-full cursor-pointer"
              for="checkbox"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border-2 border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-theme-primary checked:bg-theme-primary checked:before:bg-theme-primary hover:before:opacity-10"
                id="checkbox"
              />
              <div
                class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="text-sm dark:text-theme-text-blue-200 ltr:ml-2 rtl:mr-2">
            <p>Billable</p>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="text"
                name="note"
                id="notes"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="note" class="input_label">Notes</label>
            </div>
          </div>
        </div>
        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Tax on Invoices and Quotes
          </h4>
        </div>
        <!-- Coulmn 1 Starts -->
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="tax"
              [suggestions]="filteredtaxs"
              (completeMethod)="filtertaxs($event)"
              [dropdown]="true"
              name="taxs"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Select Tax</label>
          </span>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Tax On Purchase Orders
          </h4>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <span class="p-float-label">
            <p-autoComplete
              formControlName="selecttax"
              [suggestions]="filteredselecttaxs"
              (completeMethod)="filterselecttaxs($event)"
              [dropdown]="true"
              name="selecttaxs"
              class=""
              styleClass="twPrimeAutoComplete "
              autocomplete
              [inputStyle]=""
              inputStyleClass="twPrimeAutoCompleteInput"
              aria-label=""
            ></p-autoComplete>
            <label for="autocomplete">Select Tax</label>
          </span>
        </div>

        <div class="lg:col-span-2 pb-5 md:col-span-2 col-span-2">
          <h4 class="text-xl dark:text-theme-text-blue-200">
            Interface Information
          </h4>
        </div>

        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="income"
                id="income_amount"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="income" class="input_label">Income Amount</label>
            </div>
            <div class="text-sm text-gray-500 pt-2">
              <p>
                Income Account - GL code from Your accounting system that the
                item on the invoice can be allocated to
              </p>
            </div>
          </div>
        </div>
        <div class="lg:col-span-1 pb-5 md:col-span-2 col-span-2">
          <div class="relative">
            <div class="relative z-0 w-full group">
              <input
                type="number"
                name="cost_of_sale"
                id="costsale"
                class="input_box peer"
                placeholder=" "
                required
              />
              <label for="cost_of_sale" class="input_label"
                >Cost of Sale Account</label
              >
            </div>
            <div class="text-sm text-gray-500 pt-2">
              <p>
                Cost of Sale Account - GL Code From your Accounting System that
                the item on the Purchase order can be allocated to
              </p>
            </div>
          </div>
        </div>

        <div class="py-5">
          <div
            class="flex items-center justify-start space-x-2 rtl:space-x-reverse"
          >
            <a class="px-5 text-white save py-2 rounded-xl" href="#">Save</a>
            <a class="px-5 text-white cancel py-2 rounded-xl" href="#"
              >Cancel</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
