import { Component, OnInit } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormControl, FormGroup } from '@angular/forms';
interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'app-add-cost',
  templateUrl: './add-cost.component.html',
})
export class AddCostComponent {
  filteredtaxs: any[] = [];
  tax: any[] | undefined;
  filteredselecttaxs: any[] = [];
  selecttax: any[] | undefined;
  ngOnInit() {
    this.tax = [
      'Select Tax',
      'Client Financial Setting',
      'None (0.0%)',
      'None (10.0%)',
    ];
    this.selecttax = [
      'Select Tax',
      'Supplier Financial Setting',
      'None (0.0%)',
      'None (10.0%)',
    ];
  }
  filtertaxs(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tax as any[]).length; i++) {
      let month = (this.tax as any[])[i];
      if (month.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(this.tax);
      }
    }

    this.filteredtaxs = filtered;
  }
  filterselecttaxs(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.selecttax as any[]).length; i++) {
      let month = (this.selecttax as any[])[i];
      if (month.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(this.selecttax);
      }
    }

    this.filteredselecttaxs = filtered;
  }
}
