<div
  class="flex lg:flex-row md:flex-row flex-col justify-end lg:p-3 md:p-3 p-2 py-3"
>
  <div>
    <input
      type="search"
      id="filter-text-box"
      class="form-control border-2 border-gray-300 dark:text-white hover:border-black focus:border-theme-primary bg-white dark:border focus:dark:border-2 focus:dark:border-theme-wui-700 dark:border-theme-wui-700 border-dark dark:bg-transparent h-10 px-5 lg:pr-20 md:pr-20 pr-10 rounded-lg text-sm"
      placeholder="Filter"
      (input)="onFilterTextBoxChanged()"
    />

    <!-- <button
      (click)="clearAllFilters()"
      class="ltr:ml-4 rtl:mr-4 btn rounded-full lg:mt-0 md:mt-5 mt-5 dark:bg-[#22c9d6] text-white px-4 py-2 mb-4 dark:mb-0 text-sm"
    >
      <img class="w-6 h-6" src="./assets/images/filter.png" />
    </button> -->
  </div>
</div>
<div class="relative">
  <ag-grid-angular
    class="ag-theme-alpine overflow-x-auto"
    [columnDefs]="columnDefs"
    style="width: 100%; height: 100%"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    [dataTypeDefinitions]="dataTypeDefinitions"
    [rowHeight]="70"
    [columnHoverHighlight]="false"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    [pagination]="true"
    [paginationPageSize]="5"
    [suppressCellFocus]="true"
    [paginationNumberFormatter]="paginationFormatter"
    [rowSelection]="'multiple'"
  ></ag-grid-angular>
  <div class="">
    <div
      class="example-wrapper pagesize block absolute w-fit bottom-2 ltr:right-0 rtl:left-0 left-5"
    >
      <div class="example-header flex items-center justify-start">
        <p class="page dark:text-white mx-2">Page Size</p>
        <select
          class="mx-2 py-1 bg-transparent border page dark:text-white dark:border dark:border-white border-gray-400 hover:border-black px-5 rounded-lg"
          (change)="onPageChanged()"
          id="page-size"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
    </div>
  </div>
</div>
