<div class="bg-white dark:bg-theme-main-dark">
  <div class="p-10">
    <div class="">
      <img
        class="w-5/12 mx-auto h-auto"
        src="./assets/images/maintainance.png"
      />
    </div>
    <div class="text-4xl pt-5 text-center font-bold text-black dark:text-white">
      <p>Maintenance Mode!!!</p>
    </div>
    <div
      class="text-2xl pt-5 text-center font-semibold text-black dark:text-white"
    >
      <p>Website is Under Construction. Check back later!</p>
    </div>
    <div class="pt-5">
      <div
        class="w-fit px-5 mx-auto rounded-lg dark:bg-save-background bg-theme-primary py-2 text-center"
      >
        <button class="text-sm text-white" href="/app/dashboard">
          Go back to Home
        </button>
      </div>
    </div>
  </div>
</div>
