<div class="">
  <h4 class="text-lg text-theme-primary font-bold mt-4">General Information</h4>
  <dl class="info-list">
    <dt>Supplier Name :</dt>
    <dd>Voltas</dd>
  </dl>

  <h4 class="text-lg font-bold text-theme-primary mt-4">Contact Information</h4>

  <dl class="info-list">
    <dt>Address :</dt>
    <dd>310 Mint Street, Periamet, Chennai</dd>

    <dt>Postal Address :</dt>
    <dd>voltas Limited 3rd Floor, KL 682036 IN</dd>

    <dt>Phone :</dt>
    <dd>+91 44 1299 9876</dd>

    <dt class="">Fax :</dt>
    <dd>+44 161 999 8888</dd>

    <dt>Website :</dt>
    <dd>https://www.google.co.in/?gws_rd=ssl</dd>
  </dl>

  <h4 class="text-lg text-theme-primary font-bold mt-4">
    Financial Information
  </h4>

  <dl class="info-list">
    <dt>Zero Rated GST :</dt>
    <dd>Yes</dd>
  </dl>
</div>
