import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';

@Component({
  selector: 'app-invoices-main',
  templateUrl: './invoices-main.component.html',
})
export class InvoicesMainComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      date: '30-Jun-2023',
      no: '	I00019',
      billing: 'Percentage',
      client: 'Apollo Tyres',
      job: 'Quote-3007',
      due: '1,375.00',
      amount: '1,375.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00016',
      billing: 'Percentage',
      client: 'ABC Corp',
      job: '4',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '27-Jun-2023',
      no: '	I00015',
      billing: 'Percentage',
      client: 'XYZ Tech',
      job: '6',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '26-Jun-2023',
      no: '	I00014',
      billing: 'Quoted',
      client: 'Tech Solution',
      job: '3',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '25-Jun-2023',
      no: '	I00013',
      billing: 'Quoted',
      client: 'Globex Inc',
      job: 'Quote-28',
      due: '0.00',
      amount: '0.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00012',
      billing: 'Quoted',
      client: 'TVS Tyres',
      job: 'Quote-2802',
      due: '25,000',
      amount: '25,000',
    },
    {
      date: '25-Jun-2023',
      no: '	I00011',
      billing: 'Quoted',
      client: 'MRF Tyres',
      job: 'Quote-2801',
      Due: '30,000',
      amount: '30,000.00',
    },
    {
      date: '30-Jun-2023',
      no: '	I00019',
      billing: 'Percentage',
      client: 'Apollo Tyres',
      job: 'Quote-3007',
      due: '1,375.00',
      amount: '1,375.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00016',
      billing: 'Percentage',
      client: 'ABC Corp',
      job: '4',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '27-Jun-2023',
      no: '	I00015',
      billing: 'Percentage',
      client: 'XYZ Tech',
      job: '6',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '26-Jun-2023',
      no: '	I00014',
      billing: 'Quoted',
      client: 'Tech Solution',
      job: '3',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '25-Jun-2023',
      no: '	I00013',
      billing: 'Quoted',
      client: 'Globex Inc',
      job: 'Quote-28',
      due: '0.00',
      amount: '0.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00012',
      billing: 'Quoted',
      client: 'TVS Tyres',
      job: 'Quote-2802',
      due: '25,000',
      amount: '25,000',
    },
    {
      date: '25-Jun-2023',
      no: '	I00011',
      billing: 'Quoted',
      client: 'MRF Tyres',
      job: 'Quote-2801',
      Due: '30,000',
      amount: '30,000.00',
    },
    {
      date: '30-Jun-2023',
      no: '	I00019',
      billing: 'Percentage',
      client: 'Apollo Tyres',
      job: 'Quote-3007',
      due: '1,375.00',
      amount: '1,375.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00016',
      billing: 'Percentage',
      client: 'ABC Corp',
      job: '4',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '27-Jun-2023',
      no: '	I00015',
      billing: 'Percentage',
      client: 'XYZ Tech',
      job: '6',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '26-Jun-2023',
      no: '	I00014',
      billing: 'Quoted',
      client: 'Tech Solution',
      job: '3',
      due: '13,750.00',
      amount: '13,750.00',
    },
    {
      date: '25-Jun-2023',
      no: '	I00013',
      billing: 'Quoted',
      client: 'Globex Inc',
      job: 'Quote-28',
      due: '0.00',
      amount: '0.00',
    },
    {
      date: '28-Jun-2023',
      no: '	I00012',
      billing: 'Quoted',
      client: 'TVS Tyres',
      job: 'Quote-2802',
      due: '25,000',
      amount: '25,000',
    },
    {
      date: '25-Jun-2023',
      no: '	I00011',
      billing: 'Quoted',
      client: 'MRF Tyres',
      job: 'Quote-2801',
      Due: '30,000',
      amount: '30,000.00',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.date}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.date;
  };

  nameCellRenderer = (params: ICellRendererParams) => {
    return `<div class="pl-1">
            <a href="${params.node.data.link}">
            <h4 class=" font-bold  list-font hover:underline">${params.node.data.date}</h4>
            
            </a>
            </div>
      `;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs -mt-2">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-sky-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
          Quotes : ${params.value.no}
        </div>
        <div class="flex bg-pink-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
          Invoices: ${params.value.billing}
        </div>
        <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
          Jobs : ${params.value.client}
        </div>
        <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
        Jobs : ${params.value.job}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
      Jobs : ${params.value.due}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 list-font font-light rounded-full text-white h-fit">
    Jobs : ${params.value.amount}
  </div>
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 300,
      resizable: true,
      cellRenderer: this.nameCellRenderer,
      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'no',
      headerName: 'No',
    },
    {
      field: 'billing',
      headerName: 'Billing',
    },
    {
      field: 'client',
      headerName: 'Client',
    },
    {
      field: 'job',
      headerName: 'Job',
    },
    {
      field: 'due',
      headerName: 'Due',
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 300,
  };
  public paginationPageSize = 10;
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
