<div
  class="lg:ml-4 md:ml-0 ml-0 relative mt-1 text-black dark:text-top-nav-bar hover:dark:text-top-nav-bar rounded-full"
>
  <button
    id="message_Button"
    class="hover:bg-gray-200 dark:hover:bg-gray-200/10 p-2 rounded-full"
    (click)="toggleDropdown()"
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
      ></path>
    </svg>
  </button>
  <div class="absolute top-0 ltr:ml-5 rtl:mr-5">
    <div class="text-xs text-white bg-theme-secondary w-4 h-4 rounded-full">
      <p class="ltr:ml-1 rtl:mr-1">2</p>
    </div>
  </div>
</div>
<div
  id="message"
  *ngIf="isDropdownOpen"
  class="z-10 bg-white rounded-lg absolute ltr:right-0 rtl:left-5 shadow w-80 dark:bg-gray-700"
  style="margin-right: 20px !important"
>
  <div class="py-5">
    <div class="">
      <div class="flex px-5 items-center justify-between">
        <div class="text-left text-lg text-black dark:text-white font-bold">
          <p>Messages</p>
        </div>
        <div class="text-left text-xs text-white font-bold">
          <a href="#" class="bg-sky-blue-text rounded-lg px-2 py-1">5 new</a>
        </div>
      </div>
      <div class="pt-5">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/toogle_profile.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-sky-blue-text text-black dark:text-white font-bold"
            >
              <p>Roman Joined the Team !</p>
            </div>
            <div class="text-sm text-black dark:text-white pt-1">
              <p>Congratulate him</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile1.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-sky-blue-text text-black dark:text-white font-bold"
            >
              <p>New Message Received</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Salma sent you new message</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile2.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-sky-blue-text text-black dark:text-white font-bold"
            >
              <p>New Payment Received</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Check your earnings</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile3.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-sky-blue-text text-black dark:text-white font-bold"
            >
              <p>Jolly completed task</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Assign her new task</p>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="flex hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-200/10 p-2 px-4 items-center justify-start"
        >
          <div class="rounded p-2">
            <img
              class="w-14 rounded-full h-auto mr-auto"
              src="./assets/images/profile3.png"
            />
          </div>
          <div class="ml-4">
            <div
              class="text-sm hover:text-sky-blue-text text-black dark:text-white font-bold"
            >
              <p>Roman Joined the Team !</p>
            </div>
            <div class="text-sm pt-1 text-black dark:text-white">
              <p>Congratulate him</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-8 text-center">
        <a
          routerLink="see/all/messages"
          (click)="messagehide()"
          class="border-2 border-sky-blue-text hover:bg-gray-300/10 dark:hover:bg-gray-600/10 rounded px-20 py-2 text-sky-blue-text text-sm"
          >See all messages</a
        >
      </div>
    </div>
  </div>
</div>
