import { Component } from '@angular/core';
import {
  ColDef,
  ICellRendererParams,
  FirstDataRenderedEvent,
  ITextFilterParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ISetFilterParams,
  ValueFormatterParams,
  PaginationNumberFormatterParams,
} from 'ag-grid-community';
@Component({
  selector: 'app-cost-setting',
  templateUrl: './cost-setting.component.html',
})
export class CostSettingComponent {
  gridOptions: GridOptions = {};
  gridApi: GridApi = {} as GridApi;

  rowData: any[] = [
    {
      description: 'Cost-1',
      supplier: 'Apollo Tyres',
      code: '000007',
      unit: '1000.00',
    },
    {
      description: 'Cost-2',
      supplier: 'Bajaj',
      code: '001207',
      unit: '2000.00',
    },
    {
      description: 'Cost-3',
      supplier: 'KTM',
      code: '4511000',
      unit: '3000.00',
    },
    {
      description: 'Cost-4',
      supplier: 'TVS Tyres',
      code: '754980',
      unit: '4000.00',
    },
    {
      description: 'Cost-5',
      supplier: 'Voltas',
      code: '958356',
      unit: '5000.00',
    },
    {
      description: 'Cost-6',
      supplier: 'SBI',
      code: '584809',
      unit: '6000.00',
    },
    {
      description: 'Cost-1',
      supplier: 'Aprilla',
      code: '084755',
      unit: '7000.00',
    },
    {
      description: 'Cost-1',
      supplier: 'Apollo Tyres',
      code: '000007',
      unit: '1000.00',
    },
    {
      description: 'Cost-2',
      supplier: 'Bajaj',
      code: '001207',
      unit: '2000.00',
    },
    {
      description: 'Cost-3',
      supplier: 'KTM',
      code: '4511000',
      unit: '3000.00',
    },
    {
      description: 'Cost-4',
      supplier: 'TVS Tyres',
      code: '754980',
      unit: '4000.00',
    },
    {
      description: 'Cost-5',
      supplier: 'Voltas',
      code: '958356',
      unit: '5000.00',
    },
    {
      description: 'Cost-6',
      supplier: 'SBI',
      code: '584809',
      unit: '6000.00',
    },
    {
      description: 'Cost-1',
      supplier: 'Aprilla',
      code: '084755',
      unit: '7000.00',
    },
    {
      description: 'Cost-1',
      supplier: 'Apollo Tyres',
      code: '000007',
      unit: '1000.00',
    },
    {
      description: 'Cost-2',
      supplier: 'Bajaj',
      code: '001207',
      unit: '2000.00',
    },
    {
      description: 'Cost-3',
      supplier: 'KTM',
      code: '4511000',
      unit: '3000.00',
    },
    {
      description: 'Cost-4',
      supplier: 'TVS Tyres',
      code: '754980',
      unit: '4000.00',
    },
    {
      description: 'Cost-5',
      supplier: 'Voltas',
      code: '958356',
      unit: '5000.00',
    },
    {
      description: 'Cost-6',
      supplier: 'SBI',
      code: '584809',
      unit: '6000.00',
    },
    {
      description: 'Cost-1',
      supplier: 'Aprilla',
      code: '084755',
      unit: '7000.00',
    },
  ];
  constructor() {
    this.rowData.forEach((data) => {
      data.cName = `${data.jobno}`;
      data.recentActivity = `${data.activity}, ${data.activityTime}`;
    });
  }

  baseFilterParams: ITextFilterParams = {
    debounceMs: 200,
    filterOptions: ['contains', 'notContains', 'startsWith'],
    maxNumConditions: 1,
  };

  getCompanyName = (params: ValueFormatterParams) => {
    return params.data.jobno;
  };

  activityCellRenderer = (params: ICellRendererParams) => {
    return `<p >${params.node.data.activity}</p>
              <p class="text-xs ">${params.node.data.activityTime}</p>
      `;
  };

  worksInProcessCellRenderer = (params: ICellRendererParams) => {
    return `<div class="mt-2 grid grid-cols-2 gap-2">
        <div class="flex bg-pink-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
          Description: ${params.value.description}
        </div>
       
        <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
        Supplier : ${params.value.supplier}
      </div>
      <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
      Code : ${params.value.code}
    </div>
    <div class="flex bg-purple-300 px-3 py-1 text-sm font-light rounded-full text-white h-fit">
    Unit : ${params.value.unit}
  </div>
  
        `;
  };

  columnDefs: ColDef[] = [
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 300,
      resizable: true,

      checkboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      filter: true,
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
    },

    {
      field: 'code',
      headerName: 'Code',
    },
    {
      field: 'unit',
      headerName: 'Unit Cost',
    },
  ];

  public defaultColDef: ColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };
  public paginationNumberFormatter: (
    params: PaginationNumberFormatterParams
  ) => string = (params: PaginationNumberFormatterParams) => {
    return '[' + params.value.toLocaleString() + ']';
  };
  onPageSizeChanged() {
    var value = (document.getElementById('page-size') as HTMLInputElement)
      .value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  dataTypeDefinitions = {};

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  clearAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }
}
